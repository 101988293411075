import { Record } from 'immutable';
import {mixed, number, object, string} from "yup";

export const ContentRecord = Record({
    id: 0,
    status: '',
    name: '',
    links: '',
    kind: '',
    summary: '',
    details: '',
    attachment: 0,
    filename: '',
    type: '',
    size: 0
});

export const ContentSchema = object().shape({
    id: number().required().min(0).default(0),
    status: mixed().required().oneOf(['HIDDEN', 'PUBLIC', 'ARCHIVED']).default('HIDDEN'),
    name: string().required().min(5).max(100).default(''),
    kind: string().min(0).max(100).default(''),
    links: string().min(0).max(2000).default(''),
    attachment: string().required().min(1).max(100).default(''),
    summary: string().required().min(0).max(10000000).default(''),
    details: string().required().min(0).max(100000000).default(''),
});
