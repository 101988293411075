import { Record } from 'immutable';
import {array, date, mixed, number, object, string} from "yup";
import {ageGroups} from "./agegroups";

export const EventRecord = Record({
  id: 0,
  name: '',
  label: '',
  status: '',
  type: '',
  category: '',
  startDate: null,
  endDate: null,
  location: '',
  description: '',
  registrationPhase1: null,
  registrationPhase2: null,
  registrationPhase3: null,
  registrationEnd: null,
  organizer: 0
});

export const EventSchema = object().shape({
  id: number().min(0).default(0),
  type: mixed().oneOf(['BOULDER', 'LEAD', 'SPEED', 'OTHER']),
  category: mixed().oneOf(['AMATEUR', 'PROFESSIONAL', 'MIXED']),
  status: mixed().oneOf(['PREPARATION', 'ACTIVE', 'CLOSED']),
  name: string().required().min(5).max(100).default(''),
  label: string().required().min(5).max(50).default(''),

  registrationPhase1: date().nullable(),
  registrationPhase2: date().nullable(),
  registrationPhase3: date().nullable(),
  registrationEnd: date().nullable(),

  startDate: date().required(),
  endDate: date().nullable(),
  location: string().required().min(10).max(200).default(''),

  description: string().required().max(10000000).default(''),

  organizer: number().min(1).default(1),
});

export const EventGroupRecord = Record({
  id: 0,
  event: 0,
  name: '',
  position: 0,
  day: null,
  startTime: '',
  endTime: '',
  ageGroups: [  ],
  participantLimit: 0,
  participantCount: 0,
  notes: ''
});

export const EventGroupSchema = object().shape({
  id: number().required().min(0).default(0),
  event: number().required().min(0).default(0),
  name: string().required().min(5).max(100).default(''),
  position: number().required().min(1).default(1),
  day: date().required(),
  startTime: string().required().min(5).max(5).default('08:00'),
  endTime: string().required().min(5).max(5).default('10:00'),
  ageGroups: array().of(mixed().oneOf(ageGroups)).required(),
  participantLimit: number().required().min(0),
  participantCount: number(),
  notes : string()
});

export const EventRegistrationRecord = Record({
  id: 0,
  event: 0,
  group: 0,
  principal: 0,
  name: '',
  ageGroup: '',
  status: ''
});

export const EventRegistrationSchema = object().shape({
  id: number().required().min(0).default(0),
  event: number().required().min(0).default(0),
  group: number().required().min(0).default(0),
  name: string().required().min(5).max(100).default(''),
  ageGroups: array().of(mixed().oneOf(ageGroups)).required(),
  status: mixed().oneOf(['ACTIVE', 'CANCELLED']).required(),
});

export const EventShortRecord = Record({
  id: 0,
  label: '',
  startDate: null,
  groups: []
});