import {grayColor, main} from "../../Lib/material-kit/assets/jss/material-kit-pro-react.jsx";
import {behanceColor} from "../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";

export const contentBoxStyle = {
  main: {
    ...main,
    width: "100%",
    marginTop: "64px",
    backgroundColor: grayColor[2],
    "@media (min-width: 576px)": {
      paddingLeft: 30,
      paddingRight: 30,
    },
    height: "calc(100vh - 64px)",
    minHeight: "calc(100vh - 64px)",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "44px",
    minHeight: "44px",
    maxHeight: "44px",
    overflowY: "hidden",
    "@media (max-width: 576px)": {
      paddingLeft: 10,
    },
  },
  title : {
    color: "#396593", //"white",
    fontWeight: 400,
    "&:hover,&:focus": {
      color: behanceColor
    }
  },
  addBtn: {
    margin: "0 0 0 15px",
  },
  content : {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column"
  },
  plain: {
    padding: 0
  }
};