import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../Lib/Table/Table";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import DefaultActions from "../../../Lib/Table/DefaultActions";
import {useContents} from "../../../Api/contents";

const urlNs = "/admin/contents/";

const List = memo((props) => {

  const {path} = props;

  const {t} = useTranslation('contents');

  const { data } = useContents({kind: ''});

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'status',
      Header: t('general:status')
    },
    {
      accessor: 'attachment',
      Header: t('attachment')
    },
    {
      accessor: 'filename',
      Header: t('filename')
    },
    {
      accessor: 'type',
      Header: t('type')
    },
    {
      accessor: 'size',
      Header: t('size')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={urlNs} id={row.values.id} hideDelete/>
    }
  ];

  return (
      <ContentBox path={path} add={urlNs + "add"}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default List;