import React from "react";

export default {
  documents: "Nyomtatványok",
  add: "létrehozás",
  type: "Típus",
  EXCULPATION: "igazolás",
  LEAVE_LETTER: "kikérő",
  RESULT_CERTIFICATE: "eredmény igazolás",
  event: "Esemény",
  fromDate: "Első nap",
  toDate: "Utolsó nap",
  "missing-account-data" : "Hiányzó fiók adatok",
  "missing-account-data-explanation" : 'Nyomtatvány létrehozásához meg kell adni a fiókban a születési adatokat. Hiányzó adat(ok): ',
  'birthPlace' : "születés helye",
  'mothersName' : "anyja neve",
  'mothersBirthDate': "anyja születési dátuma",
  createdAt: "Létrehozás",
  "from" : "Kezdete",
  "till" : "Vége"
}