import baseFetch, {useFetchMany, useFetchOne, useQueryOne} from "../Lib/baseFetch";
import {
  ClimbRecord,
  ClimbSchema,
  CompetitionRecord,
  CompetitionSchema,
  ParticipationRecord,
  ParticipationSchema,
  SectionGroupRecord,
  SectionGroupSchema,
  SectionRecord,
  SectionRouteRecord,
  SectionRouteSchema,
  SectionSchema
} from "../Data/Competition";

// --  Competition  ------------------------

export function useCompetitions(filter) {
  return useFetchMany('/competitions', undefined, CompetitionRecord);
}

export function useCompetition(id) {
  return useFetchOne('/competitions', id, CompetitionRecord, CompetitionSchema);
}

export function addCompetition(competition, onSuccess, onError) {
    baseFetch('POST', '/competitions', competition,
        data => {
            if (onSuccess) onSuccess(new CompetitionRecord(data))
        },
        onError
    );
}

export function getCompetitions(filter, onSuccess, onError) {
    baseFetch('GET', '/competitions', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new CompetitionRecord(r)))
        },
        onError
    );
}

export function getCompetition(id, onSuccess, onError) {
    baseFetch('GET', '/competitions/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new CompetitionRecord(data))
        },
        onError
    );
}

export function updateCompetition(competition, onSuccess, onError) {
    baseFetch('PATCH', '/competitions/' + competition.get('id'), competition, onSuccess, onError);
}

export function removeCompetition(competition, onSuccess, onError) {
    baseFetch('DELETE', '/competitions/' + competition.get('id'), undefined, onSuccess, onError);
}

export function useCandidates() {
  return useFetchMany('/competitions/candidates');
}

export function executeParticipantImport(competitionId, onSuccess, onError) {
  baseFetch('POST', '/competitions/' + competitionId + '/actions/participant-import', undefined, onSuccess, onError);
}

export function executeRecalculate(competitionId, onSuccess, onError) {
  baseFetch('POST', '/competitions/' + competitionId + '/actions/recalculate', undefined, onSuccess, onError);
}

// --  Results  --------------------

export function useCompetitionResults(competitionId) {
  return useFetchOne('/competitions/' + competitionId + '/results', null);
}

export function useSectionResults(competitionId, sectionId) {
  return useFetchMany('/competitions/' + competitionId + '/sections/' + sectionId + '/results', undefined);
}

export function useParticipationResults(competitionId, filter) {
  return useQueryOne('/competitions/' + competitionId + '/results', filter);
}

export function useMonitor(competitionId, revision) {
  return useFetchOne('/competitions/' + competitionId + '/monitor', null, undefined, undefined, revision);
}


// --  Section  ------------------------

export function useSections(competitionId) {
  return useFetchMany('/competitions/' + competitionId + '/sections', undefined, SectionRecord);
}

export function useSection(competitionId, id) {
  return useFetchOne('/competitions/' + competitionId + '/sections/', id, SectionRecord, SectionSchema);
}

export function addSection(section, onSuccess, onError) {
  baseFetch('POST', '/competitions/' + section.get('competition') + '/sections', section,
      data => {
        if (onSuccess) onSuccess(new SectionRecord(data))
      },
      onError
  );
}

export function getSection(filter, onSuccess, onError) {
  baseFetch('GET', '/competitions/' + filter.cid + '/sections/' + filter.id, undefined,
      data => {
        if (onSuccess) onSuccess(new SectionRecord(data))
      },
      onError
  );
}

export function updateSection(section, onSuccess, onError) {
  baseFetch('PATCH', '/competitions/' + section.get('competition') + '/sections/' + section.get('id'), section, onSuccess, onError);
}

export function removeSection(section, onSuccess, onError) {
  baseFetch('DELETE', '/competitions/' + section.get('competition') + '/sections/' + section.get('id'), undefined, onSuccess, onError);
}

// --  SectionGroup  -----------------------

export function useSectionGroups(competitionId, sectionId) {
  return useFetchMany('/competitions/' + competitionId + '/sections/' + sectionId + '/groups', undefined, SectionGroupRecord);
}

export function useSectionGroup(competitionId, sectionId, id) {
  return useFetchOne('/competitions/' + competitionId + '/sections/' + sectionId + '/groups', id, SectionGroupRecord, SectionGroupSchema);
}

export function addSectionGroup(sectionGroup, onSuccess, onError) {
  baseFetch('POST', '/competitions/' + sectionGroup.get('competition') + '/sections/' + sectionGroup.get('section') + '/groups', sectionGroup,
      data => {
        if (onSuccess) onSuccess(new SectionGroupRecord(data))
      },
      onError
  );
}

export function getSectionGroup(filter, onSuccess, onError) {
  baseFetch('GET', '/competitions/' + filter.cid + '/sections/' + filter.sid + '/groups/' + filter.id, undefined,
      data => {
        if (onSuccess) onSuccess(new SectionGroupRecord(data))
      },
      onError
  );
}

export function updateSectionGroup(sectionGroup, onSuccess, onError) {
  baseFetch('PATCH',
      '/competitions/' + sectionGroup.get('competition') + '/sections/' + sectionGroup.get('section') + '/groups/' + sectionGroup.get('id'),
      sectionGroup, onSuccess, onError);
}

export function removeSectionGroup(sectionGroup, onSuccess, onError) {
  baseFetch('DELETE',
      '/competitions/' + sectionGroup.get('competition') + + '/sections/' + sectionGroup.get('section') + '/groups/' + sectionGroup.get('id'),
      undefined, onSuccess, onError);
}

// --  SectionRoute  -----------------------

export function useSectionRoutes(competitionId, sectionId) {
  return useFetchMany('/competitions/' + competitionId + '/sections/' + sectionId + '/routes', undefined, SectionRouteRecord);
}

export function useSectionRoute(competitionId, sectionId, id) {
  return useFetchOne('/competitions/' + competitionId + '/sections/' + sectionId + '/routes', id, SectionRouteRecord, SectionRouteSchema);
}

export function addSectionRoute(sectionRoute, onSuccess, onError) {
  baseFetch('POST', '/competitions/' + sectionRoute.get('competition') + '/sections/' + sectionRoute.get('section') + '/routes', sectionRoute,
      data => {
        if (onSuccess) onSuccess(new SectionRouteRecord(data))
      },
      onError
  );
}

export function getSectionRoute(filter, onSuccess, onError) {
  baseFetch('GET', '/competitions/' + filter.cid + '/sections/' + filter.sid + '/routes/' + filter.id, undefined,
      data => {
        if (onSuccess) onSuccess(new SectionRouteRecord(data))
      },
      onError
  );
}

export function updateSectionRoute(sectionRoute, onSuccess, onError) {
  baseFetch('PATCH',
      '/competitions/' + sectionRoute.get('competition') + '/sections/' + sectionRoute.get('section') + '/routes/' + sectionRoute.get('id'),
      sectionRoute, onSuccess, onError);
}

export function removeSectionRoute(sectionRoute, onSuccess, onError) {
  baseFetch('DELETE',
      '/competitions/' + sectionRoute.get('competition') + + '/sections/' + sectionRoute.get('section') + '/routes/' + sectionRoute.get('id'),
      undefined, onSuccess, onError);
}

// --  Participation  ---------------------

export function useParticipations(competitionId) {
  return useFetchMany('/competitions/' + competitionId + '/participations', undefined, ParticipationRecord);
}

export function useParticipation(competitionId, id) {
  return useFetchOne('/competitions/' + competitionId + '/participations/', id, ParticipationRecord, ParticipationSchema);
}

export function addParticipation(competitionId, principalId, onSuccess, onError) {
  baseFetch('POST', '/competitions/' + competitionId + '/participations', principalId,
      data => {
        if (onSuccess) onSuccess(new ParticipationRecord(data))
      },
      onError
  );
}

export function getParticipation(filter, onSuccess, onError) {
  baseFetch('GET', '/competitions/' + filter.cid + '/participations/' + filter.id, undefined,
      data => {
        if (onSuccess) onSuccess(new ParticipationRecord(data))
      },
      onError
  );
}

export function updateParticipation(participation, onSuccess, onError) {
  baseFetch('PATCH', '/competitions/' + participation.get('competition') + '/participations/' + participation.get('id'), participation, onSuccess, onError);
}

export function updateTags(participation, onSuccess, onError) {
  baseFetch('PATCH', '/competitions/' + participation.get('competition') + '/participations/' + participation.get('id') + "?field=tags", participation, onSuccess, onError);
}

export function updateMedicalExpiration(participation, onSuccess, onError) {
  baseFetch('PATCH', '/competitions/' + participation.get('competition') + '/participations/' + participation.get('id') + "?field=medicalExpiration", participation, onSuccess, onError);
}

export function removeParticipation(participation, onSuccess, onError) {
  baseFetch('DELETE', '/competitions/' + participation.get('competition') + '/participations/' + participation.get('id'), undefined, onSuccess, onError);
}

// --  Climb  -----------------------

export function useClimbs(competitionId, filter) {
  return useFetchMany('/competitions/' + competitionId + '/climbs', filter, ClimbRecord);
}

export function useClimb(competitionId, id) {
  return useFetchOne('/competitions/' + competitionId + '/climbs/', id, ClimbRecord, ClimbSchema);
}

export function addClimb(climb, onSuccess, onError) {
  baseFetch('POST', '/competitions/' + climb.get('competition') + '/climbs', climb,
      data => {
        if (onSuccess) onSuccess(new ClimbRecord(data))
      },
      onError
  );
}

export function getClimb(filter, onSuccess, onError) {
  baseFetch('GET', '/competitions/' + filter.cid + '/climbs/' + filter.id, undefined,
      data => {
        if (onSuccess) onSuccess(new ClimbRecord(data))
      },
      onError
  );
}

export function updateClimb(climb, onSuccess, onError) {
  baseFetch('PATCH', '/competitions/' + climb.get('competition') + '/climbs/' + climb.get('id'), climb, onSuccess, onError);
}

export function fixClimbs(dto, onSuccess, onError) {
  baseFetch('PATCH', '/competitions/' + dto.competition + '/climbs', dto, onSuccess, onError);
}

export function removeClimb(climb, onSuccess, onError) {
  baseFetch('DELETE', '/competitions/' + climb.get('competition') + '/climbs/' + climb.get('id'), undefined, onSuccess, onError);
}