import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../Lib/Table/Table";
import {useSections} from "../../../../Api/competitions";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import DefaultActions from "../../../../Lib/Table/DefaultActions";

const List = memo(({competitionId, path}) => {

  const {t} = useTranslation('competition');

  const { data } = useSections(competitionId);

  const columns = [
    {
      accessor: 'id',
      Header: t('general:id')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'status',
      Header: t('general:status')
    },
    {
      accessor: 'rank',
      Header: t('rank')
    },
    {
      accessor: 'climbingType',
      Header: t('climbingType')
    },
    {
      accessor: 'tryLimit',
      Header: t('tryLimit')
    },
    {
      accessor: 'ageGroups',
      Header: t('ageGroups')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={"/admin/competitions/" + competitionId + "/sections/"} id={row.original.get('id')} hideDelete/>
    }
  ];

  return (
      <ContentBox path={path} add={"/admin/competitions/" + competitionId + "/sections/add"}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default List;