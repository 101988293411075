import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {ContentRecord, ContentSchema} from "../Data/content";

export function useContents(filter) {
  return useFetchMany('/contents', filter, ContentRecord);
}

export function useContent(id) {
    return useFetchOne('/contents', id, ContentRecord, ContentSchema);
}

export function addContent(content, onSuccess, onError) {
    baseFetch('POST', '/contents', content,
        data => {
            if (onSuccess) onSuccess(new ContentRecord(data))
        },
        onError
    );
}

export function getContents(filter, onSuccess, onError) {
    baseFetch('GET', '/contents', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new ContentRecord(r)))
        },
        onError
    );
}

export function getContent(id, onSuccess, onError) {
    baseFetch('GET', '/contents/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new ContentRecord(data))
        },
        onError
    );
}

export function updateContent(content, onSuccess, onError) {
    baseFetch('PATCH', '/contents/' + content.get('id'), content, onSuccess, onError);
}

export function removeContent(content, onSuccess, onError) {
    baseFetch('DELETE', '/contents/' + content.get('id'), undefined, onSuccess, onError);
}
