import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../Lib/Table/Table";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useClubs} from "../../Api/clubs";
import DefaultActions from "../../Lib/Table/DefaultActions";

const urlNs = "/clubs/";

const List = memo((props) => {

  const {path} = props;

  const {t} = useTranslation("club");

  const { data } = useClubs();

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier'),
      sortType: 'basic',
    },
    {
      accessor: 'name',
      Header: t('general:name'),
      sortType: 'basic'
    },
    {
      accessor: 'mnemonic',
      Header: t('mnemonic'),
      sortType: 'basic'
    },
    {
      accessor: 'status',
      Header: t('general:status'),
      Cell: ({row}) => <span>{t(row.values.status)}</span>,
      sortType: 'basic',
      disableFilters: true,
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={urlNs} id={row.values.id} />,
      disableFilters: true,
    }
  ];

  return (
    <ContentBox path={path} add={urlNs + "add"}>
      <Table columns={columns} data={data} />
    </ContentBox>
  );
});

export default List;