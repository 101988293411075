import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addSectionRoute, removeSectionRoute, updateSectionRoute, useSectionRoute} from "../../../../../Api/competitions";
import debug from "../../../../../Lib/debug";
import Form from "./Form";
import {SectionRouteRecord, SectionRouteSchema} from "../../../../../Data/Competition";
import {useTranslation} from "react-i18next";

export default (props) => useRoutes(subRoutes)(props)

const subRoutes = {
  "/": () => (props) => <Routes view="list" {...props}/>,
  "/add": () => (props) => <Routes view="add" {...props}/>,
  "/:id/edit": ({id}) => (props) => <Routes view="edit" {...props} id={id}/>,
  "/:id/remove": ({id}) => (props) => <Routes view="remove" {...props} id={id}/>
};

const Routes = ({view, id, competitionId, sectionId, path}) => {

  debug("RENDER", "Competitions.Sections.Routes", () => []);

  const {loadingStatus, data} = useSectionRoute(competitionId, sectionId, id);

  const prefix = "/admin/competitions/" + competitionId + "/sections/" + sectionId + "/routes/";

  const viewData = (loadingStatus ? new SectionRouteRecord(SectionRouteSchema.default()) : data);

  const {t} = useTranslation('competition');

  const label = (view === 'add' ? t('add-section-route') : viewData.get('name'));

  const np = path.slice(0); // to avoid modifying parent path

  switch (view) {
    case "list" :

      return <List competitionId={competitionId} sectionId={sectionId} path={np}/>;

    case "add" :

      np.push({label: label, url: prefix + "add"});
      return <Form record={viewData.set('competition', competitionId).set('section', sectionId)} path={np} view={view} api={addSectionRoute}/>;

    case "remove" :

      np.push({label: label, url: prefix + id + "/remove"});
      return <Form id={id} record={viewData} path={np} view={view} api={removeSectionRoute}/>;

    case "edit" :

      np.push({label: label, url: prefix + id + "/edit"});
      return <Form id={id} record={viewData} path={np} view={view} api={updateSectionRoute}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};