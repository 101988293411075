import { Record } from 'immutable';
import {date, mixed, number, object, string, boolean, array} from "yup";
import {ageGroups} from "./agegroups";
import moment from "moment";

export const CompetitionRecord = Record({
  id: 0,
  name: '',
  status: '',
  climbingTypes: [],
  year: moment().year(),
  mnemonic: '',
  event: 0,
  notes: ''
});

export const CompetitionSchema = object().shape({
  id: number().min(0).default(0),
  status: mixed().oneOf(['PREPARATION', 'ACTIVE', 'CLOSED']),
  climbingTypes: array().of(mixed().oneOf(['BOULDER', 'LEAD', 'SPEED'])),
  year: number().integer().required().min(0).default(moment().year()),
  name: string().required().min(5).max(100).default(''),
  mnemonic: string().required().min(3).max(3).default(''),
  event: number().min(0).default(0),
  notes: string().min(0).max(10000).default('')
});

export const CompetitionResultRecord = Record({
  id: 0,
  name: '',
  status: '',
  sections: { },
  partitions: []
});

export const ParticipationRecord = Record({
  id: 0,
  competition: 0,
  principal: 0,
  name: '',
  sections: [],
  startNumber: 0,
  ageGroup: '',
  medicalExpiration: null,
  medicalValid: false,
  registered: false,
  tags: ''
});

export const ParticipationSchema = object().shape({
  id: number().required().min(0).default(0),
  competition: number().required().min(0).default(0),
  principal: number().required().min(0).default(0),
  name: string().required().min(5).max(100).default(''),
  sections: array().of(number().integer()).default([]),
  ageGroup: mixed().oneOf(ageGroups).required(),
  medicalExpiration: date().nullable(),
  medicalValid: boolean().required().default(false),
  registered: boolean().required().default(false),
  tags: string().min(0).max(100).default('')
});

export const SectionRecord = Record({
  id: 0,
  competition: 0,
  name: '',
  status: '',
  rank: '',
  climbingType: '',
  tryLimit: 1,
  ageGroups: [],
  options: ''
});

export const SectionSchema = object().shape({
  id: number().required().min(0).default(0),
  competition: number().required().min(0).default(0),
  name: string().required().min(2).max(100).default(''),
  status: mixed().oneOf(["PREPARATION", "ACTIVE", "CLOSED"]).required(),
  rank: mixed().oneOf(["QUALIFIER", "QUATERFINAL", "SEMIFINAL", "FINAL", "SUPERFINAL"]).required(),
  climbingType: mixed().oneOf(["BOULDER", "LEAD", "SPEED"]).required(),
  tryLimit: number().required().min(1).default(1),
  ageGroups: array().of(mixed().oneOf(ageGroups)).required(),
  options: string().min(0).max(100).default(''),
});

export const SectionGroupRecord = Record({
  id: 0,
  competition: 0,
  section: 0,
  name: '',
  status: null
});

export const SectionGroupSchema = object().shape({
  id: number().required().min(0).default(0),
  competition: number().required().min(0).default(0),
  section: number().required().min(0).default(0),
  name: string().required().min(5).max(100).default(''),
  status: mixed().oneOf(["PREPARATION", "ACTIVE", "CLOSED"]).required()
});

export const SectionRouteRecord = Record({
  id: 0,
  competition: 0,
  section: 0,
  position: 1,
  displayPosition: 1,
  label: '',
  type: 'NORMAL',
  color: '',
  gender: 'BOTH',
  maxHoldNumber: 2,
  options: ''
});

export const SectionRouteSchema = object().shape({
  id: number().required().min(0).default(0),
  competition: number().required().min(0).default(0),
  section: number().required().min(0).default(0),
  position: number().required().min(1).default(0),
  displayPosition: number().required().min(1).default(0),
  label: string().required().min(1).max(10).default(''),
  color: string().required().min(1).max(15).default(''),
  type: mixed().oneOf(["OPTIONAL", "QUALIFIER", "NORMAL"]).required(),
  gender: mixed().oneOf(["BOTH", "MALE", "FEMALE"]).required(),
  maxHoldNumber: number().required().min(2).default(2),
  options: string().min(0).max(100).default(''),
});

export const SectionResultRecord = Record({
  id: 0,
  name: '',
  startNumber: '',
  routeResults: [],
  points: 0,
  tops : 0,
  zones: 0,
  topTries: 0,
  zoneTries: 0
});

export const ClimbRecord = Record({
  id: 0,
  uuid: null,
  competition: 0,
  section: 0,
  group: 0,
  participation: 0,
  route: 0,
  startTime: null,
  endTime: null,
  holdNumber: 0,
  reachUp: false
});

export const ClimbSchema = object().shape({
  id: number().required().min(0).default(0),
  competition: number().required().min(0).default(0),
  section: number().required().min(0).default(0),
  group: number().required().min(0).default(0),
  participation: number().required().min(0).default(0),
  route: number().required().min(0).default(0),
  startTime: date().required(),
  endTime: date().required(),
  holdNumber: number().required().min(0),
  reachUp: boolean().required().default(false)
});