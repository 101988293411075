import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addClub, removeClub, updateClub, useClub} from "../../Api/clubs";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {ClubRecord, ClubSchema} from '../../Data/Club';

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Clubs view="list"/>,
  "/add": () => <Clubs view="add"/>,
  "/:id/edit": ({id}) => <Clubs view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Clubs view="remove" id={id}/>,
};

const Clubs = ({view, id}) => {

  debug("RENDER", "Clubs", () => [view]);

  const {t} = useTranslation('club');

  const {loadingStatus, data} = useClub(id);

  const viewData = (loadingStatus ? new ClubRecord(ClubSchema.default()) : data);

  const path = [{label: t('club'), url: "/clubs"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('label');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/clubs/add"});
      return <Form id={id} record={viewData} path={path} view={view} api={addClub}/>;

    case "remove" :

      path.push({label: label, url: "/clubs/" + id + "/remove"});
      return <Form id={id} record={viewData} path={path} view={view} api={removeClub}/>;

    case "edit" :

      path.push({label: label, url: "/clubs/" + id + "/edit"});
      return <Form id={id} record={viewData} path={path} view={view} api={updateClub}/>;


    default :
      return <div>invalid view: {view}</div>
  }
};

