import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../../../Lib/form/useForm";
import {SectionRecord, SectionSchema} from "../../../../../Data/Competition";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import Section from "../../../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRadioGroup from "../../../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";

export default ({competitionId, id, record, path, view, api}) => {

  const {t} = useTranslation('competition');

  const form = useForm({
    recordType: SectionRecord,
    recordSchema: SectionSchema,
    namespace: 'competition',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        <Section title={t('section')}>
          <ValidatedInput name="name"/>
          <ValidatedRadioGroup name="status">
            <ValidatedRadio name="status" value="PREPARATION"/>
            <ValidatedRadio name="status" value="ACTIVE"/>
            <ValidatedRadio name="status" value="CLOSED"/>
          </ValidatedRadioGroup>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
};