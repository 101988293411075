import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {removeParticipation, updateParticipation, useParticipation} from "../../../../Api/competitions";
import debug from "../../../../Lib/debug";
import Form from "./Form";
import {useTranslation} from "react-i18next";
import {ParticipationRecord, ParticipationSchema} from "../../../../Data/Competition";
import Add from "./Add";

export default (props) => useRoutes(subRoutes)(props)

const subRoutes = {
  "/": () => (props) => <Participations view="list" {...props}/>,
  "/add": () => (props) => <Participations view="add" {...props}/>,
  "/:id/edit": ({id}) => (props) => <Participations view="edit" {...props} id={id}/>,
  "/:id/remove": ({id}) => (props) => <Participations view="remove" {...props} id={id}/>
};


const Participations = ({view, id, competitionId, path}) => {

  debug("RENDER", "Competitions.Participations", () => []);

  const {loadingStatus, data} = useParticipation(competitionId, id);

  const prefix = "/admin/competitions/" + competitionId + "/participations/";

  const viewData = (loadingStatus ? new ParticipationRecord(ParticipationSchema.default()) : data);

  const {t} = useTranslation('competition');

  const label = (view === 'add' ? t('add-participation') : viewData.get('name'));

  const np = path.slice(0); // to avoid modifying parent path

  switch (view) {
    case "list" :

      return <List competitionId={competitionId} path={np}/>;

    case "add" :

      np.push({label: label, url: prefix + "add"});
      return <Add competitionId={competitionId} path={np} />;

    case "remove" :

      np.push({label: label, url: prefix + id + "/remove"});
      return <Form competitionId={competitionId} id={id} record={viewData} path={np} view={view} api={removeParticipation}/>;

    case "edit" :

      np.push({label: label, url: prefix + id + "/edit"});
      return <Form competitionId={competitionId} id={id} record={viewData} path={np} view={view} api={updateParticipation}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};