import React from "react";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";

import customInputStyle from "../../../material-dashboard/assets/jss/components/customInputStyle.jsx";
import {useFormField} from "../../useFormField";
import moment from "moment";

const useStyles = makeStyles(customInputStyle);

// TODO not sure about this timeAsInt, think about it

const getValue = (formValue, timeAsInt) => {
  if (isNaN(formValue) || ! timeAsInt) {
    return formValue == null ? '' : formValue;
  } else {
    return moment({ hour: Math.floor(formValue / 100), minute: formValue % 100})
  }
};

const setValue = (ff, value, timeAsInt) => {
  if (value === '') {
    ff.onChange(null);
    return;
  }

  if (!value.isValid || !value.isValid()) {
    ff.onChange(value);
    return;
  }

  if (timeAsInt) {
    ff.onChange(value.hours() * 100 + value.minutes())
  } else {
    ff.onChange(value)
  }
};

function ValidatedDateTime(props) {
  const {disabled, date, time, timeAsInt} = props;

  const classes = useStyles();

  const ff = useFormField(props, classes);

  const labelClasses = classNames(classes.labelRoot, {
    [classes.labelRootError]: ff.status === "invalid",
    [classes.labelRootSuccess]: ff.status === "valid"
  });

  const datetimeClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: false,
    [classes.disabled]: disabled,
    [classes.underlineError]: ff.status === "invalid",
    [classes.underlineSuccess]: ff.status === "valid",
    [classes.underline]: true,
    [classes.whiteUnderline]: false
  });

  const formControlClasses = classes.formControl;

  let helpTextClasses = classNames({
    [classes.labelRootError]: ff.status === "invalid",
    [classes.labelRootSuccess]: ff.status === "valid"
  });

  const value = getValue(ff.value, timeAsInt);

  return (
      <FormControl className={formControlClasses} fullWidth>
        <InputLabel
            className={labelClasses}
            shrink={value !== undefined && value !== ''}
            htmlFor={ff.id}>
          {ff.labelText}
        </InputLabel>
        <Datetime
            id={ff.id}
            classNames={datetimeClasses}
            inputProps={{
              className: "form-control " + classes.dateTimeInput,
              disabled: ff.disabled
            }}
            dateFormat={date === false ? false : "YYYY-MM-DD"}
            timeFormat={time === false ? false : "HH:mm"}
            value={value}
            closeOnSelect={true}
            onChange={(value) => setValue(ff, value, timeAsInt)}
        />
        {ff.helperText ?
            <FormHelperText id={ff.id + "-text"} className={helpTextClasses}>
              {ff.helperText}
            </FormHelperText>
            : null}
      </FormControl>
  );
}

export default ValidatedDateTime;