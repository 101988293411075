  import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../../Lib/Table/Table";
import {useSectionRoutes} from "../../../../../Api/competitions";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import DefaultActions from "../../../../../Lib/Table/DefaultActions";

const List = memo(({competitionId, sectionId, path}) => {

  const {t} = useTranslation('competition');

  const { data } = useSectionRoutes(competitionId, sectionId);

  const columns = [
    {
      accessor: 'id',
      Header: t('id')
    },
    {
      accessor: 'position',
      Header: t('position')
    },
    {
      accessor: 'displayPosition',
      Header: t('displayPosition')
    },
    {
      accessor: 'type',
      Header: t('type')
    },
    {
      accessor: 'label',
      Header: t('label')
    },
    {
      accessor: 'gender',
      Header: t('gender')
    },
    {
      accessor: 'color',
      Header: t('color')
    },
    {
      accessor: 'maxHoldNumber',
      Header: t('max-hold-number')
    },
    {
      accessor: 'options',
      Header: t('options')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={"/admin/competitions/" + competitionId + "/sections/" + sectionId + "/routes/"} id={row.values.id} hideDelete/>
    }
  ];

  return (
      <ContentBox path={path} add={"/admin/competitions/" + competitionId + "/sections/" + sectionId + "/routes/add"}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default List;