import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Account from "../Views/Account/i18n"
import Competition from "../Views/Competitions/i18n"
import Event from "../Views/Events/i18n"
import Championship from "../Views/Championships/i18n";
import Dashboard from "../Views/Dashboard/i18n";
import Content from "../Views/Contents/i18n";
import Document from "../Views/Documents/i18n";

const resources = {
  hu: {
    general: {
      "actions": "Műveletek",
      "name": "Név",
      "status": "Státusz",
      "identifier": "Azonosító",
      "save.success": "Sikeres mentés.",
      "save.error": "Nem sikerült a mentés!",
      "execute.success" : "Sikeres végrehajtás",
      "execute.error": "Hiba végrehajtás közben",
      "back" : "Vissza",
      "save" : "Mentés",
      "close" : "Bezár",
      "download" : "Letöltés",
      "open" : "Megnyitás"
    },

    header: {
      "brand": "MHSSZ VR",
      "dashboard": "Kezdőlap",
      "championship": "Bajnokság",
      "results": "Eredmények",
      "community": "Közösség",
      "information": "Információk",
      "registration": "Regisztráció",
      "login": "Belépés",
      "landing": "Kezdőlap",
      "account": "Fiók",
      "logout": "Kilépés"
    },

    account : Account.hu,
    competition: Competition,
    event: Event,
    championships: Championship,
    dashboard: Dashboard,
    contents: Content,
    documents: Document,

    // TODO check this and move to the appropriate place
    translation: {
      "Dashboard": "Vezérlőpult",
      "logout": "Kilépés",
      "Notification": "Értesítések",
      "Profile": "Profil",
      "Settings": "Beállítások",
      "Logout": "Kilépés",
      "loading": "betöltés",
      "communication.error": "Kommunikációs hiba, kérjük probálkozz később.",
      "PREV": "Előző",
      "NEXT": "Következő",
      "FIRST": "Első",
      "LAST": "Utolsó",
      "Contact": "Kapcsolat"
    },

    club: {
      "club": "Egyesület",
      "clubs": "Egyesületek",
      "list": "Egyesületek",
      "add": "Egyesület hozzáadása",
      "edit": "Egyesület szerkesztése",
      "delete": "Egyesület törlése",
      "name": "Név",
      "mnemonic": "Rövidítés",
      "ACTIVE": "aktív",
      "INACTIVE": "inaktív",
      "status": "Státusz",
      "save": "Egyesület mentése",
      "back": "Mégse",
    },

    session: {
      "expired.message": "Lejárt a munkamenet, kérjük lépjen be újra a Belépés menüpontban.",
      "expired.title": "Lejárt munkamenet"
    },

    template: {
      "templates": "Minták",
      "template": "Minta",
      "template-fields-explanation": "A minta nevét a rendszer által ismert nevek közül kell választani. Ez a név nem jelenik meg a felhasználó számára sehol.",
      "add": "Minta hozzáadása",
      "edit": "Minta szerkesztése",
      "delete": "Minta törlése",
      "name": "Név",
      "language": "Nyelv",
      "title": "Cím",
      "content": "Tartalom",
      "save": "Minta mentése",
      "back": "Mégse"
    }
  },

  en: {
    general: {
      "actions": "Actions",
      "name": "Name",
      "status": "Status",
      "identifier": "Identifier",
      "save.success": "Save success.",
      "save.error": "Save failed.",
      "execute.success" : "Execute success.",
      "execute.error": "Execution error.",
      "back" : "Back",
      "save" : "Save",
      "close" : "Close",
      "download" : "Download",
      "open" : "Open"
    },

    header: {
      "brand": "MHSSZ VR",
      "dashboard": "Dashboard",
      "championship": "Championship",
      "results": "Results",
      "community": "Community",
      "information": "Information",
      "registration": "Registration",
      "login": "Login",
      "landing": "Landing",
      "account": "Account",
      "logout": "Logout"
    },

    account : Account.en,
    competition: Competition,
    event: Event,
    championships: Championship,
    dashboard: Dashboard,
    contents: Content,
    documents: Document,

    // TODO check this and move to the appropriate place
    translation: {
      "Dashboard": "Dashboard",
      "logout": "Logout",
      "Notification": "Notifications",
      "Profile": "Profil",
      "Settings": "Settings",
      "Logout": "Logout",
      "loading": "loading",
      "communication.error": "Communication error, please try later.",
      "PREV": "Previous",
      "NEXT": "Next",
      "FIRST": "First",
      "LAST": "Last",
      "Contact": "Contact"
    },

    club: {
      "club": "Club",
      "clubs": "Clubs",
      "list": "Clubs",
      "add": "Add Club",
      "edit": "Edit Club",
      "delete": "Delete Club",
      "name": "Name",
      "mnemonic": "Mnemonic",
      "ACTIVE": "active",
      "INACTIVE": "inactive",
      "status": "Status",
      "save": "Save Club",
      "back": "Cancel",
    },

    session: {
      "expired.message": "Expired session, please log in again,",
      "expired.title": "Expired session"
    },

    template: {
      "templates": "Templates",
      "template": "Template",
      "template-fields-explanation": "You have to choose a name known to the system. The users does not see this name.",
      "add": "Add template",
      "edit": "Edit template",
      "delete": "Delete template",
      "name": "Name",
      "language": "Language",
      "title": "Title",
      "content": "Content",
      "save": "Save template",
      "back": "Cancel"
    }
  }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: localStorage.getItem("vr-language") === 'en' ? 'en' : 'hu',
      whitelist: ["hu", "en"],
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });


export default i18n;