import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import {EventRecord, EventSchema} from "../../../Data/Event";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {Navigation} from "./Navigation";
import Grid from "@material-ui/core/Grid";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedDateTime from "../../../Lib/form/Components/ValidatedDateTime/ValidatedDateTime";
import ValidatedRichTextEditor from "../../../Lib/form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedDate from "../../../Lib/form/Components/ValidatedDateTime/ValidatedDate";

export default ({id, record, path, view, api}) => {

  const {t} = useTranslation('event');

  const form = useForm({
    recordType: EventRecord,
    recordSchema: EventSchema,
    namespace: 'event',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        {view === "add" ? null : <Navigation parentId={id}/>}

        <Grid item xs={12}>
          <Grid container direction={"column"}>

            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>

                <Grid item xs={12} md={6}>
                  <Section title={t('event')}>
                    <ValidatedInput name="name"/>
                    <ValidatedInput name="label"/>
                    <ValidatedRadioGroup name="type">
                      <ValidatedRadio name="type" value="BOULDER"/>
                      <ValidatedRadio name="type" value="LEAD"/>
                      <ValidatedRadio name="type" value="SPEED"/>
                      <ValidatedRadio name="type" value="OTHER"/>
                    </ValidatedRadioGroup>
                    <ValidatedRadioGroup name="category">
                      <ValidatedRadio name="category" value="AMATEUR"/>
                      <ValidatedRadio name="category" value="PROFESSIONAL"/>
                      <ValidatedRadio name="category" value="MIXED"/>
                    </ValidatedRadioGroup>
                    <ValidatedRadioGroup name="status">
                      <ValidatedRadio name="status" value="PREPARATION"/>
                      <ValidatedRadio name="status" value="ACTIVE"/>
                      <ValidatedRadio name="status" value="CLOSED"/>
                    </ValidatedRadioGroup>
                  </Section>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Section title={t('registration')}>
                    <ValidatedDateTime name="registrationPhase1"/>
                    <ValidatedDateTime name="registrationPhase2"/>
                    <ValidatedDateTime name="registrationPhase3"/>
                    <ValidatedDateTime name="registrationEnd"/>
                  </Section>
                </Grid>

              </Grid>
            </Grid>

            <Section title={t('location.title')}>
              <Grid item xs={12}>
                <Grid container direction={"row"} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ValidatedDate name="startDate"/>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ValidatedDate name="endDate"/>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ValidatedInput name="location"/>
              </Grid>
            </Section>

            <Section title={t('description')}>
              <Grid item xs={12}>
                <ValidatedRichTextEditor name="description"/>
              </Grid>
            </Section>

            <Grid item xs={12}>
              <ValidatedSubmit view={view} api={api}/>
            </Grid>

          </Grid>
        </Grid>
      </ContentBox>
  );
}