import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedFileUploader from "../../../Lib/form/Components/ValidatedFileUpload/ValidatedFileUpload";

import {ContentRecord, ContentSchema} from "../../../Data/content";
import ValidatedRichTextEditor from "../../../Lib/form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";

export default ({record, path, view, api}) => {

  const {t} = useTranslation('contents');

  const form = useForm({
    recordType: ContentRecord,
    recordSchema: ContentSchema,
    namespace: 'contents',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        <Section title={t('content')}>
          <ValidatedInput name="name"/>
          <ValidatedInput name="kind"/>
          <ValidatedInput name="links"/>
          <ValidatedRadioGroup name="status">
            <ValidatedRadio name="status" value="HIDDEN"/>
            <ValidatedRadio name="status" value="PUBLIC"/>
            <ValidatedRadio name="status" value="ARCHIVE"/>
          </ValidatedRadioGroup>
          <ValidatedFileUploader name="attachment" apiPath="/attachments" maxFileSize={20000000}/>
          <ValidatedRichTextEditor name="summary"/>
          <ValidatedRichTextEditor name="details"/>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
}