import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {ChampionshipRecord, ChampionshipResultRecord, ChampionshipResultSchema, ChampionshipSchema} from "../Data/championship";


export function useChampionships(filter) {
  return useFetchMany('/championships', undefined, ChampionshipRecord);
}

export function useChampionship(id) {
  return useFetchOne('/championships', id, ChampionshipRecord, ChampionshipSchema);
}

export function useChampionshipResults(id) {
  return useFetchOne('/championships/' + id + "/results", null, ChampionshipResultRecord, ChampionshipResultSchema);
}

export function addChampionship(championship, onSuccess, onError) {
    baseFetch('POST', '/championships', championship,
        data => {
            if (onSuccess) onSuccess(new ChampionshipRecord(data))
        },
        onError
    );
}

export function getChampionships(filter, onSuccess, onError) {
    baseFetch('GET', '/championships', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new ChampionshipRecord(r)))
        },
        onError
    );
}

export function getChampionship(id, onSuccess, onError) {
    baseFetch('GET', '/championships/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new ChampionshipRecord(data))
        },
        onError
    );
}

export function updateChampionship(championship, onSuccess, onError) {
    baseFetch('PATCH', '/championships/' + championship.get('id'), championship, onSuccess, onError);
}

export function removeChampionship(championship, onSuccess, onError) {
    baseFetch('DELETE', '/championships/' + championship.get('id'), undefined, onSuccess, onError);
}