import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../../Lib/form/useForm";
import {SectionRecord, SectionSchema} from "../../../../Data/Competition";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRadioGroup from "../../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import Grid from "@material-ui/core/Grid";
import {ageGroups} from "../../../../Data/agegroups";
import ValidatedCheckbox from "../../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import ValidatedCheckboxGroup from "../../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckboxGroup";
import {Navigation} from "./Navigation";
import {Typography} from "@material-ui/core";

export default ({competitionId, id, record, path, view, api}) => {

  const {t} = useTranslation('competition');

  const form = useForm({
    recordType: SectionRecord,
    recordSchema: SectionSchema,
    namespace: 'competition',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        {view === "add" ? null : <Navigation competitionId={competitionId} sectionId={id}/>}

        <Section title={t('section')}>
          <ValidatedInput name="name"/>
          <ValidatedRadioGroup name="status">
            <ValidatedRadio name="status" value="PREPARATION"/>
            <ValidatedRadio name="status" value="ACTIVE"/>
            <ValidatedRadio name="status" value="CLOSED"/>
          </ValidatedRadioGroup>
          <ValidatedRadioGroup name="rank">
            <ValidatedRadio name="rank" value="QUALIFIER"/>
            <ValidatedRadio name="rank" value="QUATERFINAL"/>
            <ValidatedRadio name="rank" value="SEMIFINAL"/>
            <ValidatedRadio name="rank" value="FINAL"/>
            <ValidatedRadio name="rank" value="SUPERFINAL"/>
          </ValidatedRadioGroup>
          <ValidatedRadioGroup name="climbingType">
            <ValidatedRadio name="climbingType" value="BOULDER"/>
            <ValidatedRadio name="climbingType" value="LEAD"/>
            <ValidatedRadio name="climbingType" value="SPEED"/>
          </ValidatedRadioGroup>
          <ValidatedInput name="tryLimit"/>
          <ValidatedCheckboxGroup name="ageGroups">
            <Grid container>
              {ageGroups.map(a => <ValidatedCheckbox name="ageGroups" value={a} key={a} multi/>)}
            </Grid>
          </ValidatedCheckboxGroup>
          <ValidatedInput name="options"/>
          <div><Typography color="textSecondary"><small>{t('section-options-help')}</small></Typography></div>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
};