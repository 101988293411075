import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import styles from "../../../material-dashboard/assets/jss/customSelectStyle.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/styles";
import {useFormField} from "../../useFormField";

const useStyles = makeStyles(styles);

function ValidatedSelect(props) {

  const {name, disabled, api} = props;

  const classes = useStyles();

  const ff = useFormField(props, classes);

  const {data} = api();

  // FIXME select error handling

  return (
      <FormControl
          fullWidth
          className={classes.selectFormControl}>
        <InputLabel
            htmlFor={ff.id}
            className={ff.labelClasses}
            shrink={ff.value !== 0}>
          {ff.labelText}
        </InputLabel>
        <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            disabled={disabled}
            value={ff.value}
            onChange={(e) => ff.onChange(e.target.value)}
            inputProps={{
              name: name,
              id: ff.id,
              value: ff.value
            }}>
          {data.map(item =>
              <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  key={item.id}
                  value={item.id}>
                {item.name}
              </MenuItem>
          )}
        </Select>
      </FormControl>
  );
}

export default ValidatedSelect;