import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../../Lib/Table/Table";
import {useSectionResults} from "../../../../../Api/competitions";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";

const Results = memo(({competitionId, sectionId, path}) => {

  const {t} = useTranslation('competition');

  const { data } = useSectionResults(competitionId, sectionId);

  data.sort((a,b) => b.points - a.points);

  const columns = [
    {
      accessor: 'name',
      Header: t('name')
    },
    {
      accessor: 'tops',
      Header: t('tops')
    },
    {
      accessor: 'zones',
      Header: t('zones')
    },
    {
      accessor: 'topTries',
      Header: t('topTries')
    },
    {
      accessor: 'zoneTries',
      Header: t('zoneTries')
    },
    {
      accessor: 'points',
      Header: t('points')
    },
  ];

  return (
      <ContentBox path={path}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default Results;