import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {useFilters, usePagination, useSortBy, useTable} from "../react-table";
import tableStyle from "./tableStyle"
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Card from "../material-dashboard/components/Card/Card";
import CardBody from "../material-dashboard/components/Card/CardBody";
import CustomInput from "../material-dashboard/components/CustomInput/CustomInput";
import {CloudDownload, Print, Search, ViewHeadline} from "@material-ui/icons";
import Button from "../material-dashboard/components/CustomButtons/Button";
import Pagination from "../material-dashboard/components/Pagination/Pagination";
import Immutable from "immutable";
import Hidden from "@material-ui/core/Hidden";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUpRounded"
import matchSorter from "match-sorter";


const useStyles = makeStyles(tableStyle);

const TableSearchField = ({handleSearch}) => {
    const classes = useStyles();

    const [state, setState] = useState("");

    return (
        <div>
            <Button justIcon color={"primary"} size={"sm"}
                    onClick={() => handleSearch(state)}>
                <Search/>
            </Button>&nbsp;
            <CustomInput name={"filter"}
                         formControlProps={{
                             className: classes.tableHeaderControl,
                             value: state,
                             onChange: event => setState(event.target.value),
                             onKeyPress: event => {
                                 if (event.key === "Enter") {
                                     handleSearch(state);
                                 }
                             }
                         }}/>
        </div>
    );
};

const TablePagination = (props) => {
    const {pageCount, pageIndex, previousPage, gotoPage, nextPage} = props;
    let pages = [];

    // pageIndex stars from 0

    const second = Math.max(pageIndex - 2, 1);
    const secondToLast = pageCount - 2;
    const last = pageCount - 1;

    pages.push({text: "PREV", onClick: previousPage, pageIndex: Math.max(0, pageIndex - 1)});

    pages.push({active: 0 === pageIndex, text: (1), onClick: () => gotoPage(0)});

    if (pageCount >= 9) {

        if (second > 1) pages.push({active: false, text: "..."});

        for (let i = second; i <= Math.min(second + 4, secondToLast); i++) {
            pages.push({active: i === pageIndex, text: (i + 1), onClick: () => gotoPage(i)});
        }

        if (secondToLast - 2 > pageIndex) pages.push({active: false, text: "..."});

    } else {

        for (let i = 1; i <= secondToLast; i++) {
            pages.push({active: i === pageIndex, text: (i + 1), onClick: () => gotoPage(i)});
        }

    }

    if (pageCount > 1) pages.push({
        active: last === pageIndex,
        text: last + 1,
        onClick: () => gotoPage(last)
    });

    pages.push({text: "NEXT", onClick: nextPage, pageIndex: (pageCount + 1)});

    return (
        <Pagination pages={pages}/>
    );
};

export const ResponsiveTable = ({columns, handheldColumns, data, noHeaders, tableProps}) => {
    return <React.Fragment>
        <Hidden mdUp>
            <PlainTable columns={handheldColumns ? handheldColumns : columns} data={data} tableProps={tableProps}/>
        </Hidden>
        <Hidden smDown>
            {noHeaders
                ? <PlainTable columns={columns} data={data} tableProps={tableProps}/>
                : <DesktopTable columns={columns} data={data} tableProps={tableProps}/>
            }
        </Hidden>
    </React.Fragment>
};

export const DesktopTable = ({columns, data, tableProps}) => {

    const classes = useStyles();

    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: (rows, id, filterValue) => matchSorter(rows, filterValue, {keys: [row => Object.values(row.values)]})
        }),
        []
    );

    const [tableState, setTableState] = useState(Immutable.Map({
        windowHeight: window.innerHeight,
        height: 0,
        headerHeight: 0,
    }));

    // Hidden column for handling global search in the table

    columns.push({
        id: "tableSearch",
        accessor: "tableSearch",
        filter: "fuzzyText",
        show: false
    });

    // FIXME table render count (double render after data arrived)

    const table = useTable({
            columns: columns,
            data: data,
            pageSize: 10,
            manualFilters: false,
            disableFilters: false,
            filterTypes,
            ...tableProps
        },
        useFilters,
        useSortBy,
        usePagination);

    const {
        getTableProps,
        headerGroups,
        prepareRow,

        setFilter,

        pageIndex,
        page,
        pageCount,
        setPageSize,
        nextPage,
        previousPage,
        gotoPage
    } = table;

    const handleSearch = value => setFilter("tableSearch", value);

    const ref = useRef(null);
    const headerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setTableState(tableState.set('windowHeight', window.innerHeight));
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [tableState]);

    const windowHeight = tableState.get('windowHeight');

    useLayoutEffect(() => {
        if (!ref.current) return;
        if (!headerRef.current) return;

        let h = ref.current.clientHeight;
        let hh = headerRef.current.clientHeight;

        if (h === 0 || hh === 0) return;
        if (h === tableState.get('height') && hh === tableState.get('headerHeight')) return;

        const pageSize = Math.floor((h - hh - 25 - 30 - 56) / 41);

        // console.log(tableState.get('windowHeight'), h, hh, pageSize);

        if (pageSize === 0) return;  // 0 means infinite loop in react-table

        setPageSize(pageSize);

        setTableState(tableState.merge({
            height: h,
            headerHeight: hh
        }));
    }, [tableState, setPageSize, windowHeight]);

    return (
        <div ref={ref} style={{flexGrow: 1, height: "100%", minHeight: "100%"}}>
            <Card className={classes.tableCard}>
                <CardBody>
                    <div className={classes.tableResponsive}>
                        <div ref={headerRef} style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <TableSearchField handleSearch={handleSearch}/>
                            <TablePagination pageCount={pageCount} pageIndex={pageIndex} previousPage={previousPage}
                                             gotoPage={gotoPage} nextPage={nextPage}/>
                            <div>
                                <Button justIcon color={"primary"} size={"sm"}><ViewHeadline/></Button>&nbsp;
                                <Button justIcon color={"primary"} size={"sm"}><Print/></Button>&nbsp;
                                <Button justIcon color={"primary"} size={"sm"}><CloudDownload/></Button>&nbsp;
                            </div>
                        </div>
                        <Table {...getTableProps()} className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                                    {headerGroups[0].headers.map((column) =>
                                        <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}
                                                   className={classes.tableHeadCell + " " + classes.tableCell}>
                                            <b>{column.render('Header')} </b>
                                            <span>
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <ArrowDropDownIcon style={{position: "absolute"}}/> :
                                                            <ArrowDropUpIcon style={{position: "absolute"}}/>) :
                                                        ''}
                                                </span>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {page.map((row) => {

                                        prepareRow(row);

                                        return (
                                            <TableRow {...row.getRowProps()} className={classes.tableRow}>
                                                {row.cells.map(cell => <TableCell {...cell.getCellProps()}
                                                                                  className={classes.tableCell}>{cell.render('Cell')}</TableCell>)}
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </div>)
};

export const PlainTable = ({columns, data, tableProps}) => {

    const classes = useStyles();

    const table = useTable({
        columns: columns,
        data: data,
        ...tableProps
    });

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        rows
    } = table;

    return (
        <div className={classes.handheldTable}>
            <InnerTable
                classes={classes}
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
            />
        </div>
    )
};
const InnerTable = ({classes, getTableProps, headerGroups, rows, prepareRow}) => {
    return <Table {...getTableProps()} className={classes.table}>
        <TableHead>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                {headerGroups[0].headers.map((column) =>
                    <TableCell {...column.getHeaderProps()} className={classes.tableHeadCell + " " + classes.tableCell}>
                        <b>{column.render('Header')}</b>
                        <span>{column.sorted ? (column.sortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((row) => {

                    prepareRow(row);

                    return (
                        <TableRow {...row.getRowProps()} className={classes.tableRow}>
                            {row.cells.map(cell => <TableCell {...cell.getCellProps()}
                                                              className={classes.tableCell}>{cell.render('Cell')}</TableCell>)}
                        </TableRow>
                    )
                }
            )}
        </TableBody>
    </Table>
};
export default ResponsiveTable;