import {useContext} from "react";
import {AuthContext} from "./AuthContext";

export function isLoggedIn(authContext) {
  return (authContext.get('session').get('principalName') !== 'anonymous');
}

export function useLoggedIn() {
  const authContext = useContext(AuthContext);
  return (authContext.get('session').get('principalName') !== 'anonymous');
}

export function hasRole(authContext, role) {
  return (authContext.get('session').get('roles').indexOf(role) !== -1);
}


export function useAccountId() {
  const authContext = useContext(AuthContext);
  return authContext.get('session').get('accountId');
}
