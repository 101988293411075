import {sortColumn} from "./table-sort";

export const createTable = tableStorage => {

    const tableConfig = tableStorage.tableConfig;
    const {id, sortable, styles, stickyHeader} = tableConfig;
    const tableClasses = styles && styles.stTable ? ` class="${styles.stTable}" ` : ``;
    const bodyClasses = styles && styles.stBody ? ` class="${styles.stBody}" ` : ``;

    const wrapper = document.getElementById(`simplexion-table-${id}`);

    const rows = makeRows(tableStorage, styles).join('');

    let caption = '';
    if (tableConfig.caption) caption = makeCaption(tableConfig);

    const head = makeHeader(tableConfig);
    const body = `<tbody${bodyClasses}>${rows}</tbody>`;

    wrapper.innerHTML = `<table id=${id} ${tableClasses}>${caption}${head}${body}</table>`;

    if (stickyHeader && rows) {
        createStickyHeader(tableStorage)
    }

    if (sortable && !stickyHeader) addSortEventListener(tableStorage);
};

const makeCaption = tableConfig => {
    const {caption, styles} = tableConfig;
    const text = caption.captionText;
    const visible = caption.visible ? '' : ' style="display: none" ';
    const classes = styles && styles.stCaption ? ` class="${styles.stCaption}" ` : ``;

    return `<caption${visible}${classes}>${text}</caption>`;
};

const makeHeader = (tableConfig) => {

    const {columns, styles} = tableConfig;

    const headerClasses = styles && styles.stRow ? ` class="${styles.stHeader}" ` : ``;
    const rowClasses = styles && styles.stHeaderRow ? ` class="${styles.stHeaderRow}" ` : ``;
    const cellClasses = styles && styles.stHeaderCell ? ` class="${styles.stHeaderCell}" ` : ``;

    const cells = columns.map(col => {

        const {accessor, header, headerRenderer} = col;

        if (headerRenderer) return headerRenderer(col);

        return `<th${cellClasses} data-accessor="${accessor}">${header}</th>`;

    }).join('');

    return `<thead${headerClasses}><tr${rowClasses}>${cells}</tr></thead>`;
};

const makeRows = tableStorage => {

    return tableStorage.data.map(record => {

        const row = makeRow(record, tableStorage.tableConfig);
        tableStorage.rows[record.get('id')] = row;
        return row;
    });
};

const makeRow = (record, tableConfig) => {

    const {styles, columns} = tableConfig;

    const rowClasses = styles && styles.stRow ? ` class="${styles.stRow}" ` : ``;
    const cellClasses = styles && styles.stCell ? ` class="${styles.stCell}" ` : ``;

    const cells = columns.map(col => {

            const {accessor, renderer} = col;
            let val = record.get(accessor) ? record.get(accessor) : (record.get(accessor) === 0 ? 0 : '');

            if (typeof val === 'string') val = replaceUnsafeChars(val);

            if (renderer) return `<td${cellClasses}>${renderer(record, tableConfig)}</td>`;
            return `<td${cellClasses}>${val}</td>`;
        }
    ).join('');

    return `<tr${rowClasses}>${cells}</tr>`;
};

const addSortEventListener = (tableStorage, sticky) => {

    const {columns, id} = tableStorage.tableConfig;

    const table = sticky ? document.getElementById(`sticky-header-${id}`) : document.getElementById(id);
    const headerCells = table.querySelectorAll('th');

    columns.forEach((col, i) => {

        if (col.sortable === false) return;

        const classes = headerCells[i].getAttribute('class');
        headerCells[i].addEventListener('click', e => sortColumn(e, tableStorage, sticky));
        headerCells[i].setAttribute('class', `${classes || ''} stSortable`)
    });

};

const replaceUnsafeChars = val => {
    return val.replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;')
};

const createStickyHeader = tableStorage => {

    const {id, styles, sortable} = tableStorage.tableConfig;

    const wrapper = document.getElementById(`main-wrapper-${id}`);
    const tableWrapper = document.getElementById(`simplexion-table-${id}`);
    const stickyWrapper = document.getElementById(`sticky-header-${id}`);

    const table = document.getElementById(id);
    const tableBody = table.getElementsByTagName('tbody')[0];
    const tableBodyRow= tableBody.getElementsByTagName('tr');
    const tableBodyCells = tableBodyRow[0].getElementsByTagName('td');
    const tableHead = table.getElementsByTagName('thead')[0];
    const tableHeaderCells = tableHead.getElementsByTagName('th');

    const tableRect = table.getBoundingClientRect();

    // Creates a new table to be used as a sticky header
    const stickyTable = document.createElement('table');
    const stickyHeader = document.createElement('thead');
    const stickyHeaderRow = document.createElement('tr');

    // Gets the widths and heights of the <th> elements of the original <thead>, then clones them and sets their widths and heights on the cloned elements.
    // Finally it appends the cloned element to the <tr> of the sticky header element.
    for (let i = 0; i < tableHeaderCells.length; i++) {
        const th = tableHeaderCells[i];
        const td = tableBodyCells[i];
        const thWidth = th.getBoundingClientRect().width;
        const thHeight = th.getBoundingClientRect().height;
        const tdWidth = td ? td.getBoundingClientRect().width : 0;
        const width = thWidth > tdWidth ? thWidth : tdWidth;

        const clone = th.cloneNode(true);
        clone.setAttribute('style', `min-width: ${width}px; height: ${thHeight}px`);

        stickyHeaderRow.append(clone);
    }

    // Style settings that makes the sticky header work
    const wrapperClasses = wrapper.getAttribute('class');
    const tableWrapperClasses = tableWrapper.getAttribute('class');

    wrapper.setAttribute('style', `width: ${tableRect.width + 17}px`);
    wrapper.setAttribute('classes', wrapperClasses + ' sticky');

    tableWrapper.setAttribute('class', tableWrapperClasses + ' sticky');

    stickyTable.setAttribute('style', `width: ${tableRect.width}px;`);
    stickyTable.setAttribute('class', `${styles.stTable} ${styles.stSticky}`);
    stickyHeader.setAttribute('class', styles.stHeader);

    // Adding the html elements together
    stickyHeader.append(stickyHeaderRow);
    stickyTable.append(stickyHeader);
    stickyWrapper.prepend(stickyTable);

    // Finally, if the table is sortable, attaching the evenet listeners for sorting
    if (sortable) addSortEventListener(tableStorage, true);

};



