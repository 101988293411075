import React, {useContext} from "react";
// noinspection ES6CheckImport
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Face from "@material-ui/icons/Face";
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Button from "../../Lib/material-kit/components/CustomButtons/Button.jsx";
import headerLinksStyle from "./headerLinksStyle.jsx";
import {MdFontDownload, MdTrendingUp, MdLanguage} from "react-icons/md";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {makeStyles} from "@material-ui/styles";
import {isLoggedIn} from "../../Lib/auth/auth";
import {A} from "hookrouter";
import {useTranslation} from "react-i18next";
import {PowerSettingsNew} from "@material-ui/icons";

const useStyles = makeStyles(headerLinksStyle);

// FIXME styles and classes are in a state of chaos

function MenuButton(props) {

  let {menuToggle, to, children, style} = props;

  if (style) {
    style.padding = "8px 16px 8px 14px";
  } else {
    style = { padding: "8px 16px 8px 14px" };
  }

  return (
      <ListItem className={props.classes.listItem}>
        <A href={to}>
          <Button
              color={props.color === undefined ? "success" : props.color}
              className={props.classes.navButton}
              onClick={() => onClick(menuToggle)}
              style={style}
          >
            {children}
          </Button>
        </A>
      </ListItem>
  );
}

function onClick(menuToggle) {
  let isMobile = navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  );
  if (isMobile) {
    menuToggle();
  }
}

function HeaderLinks(props) {

  const {t} = useTranslation("header");

  const authContext = useContext(AuthContext);

  const classes = useStyles();

  const {menuToggle} = props;

  let userLinks;

  if (!isLoggedIn(authContext)) {
    userLinks = (
        <React.Fragment>
          <MenuButton classes={classes} to="/accounts/add" menuToggle={menuToggle}>
            <PersonAdd className={classes.icons}/><span style={{marginLeft: 4}}>{t('registration')}</span>
          </MenuButton>
          <MenuButton classes={classes} to="/accounts/assume" menuToggle={menuToggle}>
            <Face className={classes.icons}/><span style={{marginLeft: 4}}>{t('login')}</span>
          </MenuButton>
        </React.Fragment>
    );
  } else {
    userLinks = (
        <MenuButton classes={classes} style={{backgroundColor: "#396593"}} to="/accounts/renounce" menuToggle={menuToggle}>
          <PowerSettingsNew className={classes.icons}/><span style={{marginLeft: 4}}>{t('logout')}</span>
        </MenuButton>
    )
  }

  return (
      <List className={classes.list + " " + classes.mlAuto}>
        <MenuButton classes={classes} to="/championships" menuToggle={menuToggle}>
          <MdTrendingUp className={classes.icons}/><span style={{marginLeft: 4}}>{t('results')}</span>
        </MenuButton>

        <MenuButton classes={classes} to="/community" menuToggle={menuToggle}>
          <People className={classes.icons}/><span style={{marginLeft: 4}}>{t('community')}</span>
        </MenuButton>

        <MenuButton classes={classes} to="/information" menuToggle={menuToggle}>
          <MdFontDownload className={classes.icons}/><span style={{marginLeft: 4}}>{t('information')}</span>
        </MenuButton>

        {userLinks}

          <Button
              color={props.color === undefined ? "success" : props.color}
              className={classes.navButton}
              onClick={() => switchLanguage()}
              style={{ padding: "8px 16px 8px 14px" }}
          >
              <MdLanguage className={classes.icons}/>
              <span style={{marginLeft: 4}}>{localStorage.getItem("vr-language") === 'en' ? 'HU' : 'EN'}</span>
          </Button>

      </List>
  );
}

function switchLanguage() {
    let currentLanguage = localStorage.getItem("vr-language")
    if (currentLanguage === "en") {
        localStorage.setItem("vr-language", "hu")
    } else {
        localStorage.setItem("vr-language", "en")
    }
    window.location.reload()
}

export default HeaderLinks;
