import {types} from '../AuthActions';
import SessionData from "../Records/SessionData";

export function reducer(state, action) {

  switch (action.type) {
    case types.AUTH_SESSION_ADD:
      return state.merge({
        session: action.session
      });

    case types.AUTH_SESSION_REMOVE:
      return state.merge({
        session: new SessionData()
      });

    default:
      return state;
  }

}