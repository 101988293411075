import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../Lib/Table/Table";
import {useEventGroups} from "../../../../Api/events";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import DefaultActions from "../../../../Lib/Table/DefaultActions";

const List = memo(({eventId, path}) => {

  const {t} = useTranslation('event');

  const { data } = useEventGroups(eventId);

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'position',
      Header: t('position')
    },
    {
      accessor: 'participantLimit',
      Header: t('participantLimit')
    },
    {
      accessor: 'participantCount',
      Header: t('participantCount')
    },
    {
      accessor: 'ageGroups',
      Header: t('ageGroups')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={"/admin/events/" + eventId + "/groups/"} id={row.values.id} hideDelete/>
    }
  ];

  return (
      <ContentBox path={path} add={"/admin/events/" + eventId + "/groups/add"}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default List;