import {types as authTypes} from '../Lib/auth/AuthActions';
import {roles} from "./roles";
import {build} from "../Lib/routing";
import {config} from "../Main/routes";

export function reducer(state, action) {

  switch (action.type) {

    case authTypes.AUTH_SESSION_ADD:
      return state.merge(build(config, action.session.get('roles')));

    case authTypes.AUTH_SESSION_REMOVE:
      return state.merge(build(config, [roles.ANONYMOUS]));

    default:
      return state;
  }

}