/**
 * Build the navigation tree used by the side bar and the routing map used by
 * hookrouter based on the roles provided. Config contains all possible items,
 * the result contains only items the roles allow.
 *
 * @param config
 * @param roles
 * @returns {{routingMap, navTree: Array}}
 */
export function build(config, roles) {
  if (roles.length === 0) roles.push("anonymous");

  const routingMap = {};
  const navTree = _routes(config, roles, routingMap);
  return { routingMap : routingMap, navTree: navTree };
}

function _routes(routesToFilter, roles, routingMap) {

  let navTree = [];

  routesToFilter.forEach(route => {

    for (let i = 0; i < roles.length; i++) {
      if (route.roles.indexOf(roles[i]) === -1) continue;

      let views = route.views ? { views : _routes(route.views, roles, routingMap)} : {  };

      // create a copy so the original config will remain unchanged
      let r = Object.assign({  }, route, views);

      // sanity check
      if (routingMap[r.path] !== undefined) throw new Error("duplicated path: " + r.path);

      // this has to be done before removal of the trailing asterisk
      routingMap[r.path] = r.render;

      // remove the asterisk so SideBar will select the active item properly
      if (r.path.endsWith('*')) r.path = r.path.replace('*', '');

      // add the item to the nav tree
      navTree.push(r);

      break;
    }

  });

  return navTree;
}