import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../Lib/Table/Table";
import {useEvents} from "../../../Api/events";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import DefaultActions from "../../../Lib/Table/DefaultActions";

const urlNs = "/admin/events/";

const List = memo((props) => {

  const {path} = props;

  const {t} = useTranslation('event');

  const { data } = useEvents();

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'startDate',
      Header: t('startDate')
    },
    {
      accessor: 'type',
      Header: t('type')
    },
    {
      accessor: 'status',
      Header: t('status')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={urlNs} id={row.values.id} hideDelete/>
    }
  ];

  return (
      <ContentBox path={path} add={urlNs + "add"}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default List;