import React, {useState} from "react";
import debug from "../../Lib/debug";
import {useTranslation} from "react-i18next";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useSnackbar} from "notistack";
import {closeSnack} from "../../Main/util";
import {mergeAccounts} from "../../Api/accounts";

export default ({competitionId, path}) => {

  debug("RENDER", "Actions", () => []);

  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const merge = () => {
    mergeAccounts(
        intoAccount,
        mergedAccount,
        () => enqueueSnackbar(t('general:execute.success')),
        () => enqueueSnackbar(t('general:execute.error'), {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)})
    )
  };

  const [mergedAccount,setMergedAccount] = useState('');
  const [intoAccount,setIntoAccount] = useState('');

  return (
      <ContentBox path={path}>

        <div>
          <div style={{color: "red", fontSize: 18}}>Account ID, NEM principal id</div>
          Merge account: <input name="mergedAccount" value={mergedAccount} onChange={(e) => setMergedAccount(e.target.value)}/>
          Into: <input name="intoAccount" value={intoAccount} onChange={(e) => setIntoAccount(e.target.value)}/>
          <Button color="success" style={{marginTop: 10}} onClick={merge}>Merge</Button>
        </div>

      </ContentBox>
  );
};