import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {ContentRecord, ContentSchema} from "../../../Data/content";
import {addContent, removeContent, updateContent, useContent} from "../../../Api/contents";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Crud config={crudConfig} view="list"/>,
  "/add": () => <Crud config={crudConfig} view="add"/>,
  "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>
};

const crudConfig = {
  compName: 'Contents',
  namespace: 'contents',
  url: '/contents',
  labelField: 'name',
  Record: ContentRecord,
  Schema: ContentSchema,
  ListComponent: List,
  FormComponent: Form,
  api: {
    use: useContent,
    add: addContent,
    edit: updateContent,
    remove: removeContent
  }
};
