import React, {useEffect, useRef} from 'react';
import {createTable} from "./simplexion-table";
import {filterTable} from "./table-filter";
import {useTranslation} from "react-i18next";


const createColumn = (col, t, nameSpace) => {

    const {accessor, sortable, renderer, headerRenderer} = col;

    let header = col.header;

    if (!col.header) {
        header = t(nameSpace + ':' + accessor);
    }

    return {
        accessor: accessor,
        header: header,
        sortable: sortable,
        renderer: renderer,
        headerRender: headerRenderer,
    }
};

export const SimplexionTable = ({tableConfig, data, filterValue}) => {

    const {t} = useTranslation();

    tableConfig.columns = tableConfig.columns.map(col => createColumn(col, t, tableConfig.nameSpace));

    if (!data) data = [];

    const classes = tableConfig.styles || {};

    const tableStorage = useRef({
        data: data,
        tableConfig: tableConfig,
        rows: {},
        filterValue: filterValue,
    });

    // Creates the table when the component mounts
    // TODO cleanup
    useEffect(() => {
        tableStorage.current.data = data;
        createTable(tableStorage.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Runs filter on the table when the props.filterValue changes
    useEffect(() => {
        if (filterValue === undefined) return;
        tableStorage.current.filterValue = filterValue;
        filterTable(tableStorage.current);

    }, [filterValue]);

    return (
        <div id={`main-wrapper-${tableConfig.id}`} className={classes.stWrapper}>
            {tableConfig.stickyHeader &&
            <div id={`sticky-header-${tableConfig.id}`} className={classes.stStickyHeaderWrapper}/>}
            <div id={`simplexion-table-${tableConfig.id}`} className={classes.stTableWrapper}/>
        </div>
    );
};
