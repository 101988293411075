import React from "react";
import {useRoutes} from "hookrouter";
import Details from "./Details";
import Monitor from "./Monitor";
import SelfCheck from "./SelfCheck";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/:id/details": ({id}) => <Details id={id}/>,
  "/:id/monitor": ({id}) => <Monitor id={id}/>,
  "/:id/self-check": ({id}) => <SelfCheck competitionId={id}/>,
  "/:id/self-check/:sn": ({id, sn}) => <SelfCheck competitionId={id} startNumber={sn}/>
};
