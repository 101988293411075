import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "../../../../Lib/material-dashboard/components/CustomButtons/Button";
import {A} from 'hookrouter';


export function Navigation(props) {

  const {competitionId, sectionId} = props;

  const prefix = "/admin/competitions/" + competitionId + "/sections/" + sectionId;

  const {t} = useTranslation('competition');

  return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <A href={prefix}>
            <Button color={"primary"} size={"sm"}>{t('base-data')}</Button>
          </A>&nbsp;
          <A href={prefix + "/routes"}>
            <Button color={"primary"} size={"sm"}>{t('routes')}</Button>
          </A>&nbsp;
          <A href={prefix + "/participations"}>
            <Button color={"primary"} size={"sm"}>{t('participants')}</Button>
          </A>&nbsp;
          <A href={prefix + "/results"}>
            <Button color={"primary"} size={"sm"}>{t('results')}</Button>
          </A>&nbsp;
        </Grid>
      </Grid>
  );
}