export default {
  "championships" : "Bajnokságok",
  "championship" : "Bajnokság",
  "results" : "Eredmények",
  "climbingType" : "Versenyszám",
  "year" : "Év",
  "championship-standings" : "Bajnokság állása",
  "championship-results" : "Bajnokság eredménye",

  "base-data" : "Alapadatok",
  "sections" : "Szekciók",
  "section" : "Szekció",
  "participations" : "Résztvevők",
  "climbs" : "Mászások",
  "routes" : "Utak",
  "partcipants": "Résztvevők",

  "position" : "Helyezés",
  "total" : "összesített",
  "points" : "Pont",

  "save" : "Mentés",
  "back" : "Vissza",

  "name" : "Név",
  "mnemonic": "Rövidítés",
  "status" : "Státusz",
  "rank" : "Rang",
  "ageGroups" : "Korcsoportok",

  "PREPARATION" : "előkészítés",
  "ACTIVE": "aktív",
  "CLOSED": "lezárt",

  "QUALIFIER" : "selejtező",
  "QUATERFINAL" : "negyeddöntő",
  "SEMIFINAL" : "elődöntő",
  "FINAL" : "döntő",
  "SUPERFINAL" : "szuperdöntő",

  "BOULDER" : "boulder",
  "LEAD" : "nehézségi",
  "SPEED" : "gyorsasági",

  "MINI" : "mini",
  "CHILD" : "gyerek",
  "TEEN_AGE" : "serdülő",
  "YOUTH_A" : "ifjúsági A",
  "YOUTH_B" : "ifjúsági B",
  "JUNIOR" : "junior",
  "ADULT" : "nyílt",
  "SENIOR" : "senior",
  "open" : "Nyílt",

  "all" : "Összes",
  "FEMALE" : "Nő",
  "MALE" : "Férfi",
  "GIRL" : "Lány",
  "BOY" : "Fiú",

  "ageGroup" : "Korcsoport",
  "startNumber" : "Rajtszám",
  "medicalExpiration" : "Sportorvosi",
  "medicalValid" : "Érvényes",
  "registered" : "Jelen",

};