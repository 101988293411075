const activeLabels = ["useFetchOne", "useFetchMany", "useForm", "useFormField", "submit"];

const activeTypes = {
  "START" : false,
  "END" : false,
  "STEP" : false,
  "ABORT" : false,
  "SUCCESS" : false,
  "ERROR" : false,
  "EFFECT" : false,
  "RENDER" : false,
  "VAL..ON" : false,
  "DATA" : false,
  "MEMO" : false
};

export default (type, label, lazyMessage) => {
  if (activeLabels.length === 0) return;
  if (activeLabels.indexOf(label) === -1) return;
  if (!activeTypes[type]) return;

  console.debug("[" + type.padEnd(7, " ") + "]  " + label, ...lazyMessage())
};