import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "../../../Lib/material-dashboard/components/CustomButtons/Button";
import {A} from 'hookrouter';

export function Navigation(props) {

  const {parentId} = props;

  const {t} = useTranslation('event');

  return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <A href={"/admin/events/" + parentId + "/edit"}>
            <Button color={"primary"} size={"sm"}>{t('base-data')}</Button>
          </A>&nbsp;
          <A href={"/admin/events/" + parentId + "/groups"}>
            <Button color={"primary"} size={"sm"}>{t('groups')}</Button>
          </A>&nbsp;
          <A href={"/admin/events/" + parentId + "/registrations"}>
            <Button color={"primary"} size={"sm"}>{t('registrations')}</Button>
          </A>
        </Grid>
      </Grid>
  );
}