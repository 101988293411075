import React from "react";
import {useTranslation} from "react-i18next";
import {useEvents} from "../../Api/events";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {baseTableStyles} from "../../Lib/simplexion-table/baseStyle";
import {makeStyles} from "@material-ui/styles";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {useContents} from "../../Api/contents";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import Moment from "react-moment";

const useStyles = makeStyles(baseTableStyles);

export default (props) => {

  const {path} = props;

  const {t} = useTranslation('info');
  const classes = useStyles();

  const {data: contents} = useContents({kind: "info"});

  return (
      <ContentBox path={path}>
        <div style={{paddingBottom: 16}}>
          {!contents ? null :
              <table>
                <tr>
                  {contents.map(c =>
                      <td>
                        <a href={"/api/1.0.0/attachments/" + c.attachment}>
                          <Button color="success">{c.name}</Button>
                        </a>
                      </td>
                  )}
                </tr>
              </table>
          }
        </div>
      </ContentBox>
  );
};
