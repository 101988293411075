import React from "react";

import styles from "../../../material-dashboard/assets/jss/customCheckboxRadioSwitch.jsx";
import {makeStyles} from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Check} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import {useFormField} from "../../useFormField";

const useStyles = makeStyles(styles);

//
// This hacking with the values are here because the checkbox components
// waits a boolean as checked but the use cases are more complex:
//
// 1. single checkbox resulting a value of true / false
// 2. multiple checkboxes resulting an array of string values
//
// These getters/setters should cover all cases.
//
const getValue = (formValue, fieldValue, multi) => {
  if (multi) {
    if (!(formValue instanceof Array)) {
      if (!multi) throw Error("non-multi checkbox with array form value");
    }
    return formValue.indexOf(fieldValue) !== -1;
  }

  return formValue;
};

const setValue = (formValue, fieldValue, multi, checked) => {
  if (multi) {
    if (!(formValue instanceof Array)) {
      if (!multi) throw Error("non-multi checkbox with array form value");
    }

    formValue = formValue.slice(0); // to have an independent array
    if (checked) {
      if (formValue.indexOf(fieldValue) === -1) formValue.push(fieldValue);
    } else {
      formValue = formValue.filter(v => v !== fieldValue);
    }

    return formValue;
  }

  return checked
};

function ValidatedCheckbox(props) {
  const {name, value, multi, label} = props;

  const classes = useStyles();

  const ff = useFormField(props);

  const labelText = ff.t(label ? label : value);

  return (
      <FormControlLabel
          control={
            <Checkbox
                checked={getValue(ff.value, value, multi)}
                onChange={(e) => ff.onChange(setValue(ff.values.current[name], value, multi, e.target.checked))}
                value={value}
                name={name}
                aria-label={labelText}
                disabled={ff.disabled}
                checkedIcon={<Check className={classes.checkedIcon}/>}
                icon={<Check className={classes.uncheckedIcon}/>}
                classes={{checked: classes.checked}}
            />
          }
          classes={{
            label: classes.label,
          }}
          label={labelText}
      />
  );
}

export default ValidatedCheckbox;