import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addCompetition, removeCompetition, updateCompetition, useCompetition} from "../../../Api/competitions";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import Form from "./Form";
import {CompetitionRecord, CompetitionSchema} from "../../../Data/Competition.js";
import Sections from "./Sections/Sections";
import Participations from "./Participations/Participations";
import Climbs from "./Climbs/Climbs";
import Results from "./Results/Results";
import Actions from "./Actions/Actions";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Competitions view="list"/>,
  "/add": () => <Competitions view="add"/>,
  "/:id/edit": ({id}) => <Competitions view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Competitions view="remove" id={id}/>,
  "/:id/sections*": ({id}) => <Competitions view="sections" id={id}/>,
  "/:id/participations*": ({id}) => <Competitions view="participations" id={id}/>,
  "/:id/climbs*": ({id}) => <Competitions view="climbs" id={id}/>,
  "/:id/results*": ({id}) => <Competitions view="results" id={id}/>,
  "/:id/actions": ({id}) => <Competitions view="actions" id={id}/>
};

const Competitions = ({view, id}) => {

  debug("RENDER", "Competitions", () => [view]);

  const {t} = useTranslation('competition');

  const {loadingStatus, data} = useCompetition(id);

  const viewData = (loadingStatus ? new CompetitionRecord(CompetitionSchema.default()) : data);

  const path = [{label: t('competitions'), url: "/admin/competitions"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('name');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/admin/competitions/add"});
      return <Form id={id} record={viewData} path={path} view={view} api={addCompetition}/>;

    case "remove" :

      path.push({label: label, url: "/admin/competitions/" + id + "/remove"});
      return <Form id={id} record={viewData} path={path} view={view} api={removeCompetition}/>;

    case "edit" :

      path.push({label: label, url: "/admin/competitions/" + id + "/edit"});
      return <Form id={id} record={viewData} path={path} view={view} api={updateCompetition}/>;

    case "sections" :

      path.push({label: label, url: "/admin/competitions/" + id + "/edit"});
      path.push({label: t('sections'), url: "/admin/competitions/" + id + "/sections"});
      return <Sections competitionId={id} competitionData={viewData} path={path}/>;

    case "participations" :

      path.push({label: label, url: "/admin/competitions/" + id + "/edit"});
      path.push({label: t('participations'), url: "/admin/competitions/" + id + "/participations"});
      return <Participations competitionId={id} competitionData={viewData} path={path}/>;

    case "climbs" :

      path.push({label: label, url: "/admin/competitions/" + id + "/edit"});
      path.push({label: t('climbs'), url: "/admin/competitions/" + id + "/climbs"});
      return <Climbs competitionId={id} competitionData={viewData} path={path}/>;

    case "results" :

      path.push({label: label, url: "/admin/competitions/" + id + "/edit"});
      path.push({label: t('results'), url: "/admin/competitions/" + id + "/results"});
      return <Results competitionId={id} competitionData={viewData} path={path}/>;

    case "actions" :

      path.push({label: label, url: "/admin/competitions/" + id + "/edit"});
      path.push({label: t('actions'), url: "/admin/competitions/" + id + "/actions"});
      return <Actions competitionId={id} competitionData={viewData} path={path}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};

