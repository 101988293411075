import React from "react";
import {useTranslation} from "react-i18next";
import {useEvents} from "../../../Api/events";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {baseTableStyles} from "../../../Lib/simplexion-table/baseStyle";
import {makeStyles} from "@material-ui/styles";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import {useContents} from "../../../Api/contents";
import Button from "../../../Lib/material-kit/components/CustomButtons/Button";
import Moment from "react-moment";

const useStyles = makeStyles(baseTableStyles);

export default (props) => {

  const {path} = props;

  const {t} = useTranslation('event');
  const classes = useStyles();

  const {data} = useEvents({format: "calendar"});
  const {data: contents} = useContents({kind: "calendar"});

  const tableConfig = {
    id: 'basic-table',
    nameSpace: 'event',
    styles: classes,
    columns: [
      {
        accessor: 'startDate',
        header: t('date')
      },
      {
        accessor: 'name',
        renderer: (record) => (
            record.status === 'ACTIVE'
                ? '<a href="/events/' + record.id + '"/>' + record.name + '</a>'
                : record.name
        )
      },
      {
        accessor: 'type',
        renderer: (record) => t(record.type)
      },
      {
        accessor: 'category',
        renderer: (record) => t(record.category)
      }
    ],
  };

  return (
      <ContentBox path={path}>
        <div style={{paddingBottom: 16}}>
          {!contents ? null :
              <table>
                <tr>
                  {contents.map(c =>
                      <td>
                        <a href={"/api/1.0.0/attachments/" + c.attachment}>
                          <Button color="success">{c.name}</Button>
                        </a>
                      </td>
                  )}
                </tr>
                <tr>
                  {contents.map(c =>
                      <td style={{color: "#396593", whiteSpace: "nowrap", textAlign: "center"}}>
                        <Moment format="YYYY-MM-DD">{c.modifiedAt}</Moment>
                      </td>
                  )}
                </tr>
              </table>
          }
        </div>
        <SimplexionTable tableConfig={tableConfig} data={data}/>
      </ContentBox>
  );
};
