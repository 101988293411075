/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import paginationStyle from "../../assets/jss/components/paginationStyle.jsx";
import {useTranslation} from "react-i18next";

function Pagination({...props}) {
    const {classes, pages, color} = props;
    const {t} = useTranslation();

    return (
        <ul className={classes.pagination}>
            {pages.map((prop, key) => {
                const paginationLink = cx({
                    [classes.paginationLink]: true,
                    [classes[color]]: prop.active,
                    [classes.disabled]: prop.disabled
                });
                return (
                    <li className={classes.paginationItem} key={key}>
                        {prop.onClick !== undefined ? (
                            <Button onClick={prop.onClick} className={paginationLink} href='#'>
                                {t(prop.text)}
                            </Button>
                        ) : (
                            <Button disabled={props.text === "..."}
                                    href='#'
                                    className={paginationLink}
                            >
                                {t(prop.text)}
                            </Button>
                        )}
                    </li>
                );
            })}
        </ul>
    );
}

Pagination.defaultProps = {
    color: "primary"
};

Pagination.propTypes = {
    classes: PropTypes.object.isRequired,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            active: PropTypes.bool,
            disabled: PropTypes.bool,
            text: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.oneOf(["PREV", "NEXT", "...", "FIRST", "LAST"])
            ]).isRequired,
            onClick: PropTypes.func
        })
    ).isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(paginationStyle)(Pagination);
