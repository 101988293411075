import React from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import {useTranslation} from "react-i18next";

export default () => {

  const t = useTranslation("account").t;

  return (
    <ContentBox title={t("add-success")}>
      <Section title={t("add-success")}>
        <br/>
        {t('add-success-explanation')}
      </Section>
    </ContentBox>
  );
};