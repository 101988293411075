import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {DocumentRecord, DocumentSchema} from "../Data/document";

export function useDocuments(filter) {
  return useFetchMany('/documents', undefined, DocumentRecord);
}

export function useDocument(id) {
    return useFetchOne('/documents', id, DocumentRecord, DocumentSchema);
}

export function addDocument(document, onSuccess, onError) {
    baseFetch('POST', '/documents', document,
        data => {
            if (onSuccess) onSuccess(new DocumentRecord(data))
        },
        onError
    );
}

export function getDocuments(filter, onSuccess, onError) {
    baseFetch('GET', '/documents', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new DocumentRecord(r)))
        },
        onError
    );
}

export function getDocument(id, onSuccess, onError) {
    baseFetch('GET', '/documents/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new DocumentRecord(data))
        },
        onError
    );
}