import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../../Lib/Table/Table";
import {updateParticipation, useParticipations} from "../../../../../Api/competitions";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import DefaultActions from "../../../../../Lib/Table/DefaultActions";
import Checkbox from "@material-ui/core/Checkbox";

const EditableCell = (props) => {

  const {
    cell: {value: initialValue},
    row: {original: record},
    column: {id}
  } = props;

  const [value, setValue] = React.useState(initialValue);

  const onChange = e => {
    // This works for checkbox because it is a single boolean, it does not work for
    // text fields! Check https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/editable-data

    // ------ MOVE THIS TO onBlur FOR TEXT FIELDS -----

    const newValue = e.target.checked;
    updateParticipation(record.set(id, newValue), () => setValue(newValue))
  };

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  return <Checkbox
      checked={value}
      onChange={onChange}
      value={value}
  />
};

const List = memo(({competitionId, path}) => {

  const {t} = useTranslation('competition');

  const {data} = useParticipations(competitionId);

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'startNumber',
      Header: t('startNumber')
    },
    {
      accessor: 'ageGroup',
      Header: t('ageGroup')
    },
    {
      accessor: 'medicalExpiration',
      Header: t('medicalExpiration')
    },
    {
      accessor: 'medicalValid',
      Header: t('medicalValid'),
      Cell: EditableCell
    },
    {
      accessor: 'registered',
      Header: t('registered')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={"/admin/competitions/" + competitionId + "/participations/"} id={row.values.id} hideDelete/>
    }
  ];

  return (
      <ContentBox path={path} add={"/admin/competitions/" + competitionId + "/participations/add"}>
        <Table columns={columns} data={data}/>
      </ContentBox>
  );
});

export default List;