export const baseTableStyles = {
    stWrapper: {
        // marginTop: '2rem',
        perspective: 0,
        '&.sticky': {
            perspective: 0,
        }
    },
    stTableWrapper: {
        '&.sticky': {
            overflowX: 'unset',
            overflowY: 'auto',
            height: '85vh',
        },
    },
    stStickyHeaderWrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
    },
    stSticky: {
        borderBottomLeftRadius: 'unset',
        borderBottomRightRadius: 'unset',
    },
    stTable: {
        width: '100%',
        textAlign: 'left',
        color: '#2e2e2e',
        backgroundColor: '#fff',
        borderCollapse: 'separate',
        borderSpacing: 0,
    },
    stCaption: {
        textAlign: 'center',
        padding: '1.25rem',
        backgroundColor: '#fff',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#2e2e2e',
    },
    stHeader: {
        fontWeight: 'medium',
    },
    stBody: {},
    stHeaderRow: {
        height: '3.5rem',
        borderRadius: '.25rem',
    },
    stRow: {
        height: '3.25rem',
        '&:hover': {
            backgroundColor: '#f5f5f5'
        }
    },
    stHeaderCell: {
        padding: '0 1rem',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        display: 'relative',
        borderTop: '1px solid #ecedef',
        borderBottom: '1px solid #ecedef',
        '&.stSortable': {
            cursor: 'pointer',
            // '&::after': {
            //     content: '""',
            //     position: 'absolute',
            //     right: -2,
            //     border: '8px solid transparent',
            // },
            // '&.stSortAscending': {
            //     '&::after': {
            //         top: 0,
            //         borderBottomColor: 'silver',
            //     },
            // },
            // '&.stSortDescending': {
            //     '&::after': {
            //         bottom: 0,
            //         borderTopColor: 'silver',
            //     }
            // },
        }
    },
    stCell: {
        padding: '0 1rem',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderTop: '1px solid #ecedef',
        borderBottom: '1px solid #ecedef',
    },
    customCheckBox: {
        boxSizing: 'unset'
    },
    customCheckBoxLabel: {
        display: 'inline-flex',
        cursor: 'pointer',
        position: 'relative',
        '& > span': {
            color: '#34495e',
            padding: '.5rem .25rem',
        }
    },
};