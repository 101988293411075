import {number, object, string} from "yup";
import {Record} from "immutable";


export const TemplateRecord = Record({
  id: 0,
  name: '',
  language: '',
  title: '',
  content: ''
});


export const TemplateSchema = object().shape({
  id: number().min(0).default(0),
  name: string().required().min(5).max(100).default(''),
  language: string().required().min(2).max(2).default(''),
  title: string().required().min(5).max(100).default(''),
  content: string().required().max(5000000).default('')
});

