import {Record} from 'immutable';
import {date, mixed, number, object} from "yup";
import * as moment from "moment";

export const DocumentRecord = Record({
    id: 0,
    type: '',
    event: 0,
    fromDate: null,
    toDate: null,
    attachment: 0
});

export const DocumentSchema = object().shape({
    id: number().required().min(0).default(0),
    type: mixed().required().oneOf(['EXCULPATION', 'LEAVE_LETTER', 'RESULT_CERTIFICATE']).default('EXCULPATION'),
    event: number().min(1).default(0),
    fromDate: date(),
    toDate: date()
});
