import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../Lib/Table/Table";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useTemplates} from "../../Api/templates";
import DefaultActions from "../../Lib/Table/DefaultActions";

const urlNs = "/admin/templates/";

const List = memo((props) => {

  const {path} = props;

  const {t} = useTranslation("template");

  const { data } = useTemplates();

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier')
    },
    {
      accessor: 'name',
      Header: t('name')
    },
    {
      accessor: 'language',
      Header: t('language')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={urlNs} id={row.values.id} />
    }
  ];

  return (
    <ContentBox path={path} add={urlNs + "add"}>
      <Table columns={columns} data={data} />
    </ContentBox>
  );
});

export default List;