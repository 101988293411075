import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {ChampionshipRecord, ChampionshipSchema} from "../../../Data/championship";

export default memo(({id, record, path, view, api}) => {

  const {t} = useTranslation('championships');

  const form = useForm({
    recordType: ChampionshipRecord,
    recordSchema: ChampionshipSchema,
    namespace: 'championships',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        <Section title={t('championship')}>
          <ValidatedInput name="name"/>
          <ValidatedRadioGroup name="status">
            <ValidatedRadio name="status" value="ACTIVE"/>
            <ValidatedRadio name="status" value="CLOSED"/>
          </ValidatedRadioGroup>
          <ValidatedRadioGroup name="climbingType">
            <ValidatedRadio name="climbingType" value="BOULDER"/>
            <ValidatedRadio name="climbingType" value="LEAD"/>
            <ValidatedRadio name="climbingType" value="SPEED"/>
          </ValidatedRadioGroup>
          <ValidatedInput name="year"/>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
})