import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../Lib/Table/Table";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import DefaultActions from "../../../Lib/Table/DefaultActions";
import {useChampionships} from "../../../Api/championships";

const urlNs = "/admin/championships/";

const List = memo((props) => {

  const {path} = props;

  const {t} = useTranslation('championships');

  const { data } = useChampionships();

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'status',
      Header: t('general:status')
    },
    {
      accessor: 'year',
      Header: t('year')
    },
    {
      accessor: 'climbingType',
      Header: t('climbingType')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <DefaultActions urlNs={urlNs} id={row.values.id} hideDelete/>
    }
  ];

  return (
      <ContentBox path={path} add={urlNs + "add"}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default List;