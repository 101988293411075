import React, {useState} from "react";
// @material-ui/core components
// @material-ui/icons
// core components
import Button from "../../../Lib/material-kit/components/CustomButtons/Button.jsx";
import Card from "../../../Lib/material-kit/components/Card/Card.jsx";
import CardBody from "../../../Lib/material-kit/components/Card/CardBody.jsx";
import {makeStyles} from "@material-ui/styles";
import {grayColor, successColor} from "../../../Lib/material-kit/assets/jss/material-kit-pro-react";
import {A} from "hookrouter";
import {useTranslation} from "react-i18next";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles({
      card: {
        margin: "8px 0px 16px 0px",
        paddingBottom: "8px",
        maxWidth: "600px"
      },
      title: {
        marginTop: 10,
        color: successColor[0],
        fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500
      },
      notes: {
        marginTop: "4px",
        color: grayColor[1],
        fontSize: "0.75rem"
      },
        image : {
          maxWidth: 235,
          maxHeight: 235
        }
    }
);

function Link({label, url}) {
  const {t} = useTranslation();
  if (url.startsWith("http"))
    return <a href={url}><Button color="success" style={{marginRight: 10}}>{t(label)}</Button></a>;
  else {
    return <A href={url}><Button color="success" style={{marginRight: 10}}>{t(label)}</Button></A>;
  }
}

export default (props) => {
  const {record} = props;

  const {t} = useTranslation("contents");
  const classes = useStyles();

  const [showDetails, setShowDetails] = useState(false);

  let links = {};
  try {
    if (record.links) links = JSON.parse(record.links);
  } catch (e) {
    // intentional
  }

  return (
      <Card className={classes.card}>
        <CardBody>
          <Hidden mdUp>
          <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "column"}}>
            <div>
              <img
                  src={"/api/1.0.0/attachments/" + record.attachment}
                  alt={record.name}
                  className={classes.image}
              />
            </div>
            <div style={{flexGrow: 1, marginLeft: 16}}>
              <h4 className={classes.cardTitle}>{record.title}</h4>
              <div className="content" dangerouslySetInnerHTML={{__html: record.summary}}/>
            </div>
          </div>
          </Hidden>
          <Hidden smDown>
            <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "row"}}>
              <div>
                <img
                    src={"/api/1.0.0/attachments/" + record.attachment}
                    alt={record.name}
                    className={classes.image}
                />
              </div>
              <div style={{flexGrow: 1, marginLeft: 16}}>
                <h4 className={classes.cardTitle}>{record.title}</h4>
                <div className="content" dangerouslySetInnerHTML={{__html: record.summary}}/>
              </div>
            </div>
          </Hidden>
          <div style={{paddingTop: 16}}>
            { ! record.details || record.details === "<p><br></p>" ? null :
                <Button color="success" style={{marginRight: 10}} onClick={() => setShowDetails(!showDetails)}>{t('details')}</Button>
            }
            {Object.keys(links).map(k => <Link key={k} label={k} url={links[k]}/>)}
          </div>
          { ! showDetails ? null :
              <div style={{paddingTop: 16}} dangerouslySetInnerHTML={{__html: record.details}}/>
          }
        </CardBody>
      </Card>
  );
}