import React, {useEffect, useState} from "react";
import {useMonitor} from "../../../Api/competitions";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import Card from "../../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../../Lib/material-dashboard/components/Card/CardBody";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import {grayColor, successColor} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";
import {useQueryParams} from "hookrouter";

const rankNames = [
  "QUALIFIER",
  "QUATERFINAL",
  "SEMIFINAL",
  "FINAL",
  "SUPERFINAL"
];

const useStyles = makeStyles((theme) => ({
      card: {
        margin: "8px 0",
        paddingBottom: "8px"
      },
      title: {
        marginBottom: 10,
        color: successColor[4],
        // fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500
      },
      button: {
        padding: "8px 16px",
        backgroundColor: successColor[0]
      },
      name: {
        [theme.breakpoints.up("md")]: {
          minWidth: "16em"
        }
      },
      position: {
        fontSize: 16,
        fontWeight: 500,
        width: "3em",
        textAlign: "center",
        color: "blue"
      },
      startNumber: {
        fontSize: 12,
        width: "4em",
        textAlign: "left",
        color: "teal"
      },
      partition: {
        border: 0,
        borderCollapse: "collapse",
        cellSpacing: 0,
        cellPadding: 0,
        "& th": {
          padding: "4px 8px 4px 8px",
          textAlign: "center",
          textTransform: "lowercase",
          backgroundColor: successColor[5],
          color: grayColor[2],
          fontSize: "0.75rem",
          fontWeight: 400
        },
        "& tr": {
          // "&:nth-child(odd)": {
          //   backgroundColor: grayColor[13]
          // }
          borderBottom: "1px solid " + grayColor[10]
        },
        "& td": {
          padding: "4px 8px 4px 8px",
        }
      },
      right: {
        textAlign: "right"
      }
    })
);

export default ({id}) => {
  debug("RENDER", "Competitions.Monitor", () => [id]);

  const {t} = useTranslation('competition');
  const classes = useStyles();

  const [queryParams] = useQueryParams();

  const [revision, setRevision] = useState(0);
  const {loadingStatus, data} = useMonitor(id, revision);

  useEffect(() => {
    const ticket = setTimeout(() => setRevision(revision + 1), 90000);
    return () => clearTimeout(ticket);
  }, [revision, setRevision]);

  const ageGroups = {};

  if (data && data.partitions) data.partitions.forEach(p => ageGroups[p.ageGroup] = true);
  const ageGroupFilter = ageGroups["ADULT"] ? "ADULT" : Object.keys(ageGroups).join(",");

  if (loadingStatus) return t('refresh');

  const show = (p, gender) => {
    if (p.gender !== gender) return null;
    if (ageGroupFilter.indexOf(p.ageGroup) === -1) return null;
    if (p.results.length === 0) return null;
    if (queryParams.tags === undefined || queryParams.tags.indexOf('amateur') === -1) {
      return p.tags.indexOf("amateur") === -1;
    } else {
      return p.tags.indexOf("amateur") !== -1;
    }
  };

  return (
      <Grid container spacing={1} style={{paddingLeft: 8, paddingRight: 8, backgroundColor: "gray"}}>
        <Grid item xs={12} md={6}>
          <Grid container>
            {data.partitions.map(p => {
              if (!show(p, "FEMALE")) return null;
              return <Partition key={p.gender + p.ageGroup + p.tags} data={data} partition={p} t={t} classes={classes} revision={revision}/>
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            {data.partitions.map(p => {
              if (!show(p, "MALE")) return null;
              return <Partition key={p.gender + p.ageGroup + p.tags} data={data} partition={p} t={t} classes={classes} revision={revision}/>
            })}
          </Grid>
        </Grid>
      </Grid>
  )
};

const Partition = ({data, partition, t, classes, revision}) => {

  let results;
  if (partition.climbingType === 'BOULDER') {
    results = partition.results.filter(r => r.sectionResults[0].tops !== 0 || r.sectionResults[0].zones !== 0);
  } else {
    results = partition.results;
  }

  const [queryParams] = useQueryParams();
  const windowSize = queryParams.ws || 10000;
  const pageCount = Math.floor((results.length + 1) / windowSize) + 1;
  const actualPage = revision % pageCount;

  results = results.slice(actualPage * windowSize, (actualPage + 1) * windowSize);

  let genderLabel;
  switch (partition.ageGroup) {
    case "JUNIOR":
    case "ADULT":
    case "SENIOR":
      genderLabel = t(partition.gender);
      break;
    default :
      genderLabel = t(partition.gender === 'FEMALE' ? 'GIRL' : 'BOY')
  }

  // style={{minHeight: "100vh"}}

  return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardBody>
            <Box className={classes.title}>{t(partition.ageGroup)} {genderLabel} {partition.tags === "" ? "" : " - " + t(partition.tags)}</Box>
            <table className={classes.partition}>
              <thead>
              <tr>
                <th rowSpan={2}>#</th>
                <th rowSpan={2}/>
                <th rowSpan={2}/>
                {partition.sections.map(sid =>
                    partition.climbingType === 'BOULDER'
                        ? <BoulderHeader key={sid} data={data} sid={sid} top t={t}/>
                        : <LeadHeader key={sid} data={data} sid={sid} top t={t}/>
                )}
              </tr>
              <tr>
                {partition.sections.map(sid =>
                    partition.climbingType === 'BOULDER'
                        ? <BoulderHeader key={sid} data={data} sid={sid} t={t}/>
                        : <LeadHeader key={sid} data={data} sid={sid} t={t}/>
                )}
              </tr>
              </thead>
              <tbody>
              {results.map(r => {
                return (
                    <tr key={r.startNumber}>
                      <td className={classes.position}>{r.position}</td>
                      <td className={classes.name}>{r.name}</td>
                      <td className={classes.startNumber}>{r.startNumber}</td>
                      {partition.climbingType === 'BOULDER'
                          ? r.sectionResults.map(sr => <BoulderResults key={sr.section} data={data} sr={sr}/>)
                          : r.sectionResults.map(sr => <LeadResults key={sr.section} data={data} sr={sr} classes={classes}/>)
                      }
                    </tr>
                );
              })}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Grid>);
};


const BoulderHeader = ({data, sid, top, t}) => {
  if (top) return <th key={sid} colSpan={4}>{t(rankNames[data.sections[sid].rank])}</th>;

  return <React.Fragment>
    <th>{t('tops')}</th>
    <th>{t('zones')}</th>
    <th>{t('topTries')}</th>
    <th>{t('zoneTries')}</th>
  </React.Fragment>
};

const LeadHeader = ({data, sid, top, t}) => {
  if (top) return <th key={sid} colSpan={data.sections[sid].routes.length + 1}>{t(rankNames[data.sections[sid].rank])}</th>;

  return <React.Fragment>
    <th>{t('points')}</th>
    {data.sections[sid].routes.map(r => <th key={r.position}>{r.label}</th>)}
  </React.Fragment>
};

const BoulderResults = ({sr}) => {
  return <React.Fragment>
    <td>{sr.tops}</td>
    <td>{sr.zones}</td>
    <td>{sr.topTries}</td>
    <td>{sr.zoneTries}</td>
  </React.Fragment>
};

const LeadResults = ({sr, classes}) => {
  return <React.Fragment>
    <td className={classes.right}>{sr.points.toFixed(2)}</td>
    {sr.routeResults.map(rr => <td>{rr.bestHoldNumber / 10}</td>)}
  </React.Fragment>
};

