import {Record} from 'immutable';
import {number, object, mixed, string} from "yup";

export const ChampionshipRecord = Record({
  id: 0,
  name: '',
  status: 'ACTIVE',
  year: 2019,
  climbingType: 'BOULDER',
  competitions: []
});

export const ChampionshipSchema = object().shape({
  id: number().min(0).default(0),
  name: string().min(4).default(''),
  status: mixed().oneOf(['ACTIVE', 'CLOSED']),
  climbingType: mixed().oneOf(['BOULDER', 'LEAD', 'SPEED']),
  year: number().required().min(2018)
});

export const ChampionshipResultRecord = Record({
  id: 0,
  name: '',
  status: '',
  year: 0,
  climbingType: '',
  competitions: [],
  results: []
});

export const ChampionshipResultSchema = object().shape({
  id: number().min(0).default(0),
  name: string().min(4).default(''),
  status: mixed().oneOf(['ACTIVE', 'CLOSED']),
  climbingType: mixed().oneOf(['BOULDER', 'LEAD', 'SPEED']),
  year: number().required().min(2018)
});