import React, {memo} from "react";
import {A} from "hookrouter";
import Button from "../material-dashboard/components/CustomButtons/Button";
import {Close, Edit} from "@material-ui/icons";

const DefaultActions = memo(({urlNs, id, hideDelete}) => {
  return (
      <div>
        <A href={urlNs + id + "/edit"}>
          <Button justIcon round color={"success"} size={"sm"}><Edit/></Button>
        </A>&nbsp;
        {hideDelete ? null :
            <A href={urlNs + id + "/remove"}>
              <Button justIcon round color={"danger"} size={"sm"}><Close/></Button>
            </A>
        }
      </div>
  );
});

export default DefaultActions;