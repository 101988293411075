import React from "react";
// @material-ui/core components
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// core components
import Button from "../../../Lib/material-kit/components/CustomButtons/Button.jsx";
import Card from "../../../Lib/material-kit/components/Card/Card.jsx";
import CardBody from "../../../Lib/material-kit/components/Card/CardBody.jsx";
import {useEventsShort} from "../../../Api/events";
import EventShort from "./EventShort";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";
import {grayColor, successColor} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";
import Box from "@material-ui/core/Box";
import {A} from "hookrouter";

const useStyles = makeStyles({
      card: {
        margin: "8px 0",
        paddingBottom: "8px"
      },
      title: {
        marginTop: 10,
        color: successColor[4],
        fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500
      },
      notes: {
        marginTop: "4px",
        color: grayColor[1],
        fontSize: "0.75rem"
      }
    }
);

function EventsOverview() {

  const t = useTranslation("event").t;

  const classes = useStyles();

  const {loadingStatus, data} = useEventsShort();

  if (loadingStatus) return null;

  // noinspection HtmlUnknownTarget
  return (
      <Card className={classes.card}>
        <CardBody>
          <Box className={classes.title}>{t('events')}</Box>
          <table style={{width: "100%", paddingTop: 10, marginTop: 10}}>
            <tbody>
            {
              data.map(
                  item => <EventShort key={item.get('id')} record={item} classes={classes}/>
              )
            }
            </tbody>
          </table>
          <A href="/events">
            <Button color="success" style={{marginTop: 10}}>
              <Icon>content_copy</Icon> versenynaptár
            </Button>
          </A>
        </CardBody>
      </Card>
  );
}

export default EventsOverview;
