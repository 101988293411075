import debug from "./debug";
import {useTranslation} from "react-i18next";
import React from "react";

export default (props) => {
  const {
    config: {
      compName,
      namespace,
      Record,
      Schema,
      api,
      url,
      labelField,
      ListComponent,
      FormComponent
    },
    view,
    id
  } = props;

  debug("RENDER", compName, () => [view]);

  const {t} = useTranslation(namespace);

  const {loadingStatus, data} = api.use(id);

  const viewData = (loadingStatus ? new Record(Schema.default()) : data);

  const path = [{label: t(namespace), url}];

  // noinspection JSCheckFunctionSignatures
  const name = viewData.get(labelField);

  switch (view) {
    case "list" :

      return <ListComponent path={path}/>;

    case "add" :

      path.push({label: name, url: url + "add"});
      return <FormComponent id={id} record={viewData} path={path} view={view} api={api.add}/>;

    case "remove" :

      path.push({label: name, url: url + "/" + id + "/remove"});
      return <FormComponent id={id} record={viewData} path={path} view={view} api={api.remove}/>;

    case "edit" :

      path.push({label: name, url: url + "/" + id + "/edit"});
      return <FormComponent id={id} record={viewData} path={path} view={view} api={api.edit}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};