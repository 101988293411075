import React from "react";

import {makeStyles} from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import customInputStyle from "../../../material-dashboard/assets/jss/components/customInputStyle.jsx";
import classNames from "classnames";
import {useFormField} from "../../useFormField";
import {RadioGroup} from "@material-ui/core";

const useStyles = makeStyles(customInputStyle);

export const RadioGroupContext = React.createContext("");

function ValidatedRadioGroup(props) {
  const {name, children} = props;

  const classes = useStyles();

  const ff = useFormField(props);

  const labelClasses = classNames(classes.labelRoot, {
    [classes.labelRootError]: ff.status === "invalid",
    [classes.labelRootSuccess]: ff.status === "valid"
  });

  const formControlClasses = classNames([
    classes.formControl
  ]);

  let helpTextClasses = classNames({
    [classes.labelRootError]: ff.status === "invalid",
    [classes.labelRootSuccess]: ff.status === "valid"
  });

  const style = ff.helperText
      ? {height: "76px", maxHeight: "76px"}
      : {height: "56px", maxHeight: "56px"};

  return (
      <FormControl className={formControlClasses} fullWidth style={style}>

        <InputLabel className={labelClasses} shrink={true}>{ff.labelText}</InputLabel>

        <RadioGroupContext.Provider value={ff.value}>
          <RadioGroup
            aria-label={ff.labelText}
            name={name}
            value={ff.value}
            onChange={(e) => ff.onChange(e.target.value)}
            row
              //style={{display: "flex", alignItems: "center", position: "relative", top: "4px", left: "10px"}}
            >
            {children}
          </RadioGroup>
        </RadioGroupContext.Provider>

        {ff.helperText ?
            <FormHelperText className={helpTextClasses}>
              {ff.helperText}
            </FormHelperText>
            : null}
      </FormControl>
  );
}

export default ValidatedRadioGroup;