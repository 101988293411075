import {container, grayColor} from "../../Lib/material-kit/assets/jss/material-kit-pro-react.jsx";
import {primaryColor} from "../../Lib/material-kit/assets/jss/material-kit-pro-react";

const accountStyle = theme => ({
  panel: {
    width: "100vw",
    height: "100vh",
    backgroundColor: grayColor[2],//grayColor[1],
    paddingTop: 120
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      //paddingBottom: "100px"
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: "500px",
    }
  },
  title: {
    marginTop: 10,
    color: primaryColor[0],
    textTransform: "uppercase",
    fontWeight: 500,
    marginBottom: 10
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "14px"
  },
  button: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    marginLeft: "20px",
    display: "inline-flex",
    paddingLeft: "14px",
    paddingRight: "14px"
  },
  // TODO migrate this into button
  smallButton: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    display: "inline-flex",
    paddingLeft: "14px",
    paddingRight: "14px",
    marginTop: "8px",
    paddingTop: "4px",
    paddingBottom: "4px",
    height: "30px",
    maxHeight: "30px"
  },
  buttonContainer: {
    paddingTop: "20px"
  }
});

export default accountStyle;
