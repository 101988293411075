import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import {A} from "hookrouter";
import Grid from "@material-ui/core/Grid";
import EventsOverview from "../Events/Overview/EventsOverview";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {Face, Print} from "@material-ui/icons";
import {hasRole, isLoggedIn} from "../../Lib/auth/auth";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {roles} from "../../Main/roles";
import Hidden from "@material-ui/core/Hidden";
import {useContents} from "../../Api/contents";
import ContentShort from "../Contents/User/ContentShort";

const useStyles = makeStyles({
  button: {
    width: "12em",
    backgroundColor: "#396593"
  }
});

export default () => {
  const authContext = useContext(AuthContext);

  const accountMenu = isLoggedIn(authContext);

  const {data: news} = useContents({short: true, kind: "news"});

  return (
      <ContentBox>
        <br />
        <Hidden smDown>
          <Grid container spacing={2} justify={"center"}>
            {accountMenu ? <Grid item md><AccountMenu authContext={authContext}/></Grid> : null}
            <Grid item md={6}>{news.map(item => <ContentShort key={item.id} record={item}/>)}</Grid>
            <Grid item md={4}><EventsOverview/></Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container spacing={1}>
            <Grid item xs={12}><EventsOverview/></Grid>
            {accountMenu ? <Grid item xs={12}><AccountMenu authContext={authContext}/></Grid> : null}
            <Grid item xs={12}>{news.map(item => <ContentShort key={item.id} record={item}/>)}</Grid>
          </Grid>
        </Hidden>
      </ContentBox>
  );
}

const NavButton = ({to, label, icon}) => {

  const {t} = useTranslation("dashboard");
  const classes = useStyles();

  return (
      <A href={to}>
        <Button color="info" className={classes.button}>
          {icon}
          <span style={{marginLeft: 4}}>{t(label)}</span>
        </Button>
      </A>
  );
};

const AccountMenu = ({authContext}) => {

  const accountId = authContext.getIn(['session', 'accountId']);
  const principalName = authContext.getIn(['session', 'principalName']);

  return (
      <Grid container style={{marginTop: 4}} justify="center">
        <NavButton to={"/accounts/" + accountId + "/edit"} label="account" icon={<Face/>}/>
        <NavButton to={"/documents"} label="documents" icon={<Print/>}/>
        {!hasRole(authContext, roles.BUSINESS_ADMIN) && !hasRole(authContext, roles.SECURITY_OFFICER) ? null :
            <React.Fragment>
              <NavButton label="clubs" to="/clubs"/>
              <NavButton label="events" to="/admin/events"/>
              <NavButton label="competitions" to="/admin/competitions"/>
              <NavButton label="championships" to="/admin/championships"/>
              <NavButton label="templates" to="/admin/templates"/>
              <NavButton label="contents" to="/admin/contents"/>
              <NavButton label="actions" to="/admin/actions"/>
            </React.Fragment>
        }
        {!hasRole(authContext, roles.EVENT_ORGANIZER) && !hasRole(authContext, roles.SECURITY_OFFICER) ? null :
            <React.Fragment>
              <NavButton label="registration" to="/organizer/competitions/47/registration"/>
            </React.Fragment>
        }
      </Grid>
  );
};