import React, {useCallback, useReducer} from 'react';
import "../Lib/material-dashboard/assets/scss/material-dashboard-pro-react.scss?v=1.7.0";
import "../Lib/material-kit/assets/scss/material-kit-pro-react.scss?v=1.7.0";
import {SnackbarProvider} from "notistack";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Immutable from "immutable";

import {reducer as appReducer} from "./AppStore";
import {reducer as authReducer} from "../Lib/auth/Stores/AuthStore";

import {AuthContext} from "../Lib/auth/AuthContext";
import {AppContext} from "./AppContext";
import {navigate, useRoutes} from "hookrouter";

let appContext = Immutable.Map();

const theme = createMuiTheme({});

function App({authStoreInit, appStoreInit}) {

  const [authStore, authDispatch] = useReducer(authReducer, authStoreInit);

  const [appStore, appDispatch] = useReducer(appReducer, appStoreInit);

  const dispatch = useCallback((action) => {
    appDispatch(action);
    authDispatch(action);
  }, [appDispatch, authDispatch]);

  appContext = appContext.merge({
    appStore: appStore,
    dispatch: dispatch
  });

  const content = useRoutes(appStore.get('routingMap'));

  if (!content) {
    navigate('/');
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  return (
      <AuthContext.Provider value={authStore}>
        <AppContext.Provider value={appContext}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <SnackbarProvider autoHideDuration={1200} anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
              {content}
            </SnackbarProvider>
          </MuiThemeProvider>
        </AppContext.Provider>
      </AuthContext.Provider>
  );

}

export default App;