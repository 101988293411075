import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../Lib/Table/Table";
import {updateParticipation, useParticipations, useSections} from "../../../../Api/competitions";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import EditableCheckbox from "../../../../Lib/Table/Components/EditableCheckbox";
import EditableText from "../../../../Lib/Table/Components/EditableText";
import {A} from "hookrouter";

const List = ({competitionId, path}) => {

  const {t} = useTranslation('competition');

  const {data, setData} = useParticipations(competitionId);
  const {data : sections} = useSections(competitionId);

  const setField = useCallback((rowIndex, fieldName, value) => {
    data[rowIndex] = data[rowIndex].set(fieldName, value);
    setData(data);
  }, [data, setData]);

  const columns = [
    {
      accessor: 'id',
      Header: t('id')
    },
    {
      accessor: 'startNumber',
      Header: t('startNumber')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'registered',
      Header: t('registered'),
      Cell: EditableCheckbox
    },
    {
      accessor: 'medicalExpiration',
      Header: t('medicalExpiration'),
      Cell: EditableText
    },
    {
      accessor: 'medicalValid',
      Header: t('medicalValid'),
      Cell: EditableCheckbox
    },
    {
      accessor: 'ageGroup',
      Header: t('ageGroup')
    },
    {
      accessor: 'tags',
      Header: t('tags')
    },
    {
      accessor: 'sections',
      Header: t('sections'),
      Cell: ({row}) => <Section sectionList={sections} sections={row.values.sections} />
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <A href={"/admin/competitions/" + competitionId + "/participations/" + row.values.id + "/edit"}>Edit</A>
    }
  ];

  return (
      <ContentBox path={path} add={"/admin/competitions/" + competitionId + "/participations/add"}>
        <Table columns={columns} data={data} tableProps={{setField, updateApi : updateParticipation}}/>
      </ContentBox>
  );
};

const Section = ({sectionList, sections}) => {

  let sectionNames = [];

  sections.forEach(sectionId => {
    const sectionRecords = sectionList.filter(sr => sr.id === sectionId);
    sectionNames.push(sectionRecords.length === 0 ? sectionId : sectionRecords[0].name);
  });

  return sectionNames.join(', ');
};

export default List;