import React from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";

export default () => {
  return (
      <ContentBox>
        Ide jön majd:
        <ul>
          <li>Klubok</li>
          <li>Mászótermek</li>
          <li>Versenyzők</li>
        </ul>
      </ContentBox>
  );
}