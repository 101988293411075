import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../../Lib/form/useForm";
import {ParticipationRecord, ParticipationSchema} from "../../../../Data/Competition";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedCheckbox from "../../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import {useSections} from "../../../../Api/competitions";
import ValidatedDate from "../../../../Lib/form/Components/ValidatedDateTime/ValidatedDate";
import ValidatedCheckboxGroup from "../../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckboxGroup";
import Grid from "@material-ui/core/Grid";

export default ({competitionId, id, record, path, view, api}) => {

  const {t} = useTranslation('competition');

  const {data: sections} = useSections(competitionId);

  const form = useForm({
    recordType: ParticipationRecord,
    recordSchema: ParticipationSchema,
    namespace: 'competition',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        <Section title={t('participant')}>
          <ValidatedInput name="startNumber" disabled/>
          <ValidatedInput name="name" disabled/>
          <ValidatedCheckbox name="registered" label="registered"/>
          <ValidatedDate name="medicalExpiration"/>
          <ValidatedCheckbox name="medicalValid" label="medicalValid"/>
          <ValidatedInput name="ageGroup" disabled/>
          <ValidatedInput name="tags"/>
          <ValidatedCheckboxGroup name="sections">
            <Grid container>
              {sections.map(sr => <ValidatedCheckbox name="sections" key={sr.id}  value={sr.id} label={sr.name} multi/>)}
            </Grid>
          </ValidatedCheckboxGroup>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
};