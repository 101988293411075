import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {AccountRecord, AccountSchema} from "../Data/account";


export function useAccounts(filter) {
  return useFetchMany('/accounts', undefined, AccountRecord);
}

export function useAccount(id) {
  return useFetchOne('/accounts', id, AccountRecord, AccountSchema);
}

export function addAccount(account, onSuccess, onError) {
    baseFetch('POST', '/accounts', account,
        data => {
            if (onSuccess) onSuccess(new AccountRecord(data))
        },
        onError
    );
}

export function activateAccount(id, ak, onSuccess, onError) {
  baseFetch('GET', '/accounts/' + id + '/activation-keys/' + ak, undefined,
      onSuccess,
      onError
  );
}

export function getAccounts(filter, onSuccess, onError) {
    baseFetch('GET', '/accounts', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new AccountRecord(r)))
        },
        onError
    );
}

export function getAccount(id, onSuccess, onError) {
    baseFetch('GET', '/accounts/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new AccountRecord(data))
        },
        onError
    );
}

export function updateAccount(account, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + account.get('accountId'), account, onSuccess, onError);
}

export function enableReset(reset, onSuccess, onError) {
  baseFetch('PATCH', '/accounts/reset', reset,
      onSuccess,
      onError
  );
}

export function resetCredentials(id, credentials, onSuccess, onError) {
  baseFetch('PATCH', '/accounts/' + id + '/reset', credentials,
      onSuccess,
      onError
  );
}

export function updateCredentials(id, credentials, onSuccess, onError) {
  baseFetch('PATCH', '/accounts/' + id + '/credentials', credentials,
      onSuccess,
      onError
  );
}

export function mergeAccounts(intoAccount, mergedAccount, onSuccess, onError) {
  baseFetch('PATCH', '/accounts/' + intoAccount + "/merge?with=" + mergedAccount, {}, onSuccess, onError);
}