import React, {memo, useContext, useState} from "react";
import classNames from "classnames";
// noinspection ES6CheckImport
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import headerStyle from "./headerStyle.jsx";
import logo from "../../Assets/img/mhssz-logo-small.png";
import HeaderLinks from "./HeaderLinks";
import {makeStyles} from "@material-ui/styles";
import {A} from "hookrouter";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {isLoggedIn} from "../../Lib/auth/auth";
import {Dashboard} from "@material-ui/icons";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";

const useStyles = makeStyles(headerStyle);

const Header = memo(() => {

  const {t} = useTranslation("header");

  const classes = useStyles();
  const authContext = useContext(AuthContext);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let brand;

  if (!isLoggedIn(authContext)) {
    brand = t('brand');
  } else {
    brand = authContext.getIn(['session', 'fullName']);
  }


  const appBarClasses = classNames({
    [classes.appBar]: true,
//    [classes[color]]: color,
    [classes.absolute]: true,
    [classes.fixed]: true
  });

  return (
      <React.Fragment>
        <AppBar className={appBarClasses}>
          <Toolbar className={classes.container}>
            <img src={logo} style={{height: 48, marginRight: 12}} alt="logo"/>
            <A href="/dashboard">
              <Button color="info" style={{padding: "8px 16px 8px 14px", backgroundColor: "#396593"}}>
                <Dashboard/>{t('dashboard')}
              </Button>
            </A>
            <Hidden smDown>
              <A href="/"><span style={{whiteSpace: "nowrap", color: "#396593", fontSize: "16px", marginLeft: "16px"}}>{brand}</span></A>
            </Hidden>
            <Hidden smDown implementation="css" className={classes.hidden}>
              <div className={classes.collapse}>
                <HeaderLinks menuToggle={handleDrawerToggle}/>
              </div>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
              >
                <Menu/>
              </IconButton>
            </Hidden>
          </Toolbar>
          <Hidden mdUp implementation="css">
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={mobileOpen}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={handleDrawerToggle}
            >
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  className={classes.closeButtonDrawer}
              >
                <Close/>
              </IconButton>
              <div className={classes.appResponsive}>
                <HeaderLinks menuToggle={handleDrawerToggle}/>
              </div>
            </Drawer>
          </Hidden>
        </AppBar>

      </React.Fragment>
  );
});

export default Header;