import React from "react";
import CardBody from "../../../material-kit/components/Card/CardBody";
import Card from "../../../material-kit/components/Card/Card";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
import {primaryColor, grayColor} from "../../../material-kit/assets/jss/material-kit-pro-react";

const useStyles = makeStyles({
  card: {
    margin: "8px 0",
    paddingBottom: "8px"
  },
  title: {
    marginTop: 10,
    color: primaryColor[0],
    fontSize: "0.75rem",
    textTransform: "uppercase",
    fontWeight: 500
  },
  notes: {
    marginTop: "4px",
    color: grayColor[1],
    fontSize: "0.75rem"
  }
});

function Section({title, notes, children}) {

  const classes = useStyles();

  return (
      <Card className={classes.card}>
        <CardBody>
          <Box className={classes.title}>{title}</Box>
          {notes ? <Box className={classes.notes}>{notes}</Box> : null}
          {children}
        </CardBody>
      </Card>
  );

};

export default Section;