export default {
  hu : {
    "contents": "Tartalmak",
    "content": "Tartalom",
    "name": "Név",
    "status": "Státusz",
    "details": "Részletek",
    "links": "Kapcsolatok",
    "kind": "Típus",
    "HIDDEN": "rejtett",
    "PUBLIC": "publikus",
    "ARCHIVE": "archivált"
  },
  en : {
    "contents" : "Contents",
    "content" : "Content",
    "name" : "Name",
    "status" : "Status",
    "details" : "Details",
    "links" : "Links",
    "kind" : "Type",
    "HIDDEN" : "hidden",
    "PUBLIC" : "public",
    "ARCHIVE" : "archive"
  }
};