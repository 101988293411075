import { Record } from 'immutable';
import {mixed, number, object, string} from "yup";

export const ClubRecord = Record({
    id: 0,
    status: '',
    name: '',
    mnemonic: '',
    contact: 0
});

export const ClubSchema = object().shape({
    id: number().required().min(0).default(0),
    status: mixed().required().oneOf(['ACTIVE', 'INACTIVE']).default('ACTIVE'),
    name: string().required().min(5).max(100).default(''),
    mnemonic: string().required().min(3).max(3).default(''),
    // contact: number().min(0)
});
