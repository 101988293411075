import React from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../Lib/Table/Table";
import {addParticipation, useCandidates} from "../../../../Api/competitions";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Button from "../../../../Lib/material-dashboard/components/CustomButtons/Button";
import {Add} from "@material-ui/icons";

export default ({competitionId, path}) => {

  const {t} = useTranslation('competition');

  const {data} = useCandidates();

  const add = (principalId) => {
    addParticipation(competitionId, principalId)
  };

  const columns = [
    {
      accessor: 'principal',
      Header: t('id')
    },
    {
      accessor: 'name',
      Header: t('general:name')
    },
    {
      accessor: 'ageGroup',
      Header: t('ageGroup')
    },
    {
      accessor: 'actions',
      Header: t('general:actions'),
      Cell: ({row}) => <Button justIcon round color={"success"} size={"sm"} onClick={() => add(row.values.principal)}><Add/></Button>
    }
  ];

  return (
      <ContentBox path={path}>
        <Table columns={columns} data={data}/>
      </ContentBox>
  );
};