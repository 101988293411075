import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {ChampionshipRecord, ChampionshipSchema} from "../../../Data/championship";
import {addChampionship, removeChampionship, updateChampionship, useChampionship} from "../../../Api/championships";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Crud config={crudConfig} view="list"/>,
  "/add": () => <Crud config={crudConfig} view="add"/>,
  "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>
};

const crudConfig = {
  compName: 'Championships',
  namespace: 'championships',
  url: '/championships',
  labelField: 'name',
  Record: ChampionshipRecord,
  Schema: ChampionshipSchema,
  ListComponent: List,
  FormComponent: Form,
  api: {
    use: useChampionship,
    add: addChampionship,
    edit: updateChampionship,
    remove: removeChampionship
  }
};
