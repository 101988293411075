import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "../../../Lib/material-dashboard/components/CustomButtons/Button";
import {A} from 'hookrouter';

export function Navigation(props) {

  const {id} = props;

  const {t} = useTranslation('competition');

  return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <A href={"/admin/competitions/" + id + "/edit"}>
            <Button color={"primary"} size={"sm"}>{t('base-data')}</Button>
          </A>&nbsp;
          <A href={"/admin/competitions/" + id + "/sections"}>
            <Button color={"primary"} size={"sm"}>{t('sections')}</Button>
          </A>&nbsp;
          <A href={"/admin/competitions/" + id + "/participations"}>
            <Button color={"primary"} size={"sm"}>{t('participations')}</Button>
          </A>&nbsp;
          <A href={"/admin/competitions/" + id + "/climbs"}>
            <Button color={"primary"} size={"sm"}>{t('climbs')}</Button>
          </A>&nbsp;
          <A href={"/admin/competitions/" + id + "/results"}>
            <Button color={"primary"} size={"sm"}>{t('results')}</Button>
          </A>
          <A href={"/admin/competitions/" + id + "/actions"}>
            <Button color={"primary"} size={"sm"}>{t('actions')}</Button>
          </A>
        </Grid>
      </Grid>
  );
}