import React from "react";
import List from "../Clubs/List";
import {A, useRoutes} from "hookrouter";
import Activate from "./Activate";
import Login from "./Login";
import Logout from "./Logout";
import ResetStart from "./ResetStart";
import {Form} from "./Form";
import AddSuccess from "./AddSuccess";
import ResetFinish from "./ResetFinish";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {Face} from "@material-ui/icons";
import debug from "../../Lib/debug";
import {useTranslation} from "react-i18next";
import {useAccount} from "../../Api/accounts";
import {AccountRecord, AccountSchema} from "../../Data/account";
import PasswordChange from "./PasswordChange";

const subRoutes = {
  "/": () => <List/>,
  "/assume": () => <Login />,
  "/renounce" : () => <Logout />,
  "/reset" : () => <ResetStart />,
  "/add": () => <Accounts view="add"/>,
  "/add/success" : () => <AddSuccess />,
  "/:id/password" : ({id}) => <PasswordChange id={id}/>,
  "/:id/reset/:token" : ({id, token}) => <ResetFinish id={id} token={token} />,
  "/:id/edit": ({id}) => <Accounts view="edit" id={id}/>,
  "/:id/activation-keys/:ak": ({id, ak}) => <Activate id={id} ak={ak} />
};

export default () => useRoutes(subRoutes);

// noinspection HtmlUnknownTarget
export const LoginButton = ({classes, t}) => (
  <A href="/accounts/assume">
    <Button color="success" className={classes.button}>
      <Face className={classes.icons}/><span style={{marginLeft: 4}}>{t('header:login')}</span>
    </Button>
  </A>
);

export const Accounts = ({view, id}) => {
  debug("RENDER", "Accounts", () => [view]);

  const {t} = useTranslation('account');

  const {loadingStatus, data} = useAccount(id);

  // this hack with hello bello is here, so the password will be valid and form
  // send button is enabled, the server does not update the password with account
  // PATCH, so it doesn't actually matters. It is needed for edit only, add is
  // fine without credentials

  const viewData = (loadingStatus ? new AccountRecord(AccountSchema.default()) :
      view === "add" ? data : data.set('credentials', 'Hello.Bello.12').set('verification', 'Hello.Bello.12'));

  switch (view) {
    case "add" : {
      const path = [{label: t('add'), url: "/accounts/add"}];
      return <Form id={id} record={viewData} path={path} view={view}/>;
    }

    case "edit" : {
      const path = [{label: t('edit'), url: "/accounts/" + id + "/edit"}];
      return <Form id={id} record={viewData} path={path} view={view}/>;
    }

    default :
      return <div>invalid view: {view}</div>
  }
};