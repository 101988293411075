import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {TemplateRecord, TemplateSchema} from "../Data/Template";

export function useTemplates(filter) {
  return useFetchMany('/templates', undefined, TemplateRecord);
}

export function useTemplate(id) {
    return useFetchOne('/templates', id, TemplateRecord, TemplateSchema);
}

export function addTemplate(template, onSuccess, onError) {
    baseFetch('POST', '/templates', template,
        data => {
            if (onSuccess) onSuccess(new TemplateRecord(data))
        },
        onError
    );
}

export function getTemplates(filter, onSuccess, onError) {
    baseFetch('GET', '/templates', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new TemplateRecord(r)))
        },
        onError
    );
}

export function getTemplate(id, onSuccess, onError) {
    baseFetch('GET', '/templates/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new TemplateRecord(data))
        },
        onError
    );
}

export function updateTemplate(template, onSuccess, onError) {
    baseFetch('PATCH', '/templates/' + template.get('id'), template, onSuccess, onError);
}

export function removeTemplate(template, onSuccess, onError) {
    baseFetch('DELETE', '/templates/' + template.get('id'), undefined, onSuccess, onError);
}
