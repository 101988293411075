import React, {Fragment} from "react";
import Button from "@material-ui/core/Button";

export const closeSnack = (t, closeSnackbar, key) => (
    <Fragment>
      <Button href="" onClick={() => { closeSnackbar(key) }}>
        {t('general:close')}
      </Button>
    </Fragment>
);
