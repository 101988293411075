import React from "react";
import styles from "../../material-dashboard/assets/jss/customCheckboxRadioSwitch.jsx";
import {makeStyles} from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {Check} from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default (props) => {

  const {
    setField,
    updateApi,
    cell: {value: initialValue},
    row: {original: record, index},
    column: {id: fieldName}
  } = props;

  const [value, setValue] = React.useState(initialValue);

  const classes = useStyles();

  const onChange = e => {
    const newValue = e.target.checked;
    updateApi(record.set(fieldName, newValue), () => {
      setValue(newValue);
      setField(index, fieldName, newValue);
    })
  };

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);


  return  <Checkbox
      checked={value}
      onChange={onChange}
      value={value}
      checkedIcon={<Check className={classes.checkedIcon}/>}
      icon={<Check className={classes.uncheckedIcon}/>}
      classes={{checked: classes.checked}}
  />;
};