import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {ClubRecord, ClubSchema} from '../../Data/Club';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";

export default memo(({id, record, path, view, api}) => {

  const {t} = useTranslation('club');

  const form = useForm({
    recordType: ClubRecord,
    recordSchema: ClubSchema,
    namespace: 'club',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

          <Grid container  direction="row" justify="center">
              <Grid item xs={8}>
                <Section title={t(id ? 'edit' : 'add')}>
                  <ValidatedRadioGroup name="status">
                    <ValidatedRadio name="status" value="ACTIVE"/>
                    <ValidatedRadio name="status" value="INACTIVE"/>
                  </ValidatedRadioGroup>
                  <ValidatedInput name="name"/>
                  <ValidatedInput name="mnemonic"/>
                  {/*<ValidatedInput name="contact"/>*/}
                </Section>
              </Grid>

              <Grid item xs={8}>
                <ValidatedSubmit view={view} api={api}/>
              </Grid>
          </Grid>

      </ContentBox>
  );
})