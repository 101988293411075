import {Record} from 'immutable';

const SessionData = Record({
  principalId: 0,
  accountId: 0,
  principalName: 'anonymous',
  fullName : '',
  displayName: '',
  roles : [ 'anonymous' ]
});

export default SessionData;