export default {
    hu: {
        "competitions": "Versenyek",
        "competition": "Verseny",
        "base-data": "Alapadatok",
        "sections": "Szekciók",
        "section": "Szekció",
        "participations": "Résztvevők",
        "climbs": "Mászások",
        "routes": "Utak",
        "partcipants": "Résztvevők",
        "results": "Eredmények",
        "actions": "Műveletek",
        "climbingTypes": "Versenyszámok",
        "year": "Év",
        "points": "Pont",

        "tops": "T",
        "zones": "Z",
        "topTries": "TP",
        "zoneTries": "ZP",

        "save": "Mentés",
        "back": "Vissza",

        "name": "Név",
        "mnemonic": "Rövidítés",
        "event": "Esemény",
        "status": "Státusz",
        "rank": "Rang",
        "climbingType": "Mászástípus",
        "ageGroups": "Korcsoportok",
        "options": "Opciók",
        "section-options-help": "Speciális opciók, csak akkor használd, ha tudod, mit jelent. Lehetőségek: all-qualifiers amateur simple-ref",
        "route-options-help": "Speciális opciók, csak akkor használd, ha tudod, mit jelent. Lehetőségek: amateur professional",
        "notes": "Jegyzetek",
        "tags": "Címkék",

        "amateur": "Amatőr",
        "position": "Pozíció",
        "displayPosition": "Megjelenítési pozíció",

        "PREPARATION": "előkészítés",
        "ACTIVE": "aktív",
        "CLOSED": "lezárt",

        "QUALIFIER": "selejtező",
        "QUATERFINAL": "negyeddöntő",
        "SEMIFINAL": "elődöntő",
        "FINAL": "döntő",
        "SUPERFINAL": "szuperdöntő",

        "BOULDER": "boulder",
        "LEAD": "nehézségi",
        "SPEED": "gyorsasági",

        "MINI": "mini",
        "CHILD": "gyerek",
        "TEEN_AGE": "serdülő",
        "YOUTH_A": "ifjúsági A",
        "YOUTH_B": "ifjúsági B",
        "JUNIOR": "junior",
        "ADULT": "nyílt",
        "SENIOR": "senior",

        "all": "Összes",
        "FEMALE": "Nő",
        "MALE": "Férfi",
        "GIRL": "Lány",
        "BOY": "Fiú",

        "ageGroup": "Korcsoport",
        "startNumber": "Rajtszám",
        "medicalExpiration": "Sportorvosi",
        "medicalValid": "Érvényes",
        "registered": "Jelen",

        "import-participations": "Résztvevők importálása",
        "add-sections-warning": "Add hozzá a selejtezőket mielőtt importálsz!",
        "recalculate": "Újraszámítás",
        "recalculate-warning": "A lezárt versenyt is újraszámolja, óvatosan!",

        "refresh": "...frissítés...",

        "sc-section": "Szekció",
        "sc-tops": "Top",
        "sc-zones": "Zóna",
        "sc-topTries": "Top Próba",
        "sc-zoneTries": "Zóna Próba",
        "sc-route": "Út",
        "sc-status": "Eredmény",
        "sc-top": "top",
        "sc-zone": "zóna",
        "sc-remaining": "próba még",
        "enter-startnumber": "Írj be egy rajtszámot.",
        "no-results-yet": "Nincs még eredmény ehhez a rajtszámhoz.",
        "unknown-startnumber": "Ismeretlen rajtszám.",
        "loading": "...betöltés...",
        "error": "Opsz, hiba történt... szólj valakinek, hogy nézzen rá.",
    },
    en: {
        "competitions": "Competitions",
        "competition": "Competition",
        "base-data": "Base data",
        "sections": "Sections",
        "section": "Section",
        "participations": "Participations",
        "climbs": "Climbs",
        "routes": "Routes",
        "partcipants": "Participants",
        "results": "Results",
        "actions": "Actions",
        "climbingTypes": "Disciplines",
        "year": "Year",
        "points": "points",

        "tops": "T",
        "zones": "Z",
        "topTries": "TT",
        "zoneTries": "ZT",

        "save": "Save",
        "back": "Back",

        "name": "Name",
        "mnemonic": "Mnemonic",
        "event": "Event",
        "status": "Status",
        "rank": "Rank",
        "climbingType": "Discipline",
        "ageGroups": "Age groups",
        "options": "Options",
        "section-options-help": "Special options! Use it only if you know what they mean. Valid options: all-qualifiers amateur simple-ref",
        "route-options-help": "Special options! Use it only if you know what they mean. Valid options: amateur professional",
        "notes": "Notes",
        "tags": "Tags",

        "amateur": "Amateur",
        "position": "Position",
        "displayPosition": "Display position",

        "PREPARATION": "preparation",
        "ACTIVE": "active",
        "CLOSED": "closed",

        "QUALIFIER": "qualification",
        "QUATERFINAL": "quarter final",
        "SEMIFINAL": "semi-final",
        "FINAL": "final",
        "SUPERFINAL": "super final",

        "BOULDER": "boulder",
        "LEAD": "lead",
        "SPEED": "speed",

        "MINI": "mini (U10)",
        "CHILD": "child (U12)",
        "TEEN_AGE": "teen age (U14)",
        "YOUTH_A": "youth A (U18)",
        "YOUTH_B": "youth B (U16)",
        "JUNIOR": "junior (U20)",
        "ADULT": "adult / open",
        "SENIOR": "senior",

        "all": "All",
        "FEMALE": "Female",
        "MALE": "Male",
        "GIRL": "Girl",
        "BOY": "Boy",

        "ageGroup": "Age group",
        "startNumber": "Start number",
        "medicalExpiration": "Medical expiration",
        "medicalValid": "Valid",
        "registered": "Present",

        "import-participations": "Import participants from given event",
        "add-sections-warning": "Add qualification rounds before using it!",
        "recalculate": "Recalculation",
        "recalculate-warning": "Be careful! A closed competition is recalculated, too!",

        "refresh": "...refresh...",

        "sc-section": "Section",
        "sc-tops": "Top",
        "sc-zones": "Zone",
        "sc-topTries": "Top trial",
        "sc-zoneTries": "Zone trial",
        "sc-route": "Route",
        "sc-status": "Result",
        "sc-top": "top",
        "sc-zone": "zone",
        "sc-remaining": "trials remaining",
        "enter-startnumber": "Enter start number.",
        "no-results-yet": "No results yet for this start number.",
        "unknown-startnumber": "Unknown start number.",
        "loading": "...loading...",
        "error": "The is an error... Please notify staff at the event.",
    }
};