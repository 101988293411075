import React, {useContext, useState} from "react";
import {executeRecalculate, fixClimbs, useClimbs, useParticipationResults} from "../../../Api/competitions";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import {makeStyles} from "@material-ui/styles";
import {grayColor, successColor} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";
import LoadingStates from "../../../Lib/LoadingStates";
import {navigate} from "hookrouter";
import {hasRole} from "../../../Lib/auth/auth";
import {AuthContext} from "../../../Lib/auth/AuthContext";
import {closeSnack} from "../../../Main/util";
import {useSnackbar} from "notistack";

const useStyles = makeStyles({
      card: {},
      select: {
        backgroundColor: "lightblue",
        padding: "8px 8px 16px 16px",
      },
      name: {
        fontSize: 18
      },
      headerTable: {
        backgroundColor: "yellow",
        border: 0,
        borderCollapse: "collapse",
        cellSpacing: 0,
        cellPadding: 0,
        marginLeft: 16,
        marginTop: 24,
        marginBottom: 24,
        "& th": {
          padding: "4px 8px 4px 8px",
          textAlign: "center",
          textTransform: "lowercase",
          backgroundColor: successColor[5],
          color: grayColor[2],
          fontSize: "0.75rem",
          fontWeight: 400
        },
        "& td": {
          padding: "4px 8px 4px 8px",
          textAlign: "center",
          fontWeight: 500,
          fontSize: 14
        }
      },
      sectionName: {
        fontSize: 18,
        paddingRight: 32
      },
      routeTable: {
        border: 0,
        borderCollapse: "collapse",
        cellSpacing: 0,
        cellPadding: 0,
        marginLeft: 16,
        "& th": {
          padding: "4px 8px 4px 8px",
          textAlign: "center",
          textTransform: "lowercase",
          backgroundColor: successColor[5],
          color: grayColor[2],
          fontSize: "0.75rem",
          fontWeight: 400
        },
        "& tr": {
          borderBottom: "1px solid " + grayColor[10]
        },
        "& td": {
          padding: "4px 8px 4px 8px",
          textAlign: "center",
          fontSize: 14
        }
      },
      startNumber: {
        fontSize: 18,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 8,
        border: "1px solid #396593",
        borderRadius: 4,
        height: "2em",
        width: "4em",
        textAlign: "center",
        fontWeight: 500
      },
      remaining: {
        fontSize: 14,
        color: "red",
        fontWeight: 500
      }
    }
);

export default ({competitionId, startNumber}) => {
  debug("RENDER", "Competitions.SelfCheck", () => [competitionId, startNumber]);

  const {t} = useTranslation('competition');
  const classes = useStyles();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const authContext = useContext(AuthContext);

  const sni = parseInt(startNumber);

  const {loadingStatus, data} = useParticipationResults(competitionId, isNaN(sni) ? undefined : { startNumber: sni });

  // useClimbs without a startNumber returns with
  const {data : climbs} = useClimbs(competitionId,  isNaN(sni) ? null : { startNumber: sni });

  const partition = (data && data.partitions && data.partitions.length ? data.partitions[0] : undefined);
  const hasResult = partition && partition.results[0];

  let name = '';

  const context = {
    competition: competitionId,
    climbingType: undefined,
    participation: undefined,
    gender: undefined,
    climbs: climbs
  };

  switch (loadingStatus) {
    case LoadingStates.READY :
      if (hasResult) {
        name = partition.results[0].name;
        context.participation = partition.results[0].participation;
        context.gender = partition.gender;
        context.climbingType = partition.climbingType;
      } else {
        name = startNumber ? t('no-results-yet') : t('enter-startnumber');
      }
      break;

    case LoadingStates.ERROR:
      name = t('unknown-startnumber');
      break;

    case LoadingStates.LOADING:
      name = t('loading');
      break;

    default:
      name = t('error');
  }


  // TODO think about this a bit, a short delay might be a good idea, or a "show" button
  const onChange = (e) => {
    const v = parseInt(e.target.value);
    if (v <= 0) return;
    navigate('/competitions/' + competitionId + '/self-check/' + v, true)
  };

  const recalculate = () => {
    executeRecalculate(
        competitionId,
        () => enqueueSnackbar(t('general:execute.success')),
        () => enqueueSnackbar(t('general:execute.error'), {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)})
    )
  };

  return (
      <React.Fragment>
        <div className={classes.select}>
          {t('startNumber')}:
          <input
              className={classes.startNumber}
              autoFocus
              value={startNumber}
              type="number"
              name="startNumber"
              onChange={onChange}
          />
          <span className={classes.name}>{name}</span>
          {hasRole(authContext, "security-officer") ? <button onClick={recalculate}>{t('recalculate')}</button> : null}
        </div>
        {hasResult ?
            partition.results[0].sectionResults.map(sr => <SectionResult key={sr.section} result={sr} sections={data.sections} context={context}/>)
            : null
        }
      </React.Fragment>
  );
};

const SectionResult = ({result, sections, context}) => {
  const {t} = useTranslation('competition');
  const classes = useStyles();

  const section = sections[result.section];

  const rc = {};
  section.routes.forEach(r => rc[r.id] = Object.assign({}, r));

  const routes = [];
  result.routeResults.forEach(rr => routes.push(Object.assign(rc[rr.route], rr)));

  return (
      <React.Fragment>
        <table className={classes.headerTable}>
          <thead>
          <tr>
            <th>{t('sc-section')}</th>
            <th>{t('sc-tops')}</th>
            <th>{t('sc-zones')}</th>
            <th>{t('sc-topTries')}</th>
            <th>{t('sc-zoneTries')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{section.name}</td>
            <td>{result.tops}</td>
            <td>{result.zones}</td>
            <td>{result.topTries}</td>
            <td>{result.zoneTries}</td>
          </tr>
          </tbody>
        </table>

        <table className={classes.routeTable}>
          <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{t('sc-route')}</th>
            <th>{t('sc-status')}</th>
            <th>{t('sc-remaining')}</th>
            <th>{t('sc-topTries')}</th>
            <th>{t('sc-zoneTries')}</th>
          </tr>
          </thead>
          <tbody>
          {routes.map(r => <RouteResult key={r.id} route={r} tryLimit={section.tryLimit}  section={section} context={context}/>)}
          </tbody>
        </table>
      </React.Fragment>
  );
};

const RouteResult = ({route, tryLimit, section, context}) => {

  const authContext = useContext(AuthContext);

  if (route.gender !== 'BOTH' && context.gender !== route.gender) return null;

  return (
      <tr>
        <td>
          <div style={{width: 16, height: 16, backgroundColor: route.color}}>&nbsp;</div>
        </td>
        <td>{route.label}</td>
        <Status holdNumber={route.bestHoldNumber} remaining={tryLimit - route.tries[0]} climbingType={context.climbingType}/>
        <td>{route.tries[1] ? route.tries[1] : ''}</td>
        <td>{route.tries[2] ? route.tries[2] : ''}</td>
        {hasRole(authContext, "security-officer") ? <td><ResultUpdate route={route} section={section} context={context}/></td> : null}
      </tr>
  )
};

const holdNumberToLetter = {
  0 : 'p',
  1 : 'Z',
  2 : 'T'
};

const ResultUpdate = ({route, section, context}) => {
  const rc = context.climbs.filter(c => c.route === route.id).map(c => holdNumberToLetter[c.holdNumber]).join('');

  const {t} = useTranslation('competition');
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [last, setLast] = useState(rc);
  const [fix, setFix] = useState(rc);

  const update = () => {
    const dto = {
      competition: context.competition,
      section: section.id,
      participation: context.participation,
      route: route.id,
      climbs: fix
    };

    fixClimbs(
        dto,
        () => {
          enqueueSnackbar(t('general:execute.success'));
          setLast(fix);
        },
        () => enqueueSnackbar(t('general:execute.error'), {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)})
    );
  };

  return (
      <React.Fragment>
        <input value={fix} onChange={e => setFix(e.target.value)}/>
        {last === fix ? null : <button onClick={update}>{t('general:save')}</button>}
      </React.Fragment>
  );
};

const Status = ({holdNumber, remaining, climbingType}) => {
  const {t} = useTranslation('competition');
  const classes = useStyles();

  if (climbingType === 'BOULDER') {
    switch (holdNumber) {
      case 0:
        return (
            <React.Fragment>
              <td>&nbsp;</td>
              <td className={classes.remaining}>{remaining}</td>
            </React.Fragment>
        );

      case 10:
        return (
            <React.Fragment>
              <td>{t('sc-zone')}</td>
              <td className={classes.remaining}>{remaining}</td>
            </React.Fragment>
        );

      case 20:
        return (
            <React.Fragment>
              <td>{t('sc-top')}</td>
              <td>&nbsp;</td>
            </React.Fragment>
        );

      default :
        return (
            <React.Fragment>
              <td>?</td>
              <td>&nbsp;</td>
            </React.Fragment>
        );
    }
  } else {
    return <td colSpan={2}>{holdNumber / 10}</td>
  }
};