import React from "react";
import {FormContext} from "../../useForm";


/**
 * Component to encapsulate children in a form with form context
 * provided.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function FormProvider(props) {

  const {form, children, className} = props;

  return (
      <FormContext.Provider value={form}>
        <form className={className}>
          {children}
        </form>
      </FormContext.Provider>
  );
}

export default FormProvider;
