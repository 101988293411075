import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../Lib/Table/Table";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useDocuments} from "../../Api/documents";
import Download from "../../Lib/Table/Download";
import {useEventsSelectForYear} from "../../Api/events";
import LoadingStates from "../../Lib/LoadingStates";

const urlNs = "/documents/";

const List = memo((props) => {

    const {path} = props;

    const {t} = useTranslation("documents");

    const {loadingStatus: eventLoadingStatus, data: events} = useEventsSelectForYear();
    const eventMap = {};
    if (eventLoadingStatus === LoadingStates.READY) {
        events.forEach(e => eventMap[e.id] = e.label);
    }

    const {data} = useDocuments();

    const columns = [
        {
            accessor: 'id',
            Header: t('general:identifier'),
            sortType: 'basic',
        },
        {
            accessor: 'type',
            Header: t('type'),
            sortType: 'basic',
            Cell: ({cell}) => t(cell.value),
        },
        {
            accessor: 'event',
            Header: t('event'),
            sortType: 'basic',
            Cell: ({cell}) => {
                const en = eventMap[cell.value];
                return (en === undefined ? "" : en);
            },
        },
        {
            accessor: 'fromDate',
            Header: t('from'),
            sortType: 'basic'
        },
        {
            accessor: 'toDate',
            Header: t('till'),
            sortType: 'basic'
        },
        {
            accessor: 'attachment',
            Header: t('general:actions'),
            Cell: ({cell}) => <Download url={"/api/1.0.0/attachments/" + cell.value}/>,
            disableFilters: true,
        }
    ];

    return (
        <ContentBox path={path} add={urlNs + "add"}>
            <Table columns={columns} data={data}/>
        </ContentBox>
    );
});

export default List;