import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {ClubRecord, ClubSchema} from "../Data/Club";

export function useClubs(filter) {
  return useFetchMany('/clubs', undefined, ClubRecord);
}

export function useClub(id) {
    return useFetchOne('/clubs', id, ClubRecord, ClubSchema);
}

export function addClub(club, onSuccess, onError) {
    baseFetch('POST', '/clubs', club,
        data => {
            if (onSuccess) onSuccess(new ClubRecord(data))
        },
        onError
    );
}

export function getClubs(filter, onSuccess, onError) {
    baseFetch('GET', '/clubs', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new ClubRecord(r)))
        },
        onError
    );
}

export function getClub(id, onSuccess, onError) {
    baseFetch('GET', '/clubs/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new ClubRecord(data))
        },
        onError
    );
}

export function updateClub(club, onSuccess, onError) {
    baseFetch('PATCH', '/clubs/' + club.get('id'), club, onSuccess, onError);
}

export function removeClub(club, onSuccess, onError) {
    baseFetch('DELETE', '/clubs/' + club.get('id'), undefined, onSuccess, onError);
}
