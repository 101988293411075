import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {DocumentRecord, DocumentSchema} from '../../Data/document';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import {useEventsSelectForYear} from "../../Api/events";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import ValidatedDate from "../../Lib/form/Components/ValidatedDateTime/ValidatedDate";
import {useAccount} from "../../Api/accounts";
import {useAccountId} from "../../Lib/auth/auth";
import Box from "@material-ui/core/Box";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {navigate} from "hookrouter";
import {Face} from "@material-ui/icons";
import LoadingStates from "../../Lib/LoadingStates";

export default memo(({id, record, path, view, api}) => {

    const {t} = useTranslation('documents');

    const form = useForm({
        recordType: DocumentRecord,
        recordSchema: DocumentSchema,
        namespace: 'documents',
        record: record
    });

    const {loadingStatus, data} = useEventsSelectForYear();

    const events = () => ({loadingStatus, data: data.map(r => ({id: r.get('id'), name: r.get('label')}))});

    const accountId = useAccountId();

    const {loadingStatus: accountLoadingStatus, data: account} = useAccount(accountId);

    let invalidAccountFields = undefined;
    if (accountLoadingStatus === LoadingStates.READY) {
        invalidAccountFields = [];
        if (account.birthPlace === undefined || account.birthPlace.length === 0) invalidAccountFields.push("birthPlace");
        if (account.mothersName === undefined || account.mothersName.length === 0) invalidAccountFields.push("mothersName");
        if (account.mothersBirthDate === null ||account.mothersBirthDate.length === 0) invalidAccountFields.push("mothersBirthDate");
    }

    const success = (result) => {
        window.open("/api/1.0.0/attachments/" + result.attachment);
    };

    return (
        <ContentBox path={path} form={form}>
            <Grid container direction="row" justify="center">
                <Grid item xs={8}>
                    <Section title={t(id ? 'edit' : 'add')}>
                        <ValidatedRadioGroup name="type">
                            <ValidatedRadio name="type" value="EXCULPATION"/>
                            <ValidatedRadio name="type" value="LEAVE_LETTER"/>
                            {/*<ValidatedRadio name="type" value="RESULT_CERTIFICATE"/>*/}
                        </ValidatedRadioGroup>
                        <ValidatedSelect name="event" api={events}/>
                        <ValidatedDate name="fromDate"/>
                        <ValidatedDate name="toDate"/>
                    </Section>
                </Grid>

                {invalidAccountFields === undefined || invalidAccountFields.length === 0 ? null :
                    <Grid item xs={8}>
                        <Section title={t('missing-account-data')}>
                            <br/>
                            {t('missing-account-data-explanation')} {invalidAccountFields.map(f => t(f))}
                            <Box ml={2} mt={1} component="div">
                                <Button
                                    onClick={() => navigate("/accounts/" + accountId + "/edit", false, {target: window.location.pathname})}
                                    color="info" size="sm"><Face/>{t('Fiók')}</Button>
                            </Box>
                        </Section>
                    </Grid>
                }

                <Grid item xs={8}>
                    <ValidatedSubmit view={view} api={api} onSuccess={success} disabled={invalidAccountFields === undefined || invalidAccountFields.length !== 0}/>
                </Grid>
            </Grid>

        </ContentBox>
    );
})