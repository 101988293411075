import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../../Lib/Table/Table";
import {useClimbs} from "../../../../Api/competitions";
import ContentBox from "../../../../Components/ContentBox/ContentBox";

const List = memo(({competitionId, path}) => {

  const {t} = useTranslation('competition');

  const { data } = useClimbs(competitionId);

  const columns = [
    {
      accessor: 'id',
      Header: t('general:identifier')
    },
    {
      accessor: 'section',
      Header: t('section')
    },
    {
      accessor: 'participation',
      Header: t('participation')
    },
    {
      accessor: 'holdNumber',
      Header: t('general:holdNumber')
    }
  ];

  return (
      <ContentBox path={path} add={"/admin/competitions/" + competitionId + "/sections/add"}>
        <Table columns={columns} data={data} />
      </ContentBox>
  );
});

export default List;