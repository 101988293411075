import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addTemplate, removeTemplate, updateTemplate, useTemplate} from "../../Api/templates";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {TemplateRecord, TemplateSchema} from '../../Data/Template';

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Templates view="list"/>,
  "/add": () => <Templates view="add"/>,
  "/:id/edit": ({id}) => <Templates view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Templates view="remove" id={id}/>,
};

const Templates = ({view, id}) => {

  debug("RENDER", "Templates", () => [view]);

  const {t} = useTranslation('template');

  const {loadingStatus, data} = useTemplate(id);

  const viewData = (loadingStatus ? new TemplateRecord(TemplateSchema.default()) : data);

  const path = [{label: t('template'), url: "/admin/templates"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('label');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/admin/templates/add"});
      return <Form id={id} record={viewData} path={path} view={view} api={addTemplate}/>;

    case "remove" :

      path.push({label: label, url: "/admin/templates/" + id + "/remove"});
      return <Form id={id} record={viewData} path={path} view={view} api={removeTemplate}/>;

    case "edit" :

      path.push({label: label, url: "/admin/templates/" + id + "/edit"});
      return <Form id={id} record={viewData} path={path} view={view} api={updateTemplate}/>;


    default :
      return <div>invalid view: {view}</div>
  }
};

