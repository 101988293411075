import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {EventRecord, EventSchema, EventGroupRecord, EventGroupSchema, EventShortRecord} from "../Data/Event";
import EventRegistration from "../Data/Records/EventRegistration";

export function useEvents(filter) {
  return useFetchMany('/events', filter, EventRecord);
}

export function useEventsShort() {
  return useFetchMany('/events', {format: "short"}, EventShortRecord);
}

export function useEventsSelectForYear() {
    return useFetchMany('/events', {format: "select-for-year"}, EventShortRecord);
}

export function useEvent(id) {
  return useFetchOne('/events', id, EventRecord, EventSchema);
}

export function addEvent(event, onSuccess, onError) {
    baseFetch('POST', '/events', event,
        data => {
            if (onSuccess) onSuccess(new EventRecord(data))
        },
        onError
    );
}

export function getEvents(filter, onSuccess, onError) {
    baseFetch('GET', '/events', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new EventRecord(r)))
        },
        onError
    );
}

export function getEvent(id, onSuccess, onError) {
    baseFetch('GET', '/events/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new EventRecord(data))
        },
        onError
    );
}

export function updateEvent(event, onSuccess, onError) {
    baseFetch('PATCH', '/events/' + event.get('id'), event, onSuccess, onError);
}

export function removeEvent(event, onSuccess, onError) {
    baseFetch('DELETE', '/events/' + event.get('id'), undefined, onSuccess, onError);
}

export function useEventGroups(eventId, filter) {
  return useFetchMany('/events/' + eventId + '/groups', filter, EventGroupRecord);
}

export function useEventGroup(eventId, id) {
  return useFetchOne('/events/' + eventId + '/groups/', id, EventGroupRecord, EventGroupSchema);
}

export function addEventGroup(eventGroup, onSuccess, onError) {
  baseFetch('POST', '/events/' + eventGroup.get('event') + '/groups', eventGroup,
      data => {
        if (onSuccess) onSuccess(new EventGroupRecord(data))
      },
      onError
  );
}

export function getEventGroup(filter, onSuccess, onError) {
  baseFetch('GET', '/events/' + filter.eid + '/groups/' + filter.id, undefined,
      data => {
        if (onSuccess) onSuccess(new EventGroupRecord(data))
      },
      onError
  );
}

export function updateEventGroup(eventGroup, onSuccess, onError) {
  baseFetch('PATCH', '/events/' + eventGroup.get('event') + '/groups/' + eventGroup.get('id'), eventGroup, onSuccess, onError);
}

export function removeEventGroup(eventGroup, onSuccess, onError) {
  baseFetch('DELETE', '/events/' + eventGroup.get('event') + '/groups/' + eventGroup.get('id'), undefined, onSuccess, onError);
}

// ---- Registration -----------

export function useEventRegistration(eventId) {
  return useFetchOne('/events/' + eventId + '/registration', null);
}

export function useEventRegistrations(filter) {
  return useFetchMany('/events/' + filter.eid + '/registrations', undefined);
}

export function addEventRegistration(eventRegistration, onSuccess, onError) {
  baseFetch('POST', '/events/' + eventRegistration.event + '/registrations', eventRegistration,
      data => {
        if (onSuccess) onSuccess(new EventRegistration(data))
      },
      onError
  );
}

export function getEventRegistration(filter, onSuccess, onError) {
  baseFetch('GET', '/events/' + filter.eid + '/registrations/' + filter.id, undefined,
      data => {
        if (onSuccess) onSuccess(new EventRegistration(data))
      },
      onError
  );
}

export function updateEventRegistration(eventRegistration, onSuccess, onError) {
  baseFetch('PATCH', '/events/' + eventRegistration.event + '/registrations/' + eventRegistration.id, eventRegistration, onSuccess, onError);
}
