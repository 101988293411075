import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ReactQuill from "react-quill";
import Grid from "@material-ui/core/Grid";
import Moment from "react-moment";
import {makeStyles} from "@material-ui/styles";
import {
    updateEventRegistration,
    useEventGroups,
    useEventRegistration,
    useEventRegistrations
} from "../../../Api/events";
import {
    dangerColor,
    grayColor,
    successColor,
    warningColor
} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";
import Button from "../../../Lib/material-dashboard/components/CustomButtons/Button";
import moment from "moment";
import {AuthContext} from "../../../Lib/auth/AuthContext";
import {isLoggedIn, useLoggedIn} from "../../../Lib/auth/auth";
import {A, navigate} from "hookrouter";
import Box from "@material-ui/core/Box";
import {closeSnack} from "../../../Main/util";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    headerCell: {
        paddingRight: "10px"
    },
    full: {
        color: dangerColor[0],
        fontWeight: 500,
        textTransform: "UPPERCASE",
        whiteSpace: "nowrap"
    },
    free: {
        color: successColor[0],
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    groups: {
        paddingTop: "8px"
    },
    group: {
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-child)": {
                borderBottom: "1px " + grayColor[3] + " solid",
                paddingBottom: "8px",
                marginBottom: "12px"
            }
        },
    },
    groupDate: {
        textAlign: "center"
    },
    inactive: {
        padding: "4px",
        borderRadius: "2px",
        marginBottom: "2px",
        whiteSpace: "nowrap"
    },
    active: {
        backgroundColor: successColor[3],
        color: "white",
        padding: "4px",
        borderRadius: "2px",
        marginBottom: "2px",
        whiteSpace: "nowrap"
    },
    warning: {
        backgroundColor: warningColor[4],
        color: "white",
        padding: "4px",
        borderRadius: "2px",
        whiteSpace: "nowrap"
    },
    closed: {
        backgroundColor: dangerColor[4],
        color: "white",
        padding: "4px",
        borderRadius: "2px",
        whiteSpace: "nowrap"
    }
}));


export default ({id, record}) => {

    const {t} = useTranslation('event');
    const classes = useStyles();

    const [state, setState] = useState({registration: undefined, revision: 0});

    const {data: groups} = useEventGroups(id, {revision: state.revision});

    const [registration, setRegistration] = useState();

    const {data: srvRegistration} = useEventRegistration(id);

    useEffect(() => {
        if (registration === undefined) {
            setRegistration(srvRegistration);
        }
    }, [registration, srvRegistration]);

    const {data: registrationList} = useEventRegistrations({eid: id});
    const registrationMap = {};

    registrationList.forEach(le => {
        let gl = registrationMap[le.groupName];
        if (gl === undefined) registrationMap[le.groupName] = gl = [];
        gl.push(le);
    });

    Object.keys(registrationMap).forEach(gn =>
        registrationMap[gn].sort((a, b) => a.name.localeCompare(b.name))
    );

    const np = [{label: record.name, url: '/events/' + record.id + '/view'}];

    return (
        <ContentBox path={np}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Section title={t('base-data')}>
                        <table>
                            <tbody>
                            <tr>
                                <td className={classes.headerCell}>{t('type')}:</td>
                                <td>{t(record.type)}</td>
                            </tr>
                            {
                                record.startDate === record.endDate ?
                                    <tr>
                                        <td className={classes.headerCell}>{t('date')}:</td>
                                        <td><Moment format="YYYY.MM.DD">{record.startDate}</Moment></td>
                                    </tr>
                                    :
                                    <React.Fragment>
                                        <tr>
                                            <td className={classes.headerCell}>{t('startDate')}:</td>
                                            <td><Moment format="YYYY.MM.DD">{record.startDate}</Moment></td>
                                        </tr>
                                        <tr>
                                            < td className={classes.headerCell}>{t('endDate')}:</td>
                                            <td><Moment format="YYYY.MM.DD">{record.endDate}</Moment></td>
                                        </tr>
                                    </React.Fragment>
                            }
                            <tr>
                                <td className={classes.headerCell}>{t('location')}:</td>
                                <td>{record.location}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Section>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Section title={t('Előnevezés')}>
                        <table>
                            <tbody>
                            <tr>
                                <td className={classes.headerCell}>{t('registrationPhase1')}:</td>
                                <td><DateWithStatus start={record.registrationPhase1} end={record.registrationEnd}
                                                    kind="normal"/></td>
                            </tr>
                            <tr>
                                <td className={classes.headerCell}>{t('registrationPhase2')}:</td>
                                <td><DateWithStatus start={record.registrationPhase2} end={record.registrationEnd}
                                                    kind="normal"/></td>
                            </tr>
                            <tr>
                                <td className={classes.headerCell}>{t('registrationPhase3')}:</td>
                                <td><DateWithStatus start={record.registrationPhase3} end={record.registrationEnd}
                                                    kind="warning"/></td>
                            </tr>
                            <tr>
                                <td className={classes.headerCell}>{t('registrationEnd')}:</td>
                                <td><DateWithStatus start={record.registrationEnd} kind="danger"/></td>
                            </tr>
                            </tbody>
                        </table>
                    </Section>
                </Grid>

                <Grid item xs={12}>
                    <Section title={t('groups')}>
                        <ShowLogin event={record}/>
                        <Grid container spacing={1} className={classes.groups}>
                            {groups.map(g =>
                                <Group
                                    key={g.id}
                                    t={t}
                                    classes={classes}
                                    group={g}
                                    event={record}
                                    registration={registration}
                                    setState={setState}
                                />)}
                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={12}>
                    <Section title={t('description')}>
                        <ReactQuill
                            readOnly
                            theme={null}
                            value={record.description}
                        />
                    </Section>
                </Grid>

                <Grid item xs={12}>
                    <Section title={t('participants')} notes={t('participants-explanation')}>
                        {Object.keys(registrationMap).map(gn =>
                            <React.Fragment>
                                <div style={{marginTop: 20}}><b>{gn}</b></div>
                                <div style={{display: 'flex', flexDirection: "row", flexWrap: "wrap"}}>
                                    {registrationMap[gn].map(le => <div style={{whiteSpace: "noWrap", padding: 10, minWidth: 200}}>{le.name}</div>)}
                                </div>
                            </React.Fragment>
                        )}
                    </Section>
                </Grid>
            </Grid>


        </ContentBox>
    );
}

const DateWithStatus = ({start, end, kind}) => {
    const now = moment();
    const classes = useStyles();

    if (!moment(start).isValid()) return null;

    let className;

    switch (kind) {
        case "normal" :
            className = (now.isAfter(start) && now.isBefore(end)) ? classes.active : classes.inactive;
            break;

        case "warning":
            className = (now.isAfter(start) && now.isBefore(end)) ? classes.warning : classes.inactive;
            break;

        case "danger":
            className = now.isBefore(start) ? classes.inactive : classes.closed;
            break;

        default:
            className = classes.inactive;
    }

    return <Box mb={0.5}><Moment format="YYYY.MM.DD HH:mm" className={className}>{start}</Moment></Box>;
};

const ShowLogin = ({event}) => {

    const authContext = useContext(AuthContext);
    const t = useTranslation("event").t;

    if (isLoggedIn(authContext)) return null;

    const now = moment();
    if (now.isBefore(event.registrationPhase1) || now.isAfter(event.registrationEnd)) return null;

    return (
        <Box mt={1} mb={1}>
            <Box mr={2} component="div" display="inline">{t('login-for-registration')}</Box>

            <Box mr={2} component="div" display="inline">
                <Button onClick={() => navigate("/accounts/assume", false, {target: window.location.pathname})}
                        color="success" size="sm">{t('login')}</Button>
            </Box>

            <A href={"/accounts/add"}>
                <Button color="info" size="sm">{t('account-add')}</Button>
            </A>
        </Box>
    );
};

const isOpenFor = (group, event, registration) => {

    if (registration === undefined || registration.ageGroup == null) return false;

    if (registration.group === group.id) return true; // cancel

    if (group.participantLimit <= group.participantCount) return false;

    if (group.ageGroups.indexOf(registration.ageGroup) === -1) return false;

    const now = moment();

    if (now.isAfter(event.registrationPhase1) && now.isBefore(event.registrationEnd) && registration.hasMedical) {
        return true;
    }

    return now.isAfter(event.registrationPhase2) && now.isBefore(event.registrationEnd);
};

const Group = ({t, classes, group, event, registration, setState}) => {
    const free = group.participantLimit - group.participantCount;

    const enabled = isOpenFor(group, event, registration);

    const startTime = moment(group.startTime, "hh:mm:ss");
    const endTime = moment(group.endTime, "hh:mm:ss");

    return (
        <Grid item xs={12} className={classes.group}>
            <Grid container alignItems="center">
                <Grid item xs={4} md={1}><b>{group.name}</b></Grid>
                <Grid item xs={4} md={1} className={classes.groupDate}><b>{group.day.substring(5).replace("-", ".")}</b></Grid>
                <Grid item xs={4} md={2}><Moment format="HH:mm">{startTime}</Moment> - <Moment
                    format="HH:mm">{endTime}</Moment></Grid>
                <Grid item xs={12} md={4}>{group.ageGroups.map(ag => t(ag)).join(', ')}</Grid>
                <Grid item xs={6} md={1}>{group.participantLimit} {t('slot')}</Grid>
                <Grid item xs={6} md={1}>{free <= 0
                    ? <span className={classes.full}>{t('full')}</span>
                    : <span className={classes.free}>{free} {t('free-slots')}</span>
                }</Grid>
                <Grid item xs={4} md={1}>
                    {enabled ? <GroupActions event={event} group={group} registration={registration}
                                             setState={setState}/> : null}
                </Grid>
                <Grid item xs={12}>{group.description}</Grid>
            </Grid>
        </Grid>
    );
};

const RegistrationLE = ({t, le}) => {
    return (
        <tr key={le.name}>
            <td>{le.name}</td>
            <td>{t(le.ageGroup)}</td>
            <td>{le.groupName}</td>
        </tr>
    );
};

const GroupActions = ({group, registration, setState}) => {
    const t = useTranslation("event").t;

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const execute = (newStatus) => {
        const oldStatus = registration.status;

        const reActivate = (registration.status === "CANCELLED" && newStatus === "ACTIVE");
        const groupChange = (oldStatus !== "NONEXISTING" && newStatus === "ACTIVE" && registration.group !== group.id);

        registration.status = newStatus;
        registration.group = group.id;
        registration.consent = true;

        // update and add performs the same operation on server side

        updateEventRegistration(registration,
            (result) => {
                let m = (oldStatus === "NONEXISTING" ? "registration-success" : "cancel-success");
                if (groupChange) m = "change-success";
                if (reActivate) m = "registration-success";

                enqueueSnackbar(t(m), {variant: 'success'});
                setState((old) => ({registration: result, revision: old.revision + 1}));
            },
            () => {
                let m = (oldStatus === "NONEXISTING" ? "registration-error" : "change-error");
                enqueueSnackbar(t(m), {
                    variant: 'error',
                    persist: true,
                    action: (key) => closeSnack(t, closeSnackbar, key)
                })
            }
        )
    };

    switch (registration.status) {
        case "NONEXISTING" :
        case "CANCELLED" :
            return <Button size="sm" color="info" onClick={() => execute("ACTIVE")}>{t('registration')}</Button>;

        case "ACTIVE" :
            if (registration.group === group.id) {
                return <Button size="sm" color="warning" onClick={() => execute("CANCELLED")}>{t('cancel')}</Button>;
            } else {
                return <Button size="sm" color="info" onClick={() => execute("ACTIVE")}>{t('group-change')}</Button>;
            }

        default :
            return null;
    }

};