import React from "react";
import Dashboard from "../Views/Dashboard/Dashboard";
import {roles} from "./roles";
import EventsAdmin from "../Views/Events/Admin/Events";
import EventsUser from "../Views/Events/User/Events";
import Accounts from "../Views/Account/Accounts";
import Clubs from "../Views/Clubs/Clubs";
import Templates from "../Views/Templates/Templates";
import ChampionshipsAdmin from "../Views/Championships/Admin/Championships";
import CompetitionsAdmin from "../Views/Competitions/Admin/Competitions";
import CompetitionsOrganizer from "../Views/Competitions/Organizer/Competitions";
import ExpiredSession from "../Views/Account/ExpiredSession";
import CompetitionsUser from "../Views/Competitions/User/CompetitionsUser";
import ChampionshipUser from "../Views/Championships/User/Championships";
import Community from "../Views/Community/Community";
import Information from "../Views/Information/Information";
import Contents from "../Views/Contents/Admin/Contents";
import Actions from "../Views/Actions/Actions";
import Documents from "../Views/Documents/Documents";
import Schedule from "../Views/Competitions/Organizer/Schedule";

/**
 * Configuration of the navigation tree and site routing, filtered by methods below
 * at the time of initial page load and login/logout.
 */

export const config = [
  {
    path: "/",
    render: () => <Dashboard />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/accounts*",
    render: () => <Accounts />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ] // this is here because of /accounts/assume and /accounts/renounce
  },
  {
    path: "/documents*",
    render: () => <Documents />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/expired-session",
    render: () => <ExpiredSession />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/dashboard",
    render: () => <Dashboard />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/information",
    render: () => <Information />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/community",
    render: () => <Community />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/schedule",
    render: () => <Schedule />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/events*",
    render: () => <EventsUser />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/competitions*",
    render: () => <CompetitionsUser />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/championships*",
    render: () => <ChampionshipUser />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/clubs*",
    render: () => <Clubs />,
    roles: [ roles.ANONYMOUS, roles.SITE_MEMBER ]
  },
  {
    path: "/admin/actions",
    render: () => <Actions />,
    roles: [ roles.SECURITY_OFFICER ]
  },
  {
    path: "/admin/events*",
    render: () => <EventsAdmin />,
    roles: [ roles.SECURITY_OFFICER, roles.BUSINESS_ADMIN ]
  },
  {
    path: "/admin/competitions*",
    render: () => <CompetitionsAdmin />,
    roles: [ roles.SECURITY_OFFICER, roles.BUSINESS_ADMIN ]
  },
  {
    path: "/organizer/competitions*",
    render: () => <CompetitionsOrganizer />,
    roles: [ roles.SECURITY_OFFICER, roles.EVENT_ORGANIZER ]
  },
  {
    path: "/admin/championships*",
    render: () => <ChampionshipsAdmin />,
    roles: [ roles.SECURITY_OFFICER, roles.BUSINESS_ADMIN ]
  },
  {
    path: "/admin/clubs*",
    render: () => <Clubs />,
    roles: [ roles.SECURITY_OFFICER, roles.BUSINESS_ADMIN ]
  },
  {
    path: "/admin/contents*",
    render: () => <Contents />,
    roles: [ roles.SECURITY_OFFICER, roles.BUSINESS_ADMIN ]
  },
  {
    path: "/admin/templates*",
    render: () => <Templates />,
    roles: [ roles.SECURITY_OFFICER, roles.BUSINESS_ADMIN ]
  },
  {
    path: "/admin/accounts*",
    render: () => <Accounts />,
    roles: [ roles.SECURITY_OFFICER ]
  },
];