import React, {useMemo, useRef} from "react";
import debug from "../debug.js"
import {ValidationError} from "yup";

/**
 *  Returned by useForm and is set as the value of FormContext.
 *
 *  @typedef {Object} FormContextData - Form data.
 *  @property {Record} recordType - Immutable record type.
 *  @property {schema} recordSchema - Yup schema for the record.
 *  @property {String} namespace - I18n namespace.
 *  @property {MutableRefObject} fieldValues - Field values.
 *  @property {Object.<string,string>} fieldValues.current - (name,value) pairs for each field
 *  @property {MutableRefObject} validationStatuses - Field validation statuses.
 *  @property {Object.<string.string>} validationStatuses.current - (name,status) pairs for each field. Status may be "default-invalid", "valid", "invalid".
 *  @property {MutableRefObject} validationCallbacks - Form validation callbacks.
 *  @property {Array.<function>} validationCallbacks.current - Callbacks to call when validation status of a field changes.
 *  @property {MutableRefObject} dependencyCallbacks -
 *  @property {Array.<function>} dependencyCallbacks.current -
 */

/**
 * Returns with a data and validation context to be provided to form elements.
 *
 *  @param {Object} options
 *  @param {Record} options.recordType - Immutable record type.
 *  @param {schema} options.recordSchema - Yup schema for the record.
 *  @param {String} options.namespace - I18n namespace.
 *  @param {Object} options.record - Data to initialize the form with, instance of recordType.
 *
 *  @returns FormContextData
 */
export default function useForm(options) {

  const {
    recordSchema,
    recordType,
    namespace,
    record
  } = options;

  // Objects fieldValues, validationStatuses and validationCallbacks are the same during the lifetime of the
  // component that uses the useForm hook. Validated* components change these, so the form actually
  // have access to up-to-date data. The trick is that changing these doesn't trigger a form state
  // change and the form itself won't be re-rendered. However it is important to notify the form
  // of validation changes, so Validated* components should call validation callbacks when their
  // validation state change. Components interested in validation changes should add a callback to
  // the validation callbacks array, check ValidatedSubmit for example.

  const fieldValues = useRef(null);

  const validationStatuses = useRef(null);

  const validationCallbacks = useRef([]);

  const dependencyCallbacks = useRef({});

  // Using memo, so re-rendering the parent component without any actual
  // form changes won'r re-render the whole form. However, in case
  // the underlying record changes the values have to be refreshed and
  // validation statuses recalculated.

  const formContextData = useMemo(() => {

    fieldValues.current = record.toObject();
    validationStatuses.current = initialValidation(recordSchema, fieldValues.current);

    return {
      recordSchema: recordSchema,
      recordType: recordType,
      namespace: namespace,
      fieldValues: fieldValues,
      validationStatuses: validationStatuses,
      validationCallbacks: validationCallbacks,
      dependencyCallbacks: dependencyCallbacks,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  debug("DATA", "useForm", () => [
    JSON.stringify(formContextData.fieldValues, null, "  "),
    JSON.stringify(formContextData.validationStatuses, null, "  ")
  ]);

  // noinspection JSValidateTypes
  return formContextData;
}

/**
 *  @type {React.Context<FormContextData>}
 */
export const FormContext = React.createContext(undefined);

/**
 * Performs the initial validation of the form data. Field statuses
 * will be set to "valid" or to "default-invalid".
 *
 * @param recordSchema
 * @param fieldValues
 */
function initialValidation(recordSchema, fieldValues) {
  const status = {};

  Object.keys(fieldValues).forEach(name => status[name] = "valid");

  try {

    recordSchema.validateSync(fieldValues, {abortEarly: false});

  } catch (validationErrors) {
    if (!(validationErrors instanceof ValidationError)) throw validationErrors;

    validationErrors.inner.forEach(error => {
      status[error.path] = "default-invalid";
    });
  }

  debug("VAL..ON", "useForm", () => [
      JSON.stringify(fieldValues, null, "  "),
      JSON.stringify(status, null, "  ")
  ]);

  return status;
}