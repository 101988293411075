import React, {memo, useContext} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {assume} from "../../Api/session-migration";

import recordType from "../../Lib/auth/Records/AuthenticationData";
import recordSchema from "../../Lib/auth/Schemas/AuthenticationData";
import {AppContext} from "../../Main/AppContext";
import Actions from "../../Lib/auth/AuthActions";
import {useSnackbar} from "notistack";
import {A, navigate, useQueryParams} from "hookrouter";
import Grid from "@material-ui/core/Grid";

import accountStyle from "./accountStyle";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {makeStyles} from "@material-ui/styles";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import {closeSnack} from "../../Main/util";
import ContentBox from "../../Components/ContentBox/ContentBox";

const useStyles = makeStyles(accountStyle);

const Login = memo(() => {

  // stuff specific for login  ----------------------------------

  const appContext = useContext(AppContext);
  const dispatch = appContext.get('dispatch');

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const {target = "/dashboard"} = useQueryParams()[0];

  const onSuccess = (sessionData) => {
    dispatch(Actions.sessionAdd(sessionData));
    navigate(target);
  };

  const onError = (error) => {
    let message;
    switch (error.status) {
      case 404:
        message = t('error404');
        break;
      case 406:
        message = t('error406');
        break;
      case 412:
        message = t('error412');
        break;
      default:
        message = t('error');
    }
    enqueueSnackbar(message, {variant: 'error', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});
  };

  // usual form stuff  ----------------------------------

  const {t} = useTranslation("account");

  // noinspection JSPotentiallyInvalidConstructorUsage
  const form = useForm({
    recordType: recordType,
    recordSchema: recordSchema,
    namespace: 'account',
    record: new recordType(recordSchema.default())
  });

  const classes = useStyles();

  // noinspection HtmlUnknownTarget
  return (
      <ContentBox>
        <div className={classes.panel}>
          <Card className={classes.container}>
            <CardBody>
              <Grid container justify="center">

                <Grid item xs={12}>
                  <div className={classes.title}>{t('login')}</div>
                </Grid>

                <Grid item xs={12}>
                  <FormProvider form={form} formClassName={classes.form}>
                    <ValidatedInput name="principalName"/>

                    <ValidatedInput name="password" password/>

                    <Grid item container justify="space-between">
                      <Grid item style={{marginTop: 40}}>
                        <ValidatedSubmit
                            api={assume}
                            hideCancel
                            submitLabel={t('login')}
                            onSuccess={(sessionData) => onSuccess(sessionData)}
                            onError={(error) => onError(error)}
                        />
                      </Grid>
                      <Grid item style={{marginTop: 40}}>
                        <A href="/accounts/reset">
                          <Button color="info">
                            {t('forgottenPassword')}
                          </Button>
                        </A>
                      </Grid>
                    </Grid>
                  </FormProvider>
                </Grid>

              </Grid>
            </CardBody>
          </Card>
        </div>
      </ContentBox>
  );
});

export default Login;