import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../../Lib/form/useForm";
import {EventGroupRecord, EventGroupSchema} from "../../../../Data/Event";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedDateTime from "../../../../Lib/form/Components/ValidatedDateTime/ValidatedDateTime";
import ValidatedRichTextEditor from "../../../../Lib/form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import ValidatedSubmit from "../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedCheckbox from "../../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import ValidatedCheckboxGroup from "../../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckboxGroup";
import {ageGroups} from "../../../../Data/agegroups"
import Grid from "@material-ui/core/Grid";

export default ({eventId, id, record, path, view, api}) => {

  const {t} = useTranslation('event');

  const form = useForm({
    recordType: EventGroupRecord,
    recordSchema: EventGroupSchema,
    namespace: 'event',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        <Section title={t('group')}>
          <ValidatedInput name="name"/>
          <ValidatedInput name="position"/>
          <ValidatedDateTime name="day" time={false}/>
          <ValidatedInput name="startTime"/> {/* FIXME time input */}
          <ValidatedInput name="endTime"/> {/* FIXME time input */}
          <ValidatedCheckboxGroup name="ageGroups">
            <Grid container>
              {ageGroups.map(a => <ValidatedCheckbox name="ageGroups" value={a} key={a} multi/>)}
            </Grid>
          </ValidatedCheckboxGroup>
          <ValidatedInput name="participantLimit"/>
          <ValidatedRichTextEditor name="notes"/>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
};