import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import {CompetitionRecord, CompetitionSchema} from "../../../Data/Competition";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {Navigation} from "./Navigation";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedCheckbox from "../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import ValidatedCheckboxGroup from "../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckboxGroup";
import Grid from "@material-ui/core/Grid";
import {useEventsShort} from "../../../Api/events";
import ValidatedSelect from "../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";

export default ({id, record, path, view, api}) => {

  const {t} = useTranslation('competition');

  const form = useForm({
    recordType: CompetitionRecord,
    recordSchema: CompetitionSchema,
    namespace: 'competition',
    record: record
  });

  const {loadingStatus, data} = useEventsShort();

  const events = () => ({loadingStatus, data: data.map(r => ({ id: r.get('id'), name: r.get('label') })) });

  return (
      <ContentBox path={path} form={form}>

        {view === "add" ? null : <Navigation id={id}/>}

        <Section title={t('competition')}>
          <ValidatedInput name="name"/>
          <ValidatedInput name="mnemonic"/>
          <ValidatedSelect name="event" api={events}/>
          <ValidatedRadioGroup name="status">
            <ValidatedRadio name="status" value="PREPARATION"/>
            <ValidatedRadio name="status" value="ACTIVE"/>
            <ValidatedRadio name="status" value="CLOSED"/>
          </ValidatedRadioGroup>
          <ValidatedCheckboxGroup name="climbingTypes">
            <Grid container>
              <ValidatedCheckbox name="climbingTypes" value="BOULDER" multi/>
              <ValidatedCheckbox name="climbingTypes" value="LEAD" multi/>
              <ValidatedCheckbox name="climbingTypes" value="SPEED" multi/>
            </Grid>
          </ValidatedCheckboxGroup>
          <ValidatedInput name="year"/>
          <ValidatedInput name="notes" multiline/>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
}