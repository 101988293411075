import React, {memo, useRef} from 'react';
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import baseFetch from "../../../baseFetch";
import {useFormField} from "../../useFormField";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

/**
 * apiPath = /images
 */

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const ValidatedFileUploader = memo((props) => {

    const {name, apiPath, multiple, acceptedFileTypes, sizeValidation, maxFileSize, maxTotalFileSize} = props;

    const ff = useFormField(props);

    const ref = useRef(null);

    let fileIds = ff.value ? (Array.isArray(ff.value) ? ff.value : [ff.value]) : [];

    const serverConfig = {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

            const request = new XMLHttpRequest();
            request.open('POST', '/api/1.0.0' + apiPath);

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    // console.log(JSON.stringify(request.response));
                    if (multiple) {
                        fileIds.push(parseInt(request.response));
                    } else {
                        fileIds = [parseInt(request.response)]
                    }

                    ff.onChange(multiple ? fileIds : fileIds[0]);
                    load(request.responseText);
                } else {
                    if (request.status === 409 || request.status === 410 || request.status === 417) {
                        window.location = "/expired-session";
                    }
                    error();
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                }
            };
        },
        revert: (uniqueFileId, load, error) => {
            // don't
            baseFetch('DELETE', apiPath + "/" + uniqueFileId, undefined, () => load());

            uniqueFileId = parseInt(uniqueFileId);

            let filtered = fileIds.filter(id => id !== uniqueFileId);

            multiple && filtered.length ? ff.onChange(filtered) : ff.onChange(null)
        }
    };

    return (
        <FilePond name="file"
                  ref={ref}
                  allowMultiple={multiple}

                  server={serverConfig}

                  //allowFileTypeValidation={true}
                  //acceptedFileTypes={acceptedFileTypes ? acceptedFileTypes : "*"}

                  allowFileSizeValidation={sizeValidation}
                  maxFileSize={maxFileSize || null}
                  maxTotalFileSize={maxTotalFileSize || null}
        />
    );
});

export default ValidatedFileUploader;