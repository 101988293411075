import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";

import accountStyle from "./accountStyle";
import {makeStyles} from "@material-ui/styles";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import {ResetRecord, ResetSchema} from "../../Data/account";
import {enableReset} from "../../Api/accounts";
import Section from "../../Lib/form/Components/Section/Section";
import ContentBox from "../../Components/ContentBox/ContentBox";
import ValidatedDate from "../../Lib/form/Components/ValidatedDateTime/ValidatedDate";
import {closeSnack} from "../../Main/util";

const useStyles = makeStyles(accountStyle);

const ResetStart = () => {

  const [success, setSuccess] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const onSuccess = () => {
    setSuccess(true);
  };

  const onError = () => {
    enqueueSnackbar(
        t('reset-start-error'),
        {variant: 'error', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});

  };

  // usual form stuff  ----------------------------------

  const {t} = useTranslation("account");

  // noinspection JSPotentiallyInvalidConstructorUsage
  const form = useForm({
    recordType: ResetRecord,
    recordSchema: ResetSchema,
    namespace: 'account',
    record: new ResetRecord(ResetSchema.default())
  });

  const classes = useStyles();

  if (success) {
    return (
        <ContentBox title={t("reset-start-success")}>
          <Section title={t("reset-start-success")}>
            <br/>
            {t('reset-start-success-explanation')}
          </Section>
        </ContentBox>
    )
  }

  return (
      <ContentBox>
        <div className={classes.panel}>
          <Card className={classes.container}>
            <CardBody>
              <Grid container justify="center">

                <Grid item xs={12}>
                  <div className={classes.title}>{t('reset-start-title')}</div>
                  <p>{t('reset-start-explanation')}</p>
                </Grid>

                <Grid item xs={12}>
                  <FormProvider form={form} formClassName={classes.form}>
                    <ValidatedInput name="principalName" autocomplete="username"/>

                    <ValidatedDate name="birthDate"/>

                    <Grid item container justify="flex-end">
                      <Grid item style={{marginTop: 40}}>
                        <ValidatedSubmit
                            api={enableReset}
                            hideCancel
                            submitLabel={t('reset-start-button')}
                            onSuccess={onSuccess}
                            onError={onError}
                        />
                      </Grid>
                    </Grid>
                  </FormProvider>
                </Grid>

              </Grid>
            </CardBody>
          </Card>
        </div>
      </ContentBox>
  );
};

export default ResetStart;