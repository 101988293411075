import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../../../Lib/form/useForm";
import {SectionRouteRecord, SectionRouteSchema} from "../../../../../Data/Competition";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import Section from "../../../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRadioGroup from "../../../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import {Typography} from "@material-ui/core";

export default ({record, path, view, api}) => {

  const {t} = useTranslation('competition');

  const form = useForm({
    recordType: SectionRouteRecord,
    recordSchema: SectionRouteSchema,
    namespace: 'competition',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

        <Section title={t('route')}>
          <ValidatedInput name="position"/>
          <ValidatedInput name="displayPosition"/>
          <ValidatedRadioGroup name="type">
            <ValidatedRadio name="type" value="OPTIONAL"/>
            <ValidatedRadio name="type" value="QUALIFIER"/>
            <ValidatedRadio name="type" value="NORMAL"/>
          </ValidatedRadioGroup>
          <ValidatedRadioGroup name="gender">
            <ValidatedRadio name="gender" value="BOTH"/>
            <ValidatedRadio name="gender" value="FEMALE"/>
            <ValidatedRadio name="gender" value="MALE"/>
          </ValidatedRadioGroup>
          <ValidatedInput name="label"/>
          <ValidatedInput name="color"/>
          <ValidatedInput name="maxHoldNumber"/>
          <ValidatedInput name="options"/>
          <div><Typography color="textSecondary"><small>{t('route-options-help')}</small></Typography></div>
        </Section>

        <ValidatedSubmit view={view} api={api}/>

      </ContentBox>
  );
};