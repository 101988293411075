import React, {useCallback, useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../../../material-dashboard/components/CustomButtons/Button";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {FormContext} from "../../useForm";


function _label(view, t, submitLabel) {
  if (submitLabel) return [submitLabel, "primary"];

  if (!view) return [t('save'), "primary"];

  switch (view) {
    case "add" :
      return [t('add'), "success"];
    case "edit" :
      return [t('save'), "success"];
    case "remove" :
      return [t('delete'), "danger"];
    default :
      return [t('save'), "primary"];
  }
}

function ValidatedSubmit(props) {
  const {view, api, hideCancel, submitLabel, onSuccess, onError, validationInfo, disabled} = props;

  const {
    recordType,
    fieldValues,
    namespace,
    validationStatuses,
    validationCallbacks
  } = useContext(FormContext);

  const t = useTranslation(namespace).t;

  const {enqueueSnackbar} = useSnackbar();

  const [label, color] = _label(view, t, submitLabel);

  const [state, setState] = useState({
    busy: false,
    revision: 0
  });

  const doSubmit = () => {
    api(
        new recordType(fieldValues.current),
        (response) => {
          setState({...state, busy: false});
          if (onSuccess) {
            onSuccess(response);
          } else {
            enqueueSnackbar(t('general:save.success'), {variant: 'success'});
          }
        },
        (error, skipSnack) => {
          setState({...state, busy: false});
          if (onError) {
            onError(error);
          } else {
            if ( ! skipSnack) enqueueSnackbar(t('general:save.error'), {variant: 'error'});
          }
        }
    );
    setState({...state, busy: true});
  };

  const callback = useCallback(() => {
    setState((old) => ({...old, revision: old.revision + 1}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationStatuses]);

  useEffect(() => {
    let cba = validationCallbacks.current;

    if (cba.indexOf(callback) === -1) {
      cba.push(callback);
    }
    return () => {
      let index = cba.indexOf(callback);
      if (index !== -1) cba.splice(index, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  const recordStatus = Object.values(validationStatuses.current).find(s => s === "invalid" || s === "default-invalid");

  let _disabled = (state.busy || (recordStatus !== undefined)) || disabled;

  const VI = validationInfo; // to have component class name capitalized

  return (
      <Grid container spacing={2}>
        {!validationInfo ? null :
            <Grid item xs={12}>
              <VI statuses={validationStatuses.current}/>
            </Grid>
        }
        {hideCancel ? null :
            <Grid item>
              <Button
                  color={"primary"}
                  onClick={() => window.history.back()}
              >
                {t('back')}
              </Button>
            </Grid>
        }
        <Grid item>
          <Button
              disabled={_disabled}
              color={color}
              onClick={doSubmit}
          >
            {label}
          </Button>
        </Grid>
      </Grid>
  );
}

export default ValidatedSubmit;