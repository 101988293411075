import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {TemplateRecord, TemplateSchema} from '../../Data/Template';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRichTextEditor from "../../Lib/form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";

export default memo(({id, record, path, view, api}) => {

  const {t} = useTranslation('template');

  const form = useForm({
    recordType: TemplateRecord,
    recordSchema: TemplateSchema,
    namespace: 'template',
    record: record
  });

  return (
      <ContentBox path={path} form={form}>

          <Grid container  direction="row" justify="center">
              <Grid item xs={8}>
                <Section title={t(id ? 'edit' : 'add')}>
                  <ValidatedInput name="name"/>
                  <ValidatedInput name="language"/>
                  <ValidatedInput name="title"/>
                  <ValidatedRichTextEditor name="content"/>
                </Section>
              </Grid>

          <Grid item xs={8}>
            <ValidatedSubmit view={view} api={api}/>
          </Grid>
        </Grid>
      </ContentBox>
  );
})