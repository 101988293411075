import React, {useEffect, useState} from "react";
import {activateAccount} from "../../Api/accounts";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import accountStyle from "./accountStyle";
import {LoginButton} from "./Accounts";

const useStyles = makeStyles(accountStyle);

function Activate({id, ak}) {
  const [activated, setActivated] = useState(false);
  const [error, setError] = useState(false);

  const t = useTranslation("account").t;

  const classes = useStyles();

  useEffect(() =>
    activateAccount(id, ak,
        () => setActivated(true),
        () => setError(true)
    ), [id, ak]);

  let content = <div>{t('activate-in-progress')}</div>;
  if (error) content = <div>{t('activate-error')}</div>;

  if (activated) content = <div>{t('activate-success')} <LoginButton t={t} classes={classes}/></div>;


  return (
    <ContentBox title={t("activate-title")}>
      <Section title={t("activate-title")}>
        <br/>
        {content}
      </Section>
    </ContentBox>
  );
}

export default Activate;