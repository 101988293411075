import React from "react";
// @material-ui/core components
import CustomLinearProgress from "../../../Lib/material-kit/components/CustomLinearProgress/CustomLinearProgress";
import Moment from "react-moment";
import {A} from "hookrouter";

const color = (ag) => {
  if (!ag) return "gray";
  if (ag.indexOf("MINI") !== -1) return "info";
  if (ag.indexOf("CHILD") !== -1) return "info";
  if (ag.indexOf("TEEN_AGE") !== -1) return "info";
  return "rose";
};

export default ({record, classes}) => {

  const groups = record.get('groups');

  if (groups.length === 0) groups.push({participantCount : 0, participantLimit: 1});

  const width = groups.length === 1 ? "100%" : (100/groups.length - 4).toString() + "%";

  return (
      <React.Fragment>
        <tr>
          <td valign="bottom"><A href={'/events/' + record.id} className={classes.label}>{record.get('label')}</A></td>
          <td align="right">
                <span key={record.get('startDate')} >
                  <Moment date={record.get('startDate')} format="YYYY-MM-DD"/><br/>
                </span>
            </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            {
              groups.map((group,idx) =>
                  <CustomLinearProgress
                      key={idx}
                      variant="determinate"
                      color={color(group.ageGroups)}
                      value={100 * group.participantCount / group.participantLimit}
                      style={{width: width}}
                  />
              )
            }
            </div>
          </td>
        </tr>
      </React.Fragment>
  );
}