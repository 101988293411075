import debug from "../../../Lib/debug";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Card from "../../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../../Lib/material-dashboard/components/Card/CardBody";
import Box from "@material-ui/core/Box";
import {A, useRoutes} from "hookrouter";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import {useChampionships} from "../../../Api/championships";
import Button from "../../../Lib/material-dashboard/components/CustomButtons/Button";
import {successColor} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";
import Details from "./Details";

const useStyles = makeStyles({
      card: {
        margin: "8px 0",
        paddingBottom: "8px"
      },
      title: {
        marginBottom: 20,
        marginTop: 10,
        color: successColor[4],
        // fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500
      },
      button: {
        padding: "8px 16px",
        backgroundColor: successColor[0],
      },
      linkContainer: {
        paddingTop: "4px",
        paddingBottom: "4px"
      },
      link: {
        color: "#396593"
      }
    }
);

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/" : () => <Overview/>,
  "/:id/details": ({id}) => <Details id={id}/>
};

const Overview = () => {

  debug("RENDER", "Championships.Overview", () => []);

  const {t} = useTranslation('championships');
  const classes = useStyles();

  const {loadingStatus, data} = useChampionships();

  const path = [{label: t('results'), url: "/championships"}];

  return (
      <ContentBox path={path} loadingStatus={loadingStatus}>

        <Grid container spacing={2}>
          {data.map(csr =>
              <Grid item xs={12} md={6} key={csr.id}>
                <Card className={classes.card}>
                  <CardBody>
                    <Box className={classes.title}>{csr.name} - {csr.year} - {t(csr.climbingType)}</Box>
                    <A href={"/championships/" + csr.id + "/details"}>
                      <Button size="sm" color={"success"}>
                        {t(csr.status === 'ACTIVE' ? 'championship-standings' : 'championship-results')}
                      </Button>
                    </A>
                    <div>&nbsp;</div>
                    {csr.competitions.map(r => <div className={classes.linkContainer} key={r.id}><A className={classes.link} href={"/competitions/" + r.id + "/details"}>{r.name}</A></div>)}
                  </CardBody>
                </Card>
              </Grid>
          )}
        </Grid>

      </ContentBox>
  )

};