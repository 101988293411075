import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addSection, removeSection, updateSection, useSection} from "../../../../Api/competitions";
import debug from "../../../../Lib/debug";
import Form from "./Form";
import {SectionRecord, SectionSchema} from "../../../../Data/Competition";
import {useTranslation} from "react-i18next";
import Participations from "./Participations/Participations";
import Routes from "./Routes/Routes";
import Results from "./Results/Results";

export default (props) => useRoutes(subRoutes)(props)

const subRoutes = {
  "/": () => (props) => <Sections view="list" {...props}/>,
  "/add": () => (props) => <Sections view="add" {...props}/>,
  "/:id/edit": ({id}) => (props) => <Sections view="edit" {...props} id={id}/>,
  "/:id/remove": ({id}) => (props) => <Sections view="remove" {...props} id={id}/>,
  "/:id/routes*": ({id}) => (props) => <Sections view="routes" {...props} id={id}/>,
  "/:id/participations*": ({id}) => (props) => <Sections view="participations" {...props} id={id}/>,
  "/:id/results*": ({id}) => (props) => <Sections view="results" {...props} id={id}/>
};

const Sections = ({view, id, competitionId, path}) => {

  debug("RENDER", "Competitions.Sections", () => []);

  const {loadingStatus, data} = useSection(competitionId, id);

  const prefix = "/admin/competitions/" + competitionId + "/sections/" + id;

  const viewData = (loadingStatus ? new SectionRecord(SectionSchema.default()) : data);

  const {t} = useTranslation('competition');

  const label = (view === 'add' ? t('add-section') : viewData.get('name'));

  const np = path.slice(0); // to avoid modifying parent path

  switch (view) {
    case "list" :

      return <List competitionId={competitionId} path={np}/>;

    case "add" :

      np.push({label: label, url: prefix + "/add"});
      return <Form competitionId={competitionId} id={id} record={viewData.set('competition', competitionId)} path={np} view={view} api={addSection}/>;

    case "remove" :

      np.push({label: label, url: prefix + id + "/remove"});
      return <Form competitionId={competitionId} id={id} record={viewData} path={np} view={view} api={removeSection}/>;

    case "edit" :

      np.push({label: label, url: prefix + id + "/edit"});
      return <Form competitionId={competitionId} id={id} record={viewData} path={np} view={view} api={updateSection}/>;

    case "routes" :

      np.push({label: label, url: prefix + "/edit"});
      np.push({label: t('routes'), url: prefix + "/routes"});
      return <Routes competitionId={competitionId} sectionId={id} sectionData={viewData} path={np}/>;

    case "participations" :

      np.push({label: label, url: prefix + "/edit"});
      np.push({label: t('participations'), url: prefix + "/participations"});
      return <Participations competitionId={competitionId} sectionId={id} sectionData={viewData} path={np}/>;

    case "results" :

      np.push({label: label, url: prefix + "/edit"});
      np.push({label: t('results'), url: prefix + "/results"});
      return <Results competitionId={competitionId} sectionId={id} sectionData={viewData} path={np}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};