export const types = {
  AUTH_SESSION_ADD: 'AUTH_SESSION_ADD',
  AUTH_SESSION_REMOVE: 'AUTH_SESSION_REMOVE'
};

export default {

  sessionAdd: (session) => {
    return {
      type: types.AUTH_SESSION_ADD,
      session: session
    }
  },

  sessionRemove: () => {
    return {
      type: types.AUTH_SESSION_REMOVE
    }
  }

}