import React from "react";
import {makeStyles} from "@material-ui/styles";
import {grayColor} from "../../material-dashboard/assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles({
  input: {
    padding: 4,
    margin: 0,
    border: 0,
    backgroundColor: grayColor[13],
    color: grayColor[14],
    fontSize: "14px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid " + grayColor[2]
    }
  }
});

const onKeyPress = (e) => {
  if (e.key === 'Enter') e.target.blur();
};

export default (props) => {

  const {
    setField,
    updateApi,
    cell: {value: initialValue},
    row: {original: record, index},
    column: {id: fieldName}
  } = props;

  const classes = useStyles();

  const iv = initialValue == null ? '' : initialValue;

  const [value, setValue] = React.useState(iv);

  const onChange = e => setValue(e.target.value);

  const onBlur = () => {
    if (value === initialValue) return; // do not send updates for the same data
    updateApi(record.set(fieldName, value), () => {
      setValue(value);
      setField(index, fieldName, value);
    })
  };

  React.useEffect(() => {
    setValue(iv)
  }, [iv]);

  return <input value={value} onChange={onChange} onBlur={onBlur} onKeyPress={onKeyPress} className={classes.input}/>
};