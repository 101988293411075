import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addClimb, removeClimb, updateClimb, useClimb} from "../../../../Api/competitions";
import debug from "../../../../Lib/debug";
import Form from "./Form";
import {ClimbRecord, ClimbSchema} from "../../../../Data/Competition";
import {useTranslation} from "react-i18next";

export default (props) => useRoutes(subRoutes)(props)

const subRoutes = {
  "/": () => (props) => <Climbs view="list" {...props}/>,
  "/add": () => (props) => <Climbs view="add" {...props}/>,
  "/:id/edit": ({id}) => (props) => <Climbs view="edit" {...props} id={id}/>,
  "/:id/remove": ({id}) => (props) => <Climbs view="remove" {...props} id={id}/>
};


const Climbs = ({view, id, competitionId, path}) => {

  debug("RENDER", "Competitions.Climbs", () => []);

  const {loadingStatus, data} = useClimb(competitionId, id);

  const prefix = "/admin/competitions/" + competitionId + "/climbs/";

  const viewData = (loadingStatus ? new ClimbRecord(ClimbSchema.default()) : data);

  const {t} = useTranslation('competition');

  const label = (view === 'add' ? t('add-climb') : viewData.get('name'));

  const np = path.slice(0); // to avoid modifying parent path

  switch (view) {
    case "list" :

      return <List competitionId={competitionId} path={np}/>;

    case "add" :

      np.push({label: label, url: prefix + "add"});
      return <Form competitionId={competitionId} id={id} record={viewData.set('competition', competitionId)} path={np} view={view} api={addClimb}/>;

    case "remove" :

      np.push({label: label, url: prefix + id + "/remove"});
      return <Form competitionId={competitionId} id={id} record={viewData} path={np} view={view} api={removeClimb}/>;

    case "edit" :

      np.push({label: label, url: prefix + id + "/edit"});
      return <Form competitionId={competitionId} id={id} record={viewData} path={np} view={view} api={updateClimb}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};