import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {updateMedicalExpiration, updateTags, useParticipations, useSections} from "../../../../Api/competitions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {grayColor, successColor} from "../../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";
import moment from "moment";

const useStyles = makeStyles({
  classification: {
    cursor: "pointer",
    padding: "0.5em 1em",
  },
  selected: {
    backgroundColor: "lightgreen",
    borderRadius: 4
  },
  left05em: {
    paddingLeft: "0.5em"
  },
  textField: {
    margin: "1em",
    padding: "0.2em",
    fontSize: 14
  },
  container: {
    padding: "1em"
  },
  startNumber: {
    width: "3em",
    minWidth: "3em",
    textAlign: "center"
  },
  list: {
    border: 0,
    borderCollapse: "collapse",
    cellSpacing: 0,
    cellPadding: 0,
    "& th": {
      padding: "4px 8px 4px 8px",
      backgroundColor: successColor[5],
      color: grayColor[2],
      fontSize: "0.75rem",
      fontWeight: 400
    },
    "& tr": {
      //           // "&:nth-child(odd)": {
      //           //   backgroundColor: grayColor[13]
      //           // }
      borderBottom: "1px solid " + grayColor[10]
    },
    "& td": {
      padding: "4px 8px 4px 8px",
    }
  },
  saving: {
    color: successColor[4],
    fontWeight: 500
  }
});

const Registration = ({id}) => {

      const classes = useStyles();

      const {data, setData} = useParticipations(id);
      const {data: sections} = useSections(id);
      const [filter, setFilter] = useState("");

      const updateTableTags = (record, tags) => {
        let nr = record.set('tags', tags).set('registered', true);
        const nd = data.map(r => r.id === record.id ? nr : r);
        setData(nd);
      };

      const updateMedical = (record, medical) => {
        let nr = record.set('medicalExpiration', medical);
        const nd = data.map(r => r.id === record.id ? nr : r);
        setData(nd);
      };

      return (
          <div className={classes.container}>
            <table className={classes.list}>
              <thead>
              <tr>
                <th>#</th>
                <th className={classes.name}>
                  <input type="text" name="filter" value={filter} className={classes.textField} onChange={(e) => setFilter(e.target.value)}/>
                </th>
                <th>Kategória</th>
                <th>Sportorvosi</th>
                <th>Csoport</th>
                <th>Korcsoport</th>
              </tr>
              </thead>
              <tbody>
              {data.map(record => {
                if (filter !== "" && record.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) === -1) return null;
                return <Row key={record.id} record={record} sections={sections} updateTableTags={updateTableTags} updateMedical={updateMedical}/>;
              })}
              </tbody>
            </table>
          </div>
      );
    }
;

// {"id":976,"competition":4,"principal":308,"name":"Abonyi Gabor","sections":[47],"startNumber":1,"ageGroup":"ADULT","medicalExpiration":null,"medicalValid":false,"registered":false,"tags":"amateur"}
const Row = ({record, sections, updateTableTags, updateMedical}) => {

  const classes = useStyles();
  const {t} = useTranslation('competition');

  return (
      <tr>
        <td className={classes.startNumber}>{record.startNumber}</td>
        <td className={classes.name}>{record.name}</td>
        <td><Tag record={record} updateTableTags={updateTableTags}/></td>
        <td><Medical record={record} updateMedical={updateMedical}/></td>
        <td className={classes.group}><Section sectionList={sections} sections={record.sections}/></td>
        <td className={classes.ageGroup}>{t(record.ageGroup)}</td>
      </tr>
  )
};

const Tag = ({record, updateTableTags}) => {
  const amateur = record.tags.indexOf("amateur") !== -1;
  const professional = record.registered && record.tags.indexOf("amateur") === -1;

  const classes = useStyles();

  const [saving, setSaving] = useState(false);

  const update = (tags) => {
    if (record.tags === tags && record.registered) return;

    let nr = record.set('tags', tags).set('registered', true);

    setSaving(true);

    updateTags(nr, () => {
      updateTableTags(record, tags);
      setSaving(false);
    });
  };

  if (saving) {
    return <span className={classes.saving}>mentés...</span>;
  } else {
    return (
        <div style={{display: "flex"}}>
          <div onClick={() => update("amateur")}
               className={classes.classification + " " + (amateur ? classes.selected : "")}>
            <input
                type="radio"
                name={"tags." + record.id}
                value="amateur"
                checked={amateur}
            />
            <span className={classes.left05em}>amatőr</span>
          </div>
          <div onClick={() => update("")}
               className={classes.classification + " " + (professional ? classes.selected : "")}>
            <input
                type="radio"
                name={"tags." + record.id}
                value=""
                checked={professional}
            />
            <span className={classes.left05em}>profi</span>
          </div>
        </div>
    );
  }
};

const Medical = ({record, updateMedical}) => {
  const classes = useStyles();

  const [value, setValue] = useState(record.medicalExpiration || '');
  const [saving, setSaving] = useState(false);

  if (saving) {
    return <span className={classes.saving}>mentés...</span>;
  } else {
    return <div>
      <input type="text" name={"medical" + record.id} value={value} onChange={
        (e) => {
          const newValue = e.target.value;
          setValue(newValue);
          if (newValue === record.medicalExpiration) return;

          let nr;
          if (newValue.length === 10) {
            if (!moment(newValue, "YYYY-MM-DD", true).isValid()) return;
            nr = record.set('medicalExpiration', newValue);
          } else if (newValue.length === 0) {
            nr = record.set('medicalExpiration', null);
          } else {
            return;
          }

          setSaving(true);

          updateMedicalExpiration(nr, () => {
            updateMedical(record, newValue);
            setSaving(false);
          });
        }
      }/>
    </div>;
  }
};

const Section = ({sectionList, sections}) => {

  let sectionNames = [];

  sections.forEach(sectionId => {
    const sectionRecords = sectionList.filter(sr => sr.id === sectionId);
    sectionNames.push(sectionRecords.length === 0 ? sectionId : sectionRecords[0].name);
  });

  return sectionNames.join(', ');
};

export default Registration;