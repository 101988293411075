import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addEvent, removeEvent, updateEvent, useEvent} from "../../../Api/events";
import {useTranslation} from "react-i18next";
import Groups from "./Groups/Groups";
import debug from "../../../Lib/debug";
import Form from "./Form";
import {EventRecord, EventSchema} from "../../../Data/Event.js";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Events view="list"/>,
  "/add": () => <Events view="add"/>,
  "/:id/edit": ({id}) => <Events view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Events view="remove" id={id}/>,
  "/:id/groups*": ({id}) => <Events view="groups" id={id}/>,
  "/:id/registrations*": ({id}) => <Events view="registrations" id={id} />,
};

const Events = ({view, id}) => {

  debug("RENDER", "Events.Admin", () => [view]);

  const {t} = useTranslation('event');

  const {loadingStatus, data} = useEvent(id);

  const viewData = (loadingStatus ? new EventRecord(EventSchema.default()) : data);

  const path = [{label: t('events'), url: "/admin/events"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('label');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/admin/events/add"});
      return <Form id={id} record={viewData} path={path} view={view} api={addEvent}/>;

    case "remove" :

      path.push({label: label, url: "/admin/events/" + id + "/remove"});
      return <Form id={id} record={viewData} path={path} view={view} api={removeEvent}/>;

    case "edit" :

      path.push({label: label, url: "/admin/events/" + id + "/edit"});
      return <Form id={id} record={viewData} path={path} view={view} api={updateEvent}/>;

    case "groups" :

      path.push({label: label, url: "/admin/events/" + id + "/edit"});
      path.push({label: t('groups'), url: "/admin/events/" + id + "/groups"});
      return <Groups eventId={id} eventData={viewData} path={path}/>;

    case "registrations" :

      path.push({label: label, url: "/admin/events/" + id + "/edit"});
      path.push({label: t('registrations'), url: "/admin/events/" + id + "/registrations"});
      return <Groups eventId={id} eventData={viewData} path={path}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};

