export default {
  hu : {
    account: "fiók",
    documents: "nyomtatványok",
    logout: "kilépés",
    clubs: "klubok",
    events: "események",
    competitions: "versenyek",
    championships: "bajnokságok",
    templates: "minták",
    contents: "tartalmak",
    actions: "műveletek",
    registration: "regisztráció"
  },
  en : {
    account : "account",
    documents: "documents",
    logout: "logout",
    clubs: "clubs",
    events: "events",
    competitions: "competitions",
    championships: "championships",
    templates: "templates",
    contents: "contents",
    actions: "actions",
    registration: "registration"
  }
}