import React from "react";
import {A} from "hookrouter";
import Button from "../material-dashboard/components/CustomButtons/Button";
import {useTranslation} from "react-i18next";

const Download = ({url}) => {
    const t = useTranslation("general").t;

    return (
        <div>
            <a href={url}>
                <Button color={"success"} size={"sm"}>{t('open')}</Button>
            </a>&nbsp;
        </div>
    );
};

export default Download;