import React from "react";
import Moment from "react-moment";
import {Button, TextField, withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const DEF_ROUND_LENGTH = 6 * 60;

const initial = {
    now: new Date(),
    active: false,

    management: false,

    rounds: [
        ["1","","","",""],
        ["2","","","",""],
        ["3","","","",""],
        ["4","1","","",""],
        ["5","2","","",""],
        ["6","3","","",""],
        ["7","4","1","",""],
        ["8","5","2","",""],
        ["9","6","3","",""],
        ["10","7","4","1",""],
        ["11","8","5","2",""],
        ["12","9","6","3",""],
        ["13","10","7","4","1"],
        ["14","11","8","5","2"],
        ["15","12","9","6","3"],
        ["16","13","10","7","4"],
        ["17","14","11","8","5"],
        ["18","15","12","9","6"],
        ["19","16","13","10","7"],
        ["20","17","14","11","8"],
        ["21","18","15","12","9"],
        ["22","19","16","13","10"],
        ["23","20","17","14","11"],
        ["24","21","18","15","12"],
        ["25","22","19","16","13"],
        ["26","23","20","17","14"],
        ["27","24","21","18","15"],
        ["28","25","22","19","16"],
        ["29","26","23","20","17"],
        ["30","27","24","21","18"],
        ["31","28","25","22","19"],
        ["32","29","26","23","20"],
        ["33","30","27","24","21"],
        ["34","31","28","25","22"],
        ["35","32","29","26","23"],
        ["","33","30","27","24"],
        ["","34","31","28","25"],
        ["","35","32","29","26"],
        ["","","33","30","27"],
        ["","","34","31","28"],
        ["","","35","32","29"],
        ["","","","33","30"],
        ["","","","34","31"],
        ["","","","35","32"],
        ["","","","","33"],
        ["","","","","34"],
        ["","","","","35"],
        ["","","","",""],
        ["36","","","",""],
        ["37","","","",""],
        ["38","","","",""],
        ["39","36","","",""],
        ["40","37","","",""],
        ["41","38","","",""],
        ["42","39","36","",""],
        ["43","40","37","",""],
        ["44","41","38","",""],
        ["45","42","39","36",""],
        ["46","43","40","37",""],
        ["47","44","41","38",""],
        ["48","45","42","39","36"],
        ["49","46","43","40","37"],
        ["50","47","44","41","38"],
        ["51","48","45","42","39"],
        ["52","49","46","43","40"],
        ["53","50","47","44","41"],
        ["54","51","48","45","42"],
        ["55","52","49","46","43"],
        ["56","53","50","47","44"],
        ["57","54","51","48","45"],
        ["58","55","52","49","46"],
        ["59","56","53","50","47"],
        ["60","57","54","51","48"],
        ["61","58","55","52","49"],
        ["62","59","56","53","50"],
        ["63","60","57","54","51"],
        ["64","61","58","55","52"],
        ["65","62","59","56","53"],
        ["66","63","60","57","54"],
        ["67","64","61","58","55"],
        ["68","65","62","59","56"],
        ["69","66","63","60","57"],
        ["70","67","64","61","58"],
        ["","68","65","62","59"],
        ["","69","66","63","60"],
        ["","70","67","64","61"],
        ["","","68","65","62"],
        ["","","69","66","63"],
        ["","","70","67","64"],
        ["","","","68","65"],
        ["","","","69","66"],
        ["","","","70","67"],
        ["","","","","68"],
        ["","","","","69"],
        ["","","","","70"],
    ],
    roundNumber: 1,
    roundStart: undefined,
    roundSpent: 0,
    roundLength: DEF_ROUND_LENGTH * 1000,
    roundRemaining: DEF_ROUND_LENGTH * 1000,

    formData: {
        rounds: [],
        roundNumber: 1
    }
};

class Components extends React.Component {

    constructor(props) {
        super(props);

        let savedState = window.localStorage.getItem('schedule');
        if (savedState !== null) {

            this.state = JSON.parse(savedState);
            this.state.now = new Date(this.state.now);
            if (this.state.roundStart !== undefined) this.state.roundStart = new Date(this.state.roundStart);

        } else {

            this.state = initial;

        }

        this.init = this.init.bind(this);
        this.apply = this.apply.bind(this);
        this.management = this.management.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.start = this.start.bind(this);
        this.pause = this.pause.bind(this);
        this.reset = this.reset.bind(this);
        this.forward = this.forward.bind(this);
        this.back = this.back.bind(this);
    }


    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }


    componentWillUnmount() {
        clearInterval(this.timerID);
    }


    tick() {
        let now = new Date();

        let newState = {  };

        let remaining = this.state.roundLength - this.state.roundSpent;
        if (this.state.active === true) {
            remaining -=  (now.getTime() - this.state.roundStart.getTime());
            if (remaining < 100) {
                newState.roundNumber = this.state.roundNumber + 1;
                newState.roundSpent = 0;
                newState.roundStart = now;
                remaining = this.state.roundLength;
            }
        }

        remaining = Math.max(1, remaining); // 1 so moment won't display current time

        newState.now = now;
        newState.roundRemaining = remaining;

        this.setState(newState);
    }


    management() {
        let {rounds, roundNumber} = this.state;

        this.setState({
            management: true,
            formData: {
                rounds: JSON.stringify(rounds, 2),
                roundNumber: roundNumber,
                roundLength: DEF_ROUND_LENGTH
            }
        });
    }


    apply() {
        let {formData} = this.state;

        this.setState({
            management: false,
            rounds: JSON.parse(formData.rounds),
            roundNumber: parseInt(formData.roundNumber),
            roundLength: parseInt(formData.roundLength) * 1000
        });
    }

    init() {
        this.setState(initial);
    }

    handleChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        let formData = Object.assign({}, this.state.formData);
        formData[name] = value;

        this.setState({
            formData: formData
        });
    }

    start() {
        this.setState({
            active: true,
            roundStart: new Date()
        });
    }


    pause() {
        let now = new Date();

        this.setState({
            active: false,
            roundSpent: (now.getTime() - this.state.roundStart.getTime())
        });
    }


    reset() {
        this.setState((state) => ({
            roundSpent: 0,
            roundRemaining: state.roundLength
        }));
    }


    back() {
        this.setState((state) => ({
            roundNumber: Math.max(1, state.roundNumber - 1)
        }));
    }


    forward() {
        this.setState((state) => ({
            roundNumber: Math.min(state.roundNumber + 1, state.rounds.length)
        }));
    }


    render() {
        window.localStorage.setItem('schedule', JSON.stringify(this.state));

        let {now, roundRemaining, management, rounds, roundNumber} = this.state;

        let containerStyle = {
            backgroundColor: "black",
            color: "yellow",
            width: "100vw",
            height: "100vh",
            fontSize: 180,
            display: "flex",
            flexDirection: "column",
        };

        let row1 = (rounds.length < roundNumber ? [ '', '', '', '' ] : rounds[roundNumber-1]);
        let row2 = (rounds.length < roundNumber + 1 ? [ '', '', '', '' ] : rounds[roundNumber]);
        let row3 = (rounds.length < roundNumber + 2 ? [ '', '', '', '' ] : rounds[roundNumber+1]);

        if (management === false) {
            return (
                <div style={containerStyle}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", lineHeight: "200px", marginLeft: 40, marginRight: 40}}>
                        <Moment date={roundRemaining} format="mm:ss"/>
                        <div style={{lineHeight: "18px"}}>
                            <Button color="primary" variant={"outlined"} onClick={e => this.start(e)}>indít</Button>
                            <Button color="primary" variant={"outlined"} onClick={e => this.pause(e)}>szünet</Button>
                            <Button color="primary" variant={"outlined"} onClick={e => this.reset(e)}>nulláz</Button>
                            <Button color="primary" variant={"outlined"} onClick={e => this.back(e)}>hátra</Button>
                            <Button color="primary" variant={"outlined"} onClick={e => this.forward(e)}>előre</Button>
                            <Button color="primary" variant={"outlined"} onClick={e => this.management(e)}>adatok</Button>
                        </div>
                        <Moment date={now} format="HH:mm:ss"/>
                    </div>
                    <table style={{width: "100%", lineHeight: "250px", borderSpacing: 20}}>
                        <tbody>
                        <tr style={{fontSize: 190, fontWeight: "bold"}}>
                            <td style={{width: "20%", border: "6px solid yellow"}} align="center">{row1[0]}</td>
                            <td style={{width: "20%", border: "6px solid yellow"}} align="center">{row1[1]}</td>
                            <td style={{width: "20%", border: "6px solid yellow"}} align="center">{row1[2]}</td>
                            <td style={{width: "20%", border: "6px solid yellow"}} align="center">{row1[3]}</td>
                            <td style={{width: "20%", border: "6px solid yellow"}} align="center">{row1[4]}</td>
                        </tr>
                        <tr style={{fontSize: 150}}>
                            <td style={{width: "20%"}} align="center">{row2[0]}</td>
                            <td style={{width: "20%"}} align="center">{row2[1]}</td>
                            <td style={{width: "20%"}} align="center">{row2[2]}</td>
                            <td style={{width: "20%"}} align="center">{row2[3]}</td>
                            <td style={{width: "20%"}} align="center">{row2[4]}</td>
                        </tr>
                        <tr style={{fontSize: 150}}>
                            <td style={{width: "20%"}} align="center">{row3[0]}</td>
                            <td style={{width: "20%"}} align="center">{row3[1]}</td>
                            <td style={{width: "20%"}} align="center">{row3[2]}</td>
                            <td style={{width: "20%"}} align="center">{row3[3]}</td>
                            <td style={{width: "20%"}} align="center">{row3[4]}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <form style={{padding: 20, height: "100vh"}}>
                    <Grid container direction="column">
                        <Grid item>
                            <TextField
                                id="roundNumber"
                                name="roundNumber"
                                label="roundNumber"
                                value={this.state.formData.roundNumber}
                                onChange={e => this.handleChange(e)}/>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="roundLength"
                                name="roundLength"
                                label="roundLength"
                                value={this.state.formData.roundLength}
                                onChange={e => this.handleChange(e)}/>
                        </Grid>
                        <Grid item>
                            <TextField
                                multiline
                                rowsMax="4"
                                label="data"
                                name="rounds"
                                style={{width: "50vw"}}
                                value={this.state.formData.rounds}
                                onChange={e => this.handleChange(e)}/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" style={{margin: 20}}
                                    onClick={
                                        () => this.setState({management: false})
                                    }
                            >cancel</Button>

                            <Button variant="contained" color="primary" style={{margin: 20}} onClick={
                                e => this.apply(e)
                            }
                            >apply</Button>

                            <Button variant="contained" color="primary" style={{margin: 20}} onClick={
                                e => this.init(e)
                            }
                            >init</Button>
                        </Grid>
                    </Grid>
                </form>
            );
        }
    }
}

export default withStyles({})(Components);