import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {useEvent} from "../../../Api/events";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import View from "./View";
import {EventRecord, EventSchema} from "../../../Data/Event.js";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Events view="list"/>,
  "/:id": ({id}) => <Events view="view" id={id}/>,
};

const Events = ({view, id}) => {

  debug("RENDER", "Events.User", () => [view]);

  const {t} = useTranslation('event');

  const {loadingStatus, data} = useEvent(id);

  const viewData = (loadingStatus ? new EventRecord(EventSchema.default()) : data);

  const path = [{label: t('calendar'), url: "/events"}];

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "view" :

      return <View id={id} record={viewData} path={path}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};

