import React from "react";
import debug from "../../../../Lib/debug";
import {useTranslation} from "react-i18next";
import Button from "../../../../Lib/material-kit/components/CustomButtons/Button";
import {executeParticipantImport, executeRecalculate} from "../../../../Api/competitions";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {Navigation} from "../Navigation";
import {useSnackbar} from "notistack";
import {closeSnack} from "../../../../Main/util";

export default ({competitionId, path}) => {

  debug("RENDER", "Competitions.Actions", () => []);

  const {t} = useTranslation('competition');
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const participantImport = () => {
    executeParticipantImport(
        competitionId,
        () => enqueueSnackbar(t('general:execute.success')),
        () => enqueueSnackbar(t('general:execute.error'), {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)})
    )
  };

  const recalculate = () => {
    executeRecalculate(
        competitionId,
        () => enqueueSnackbar(t('general:execute.success')),
        () => enqueueSnackbar(t('general:execute.error'), {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)})
    )
  };


  return (
      <ContentBox path={path}>

        <Navigation id={competitionId}/>

        <div>
          <Button color="success" style={{marginTop: 10}} onClick={participantImport}>{t('import-participations')}</Button> {t('add-sections-warning')}
        </div>

        <div>
          <Button color="success" style={{marginTop: 10}} onClick={recalculate}>{t('recalculate')}</Button> {t('recalculate-warning')}
        </div>

      </ContentBox>
  );
};