import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addDocument, useDocument} from "../../Api/documents";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {DocumentRecord, DocumentSchema} from '../../Data/document';

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Documents view="list"/>,
  "/add": () => <Documents view="add"/>
};

const Documents = ({view, id}) => {

  debug("RENDER", "Documents", () => [view]);

  const {t} = useTranslation('documents');

  const {loadingStatus, data} = useDocument(id);

  const viewData = (loadingStatus ? new DocumentRecord(DocumentSchema.default()) : data);

  const path = [{label: t('documents'), url: "/documents"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('label');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/documents/add"});
      return <Form id={id} record={viewData} path={path} view={view} api={addDocument}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};

