import { Record } from 'immutable';

const EventRegistration = Record({
  id: 0,
  principal: 0,
  event: 0,
  group: 0,
  status: '',
  consent: false,
  paid: false,
  paymentConfirmed: false,
  notes: ''
});

export default EventRegistration;