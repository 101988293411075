import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";

import accountStyle from "./accountStyle";
import {makeStyles} from "@material-ui/styles";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import {PasswordRecord, PasswordSchema} from "../../Data/account";
import {updateCredentials} from "../../Api/accounts";
import Section from "../../Lib/form/Components/Section/Section";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {closeSnack} from "../../Main/util";

const useStyles = makeStyles(accountStyle);

/**
 * Checks if the password verification is the same as password.
 */
export const passwordValidator = (fieldName, fieldValue, fieldValues) => {
  return fieldValues["newPassword"] === fieldValue;
};

const PasswordChange = ({id}) => {

  const [success, setSuccess] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const api = (record, onSuccess, onError) => {
    updateCredentials(id, record, onSuccess, onError)
  };

  const cbSuccess = () => {
    setSuccess(true);
  };

  const cbError = () => {
    enqueueSnackbar(
        t('password-change-error'),
        {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});
  };

  const {t} = useTranslation("account");

  // noinspection JSPotentiallyInvalidConstructorUsage
  const form = useForm({
    recordType: PasswordRecord,
    recordSchema: PasswordSchema,
    namespace: 'account',
    record: new PasswordRecord()
  });

  const classes = useStyles();

  if (success) {
    return (
        <ContentBox title={t("password-change-success")}>
          <Section title={t("password-change-success")}>
            <br/>
            {t('password-change-success-explanation')}
          </Section>
        </ContentBox>
    )
  }

  return (
        <div className={classes.panel}>
          <Card className={classes.container}>
            <CardBody>
              <Grid container justify="center">

                <Grid item xs={12}>
                  <div className={classes.title}>{t('password-change-title')}</div>
                  <p>{t('password-change-explanation')}</p>
                </Grid>

                <Grid item xs={12}>
                  <FormProvider form={form} formClassName={classes.form}>
                    <ValidatedInput name="oldPassword" password/>
                    <ValidatedInput name="newPassword" password/>
                    <ValidatedInput name="verification" password validator={passwordValidator}/>

                    <Grid item container justify="space-between">
                      <Grid item style={{marginTop: 40}}>
                        <ValidatedSubmit
                            api={api}
                            hideCancel
                            submitLabel={t('password-change-button')}
                            onSuccess={cbSuccess}
                            onError={cbError}
                        />
                      </Grid>
                    </Grid>
                  </FormProvider>
                </Grid>

              </Grid>
            </CardBody>
          </Card>
        </div>
  );
};

export default PasswordChange;